import React from "react";
import { useTranslation } from "react-i18next";
import { useAPIGetRightToBeForgotten } from "api/client-features/useAPIGetRightToBeForgotten";
import { SupportedLanguages } from "locale";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import moment from "moment";

import {
  InputSelect,
  SelectOption,
  Link,
  LinkVariant,
  Loading,
  Mixins,
} from "@teamsoftware/reactcomponents";

type HeaderNavProps = {
  leftComponent?: JSX.Element;
};

const DivContainerStyled = styled.div`
  ${Mixins.Flex.align({ direction: "center" })}
  padding: 1rem;
  ${up("md")} {
    ${Mixins.Flex.row()}
  }
  ${down("sm")} {
    ${Mixins.Flex.column()}
  }
`;

const DivPageTitleStyled = styled.div`
  ${Mixins.Flex.flex()}
  order: 1;
  ${down("sm")} {
    order: 2;
    margin-top: 1rem;
  }
`;

const DivNavLangContainerStyled = styled.div`
  ${Mixins.Flex.row()}
  ${Mixins.Flex.align({ direction: "center" })}
  order: 2;
  ${down("sm")} {
    order: 1;
    width: 100%;
    ${Mixins.Flex.justify({ direction: "justify" })}
  }
`;

const NavStyled = styled.nav`
  ${Mixins.Flex.row()}
  ${Mixins.Flex.align({ direction: "center" })}
`;

const LinkStyled = styled(Link)`
  margin-right: 1.6875rem;

  ${down("sm")} {
    margin: 1rem 1rem;
  }

  :last-child {
    ${down("sm")} {
      margin-right: 0;
    }
  }
`;

export const HeaderNav = ({ leftComponent }: HeaderNavProps) => {
  const { t, i18n } = useTranslation();
  const { isLoading, data: hasRightForgotten } = useAPIGetRightToBeForgotten();

  const onChangeLang = (values: SelectOption<string>[]) => {
    if (!values || values.length === 0) return;
    const selectedOption = values[0];
    if (!selectedOption || selectedOption.value === undefined) return;
    moment.locale(selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
  };

  const langOptions: SelectOption<string>[] = SupportedLanguages.map(
    (lang) => ({
      key: lang.key,
      label: lang.name,
      value: lang.key,
      isSelected:
        lang.key === i18n.language ||
        (lang.key === "en" && i18n.language === "en-US"),
    })
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      {!isLoading && (
        <DivContainerStyled data-testid="header-nav">
          <DivPageTitleStyled data-testid="nav-left-component">
            {leftComponent}
          </DivPageTitleStyled>
          <DivNavLangContainerStyled>
            <NavStyled>
              <LinkStyled
                id="navLinkHelp"
                data-testid="nav-link-help"
                variant={LinkVariant.SUBTLE}
                to={"/help"}
              >
                {t("header.nav.help")}
              </LinkStyled>
              {hasRightForgotten && (
                <LinkStyled
                  id="navLinkDataRequest"
                  data-testid="nav-link-data-request"
                  variant={LinkVariant.SUBTLE}
                  to={process.env.REACT_APP_DATA_REQUEST_URL as string}
                  target="_blank"
                >
                  {t("header.nav.datarequest")}
                </LinkStyled>
              )}
            </NavStyled>

            <span>
              <InputSelect
                id="user-lang"
                placeholder="Language"
                aria-label="Language selection"
                options={langOptions}
                onOptionChange={onChangeLang}
              />
            </span>
          </DivNavLangContainerStyled>
        </DivContainerStyled>
      )}
    </>
  );
};
