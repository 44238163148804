import { useQuery } from "react-query";

import axios from "utils/axios-api-base";

const queryKey: string = "Tenant";

type getTenantInfoRequest = {
  domain: string;
};

type getTenantInfoResponse = {
  id: string;
  tenantName: string;
  hasStylesheet: boolean;
  hasScript: boolean;
};

export const useAPITenant = (request: getTenantInfoRequest) => {
  return useQuery(
    queryKey,
    async (): Promise<getTenantInfoResponse | null> => {
      const { status, data } = await axios.get("tenant", {
        headers: {
          TenantKey: request.domain,
        },
      });
      // NoContent?
      if (status === 404) return null;
      // Has tenant
      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
