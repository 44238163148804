import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonType,
  ButtonVariant,
  Icon,
  Mixins,
} from "@teamsoftware/reactcomponents";

import { ApplyStepSubHeader } from "components/ApplyStepSubHeader";
import { Card, CardProps } from "../Card";

export type SectionProps = {
  title: string;
  description: string;
  linkTerm: string;
  error?: string;
  cards: CardProps[];
  onClickAdd: () => void;
};

const CategoryDescriptionPStyled = styled.p`
  margin-top: 0.125rem;
`;

const ButtonStyled = styled(Button)`
  margin: 0.5rem;
`;

const CardContainer = styled.div`
  margin-top: 1rem;
  ${Mixins.Flex.column({ gap: 1 })}
`;

const ErrorStyled = styled.p`
  margin-top: 1rem;
  color: ${(props) => props.theme.Text.Critical};
`;

export const Section = ({
  title,
  description,
  cards,
  linkTerm,
  error,
  onClickAdd,
}: SectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ApplyStepSubHeader
        title={title}
        data-testid="applyexperienceandhistory-section-title"
      />
      <CategoryDescriptionPStyled data-testid="applyexperienceandhistory-section-desc">
        {description}
      </CategoryDescriptionPStyled>
      <ErrorStyled>{error}</ErrorStyled>
      <CardContainer data-testid="applyexperienceandhistory-section-cardcontainer">
        {cards.map((item, i) => (
          <Card key={`${i}-${item.title}`} {...item} />
        ))}
      </CardContainer>
      <ButtonStyled
        type="button"
        variant={ButtonVariant.SECONDARY}
        buttonType={ButtonType.MINIMAL}
        iconLeft={<Icon.Add />}
        onClick={onClickAdd}
        data-testid="applyexperienceandhistory-section-addbtn"
      >
        {cards.length > 0
          ? t("apply.experience.addanotherlink", { linkTerm: linkTerm })
          : t("apply.experience.addlink", { linkTerm: linkTerm })}
      </ButtonStyled>
    </>
  );
};
