import { useQuery } from "react-query";
import { AxiosResponse } from "axios";

import axios from "utils/axios-api-base";
import { QuestionSection } from "models/QuestionSection";

const queryKey: string = "QuestionSectionsByPostingId";

type GetAllByPostingIdResponse = {
  totalResults: number;
  results: QuestionSection[];
};

export const useAPIQuestionSectionsByPostingId = (postingId: string) => {
  return useQuery(
    [queryKey, postingId],
    async (): Promise<QuestionSection[] | null> => {
      const { status, data } = await axios.get<
        any,
        AxiosResponse<GetAllByPostingIdResponse>
      >("QuestionSection/getAllByPostingId", {
        params: {
          postingId: postingId,
        },
      });

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data.results.map<QuestionSection>((s: QuestionSection, i) => {
        return { ...s, rank: i } as QuestionSection;
      });
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
