import { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Loading } from "@teamsoftware/reactcomponents";

import { useTenant } from "context/tenantContext";

const TenantContainer: FunctionComponent = ({ children }) => {
  const tenant = useTenant();

  const { i18n } = useTranslation();

  if (tenant.isLoading) {
    return <Loading isLoading />;
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}>
        <title>{tenant.name}</title>
        {tenant.scriptURL && <script src={tenant.scriptURL} defer />}
        {tenant.styleURL && <link rel="stylesheet" href={tenant.styleURL} />}
      </Helmet>
      {children}
    </>
  );
};

export default TenantContainer;
