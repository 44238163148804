import { useTranslation } from "react-i18next";
import { useStepper } from "@teamsoftware/reactcomponents";

import { ApplyMyInfoFormData } from "components/ApplyMyInfo";
import { Card } from "../Card";

export type MyInfoCardProps = {
  stepIndex: number;
  data: ApplyMyInfoFormData;
};

export const MyInfoCard = ({ stepIndex, data }: MyInfoCardProps) => {
  const { t } = useTranslation();

  const { dispatch } = useStepper();

  const goToStep = () => {
    dispatch({ type: "setStep", payload: stepIndex });
  };

  return (
    <Card title={t("apply.myinfo.step.title")} onClickEdit={goToStep}>
      <p>
        <b>Name:</b>&nbsp;
        <span>
          {data.firstName}&nbsp;
          {data.lastName}
        </span>
      </p>
      <p>
        <b>Email:</b>&nbsp;<span>{data.email}</span>
      </p>
      <p>
        <b>Phone:</b>&nbsp;<span>{data.phone}</span>
      </p>
      <p>
        <b>Terms and Conditions:</b>&nbsp;<span>Agreed</span>
      </p>
    </Card>
  );
};
