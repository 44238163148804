import { useQuery } from "react-query";
import axios from "utils/axios-api-base";
import { ApplicationInfo } from "models/ApplicationInfo";

const queryKey: string = "CompletionState";

export const useAPIGetCompletionState = (
  request: ApplicationInfo,
  enabled: boolean
) => {
  return useQuery(
    [queryKey, request],
    async (): Promise<boolean | null> => {
      const { status, data } = await axios.get(
        "Application/GetCompletionState",
        {
          params: request,
        }
      );
      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );
};
