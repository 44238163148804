import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import {
  Button,
  ButtonVariant,
  IconButton,
  Panel,
  Tag,
  Icon,
  Mixins,
  InputText,
  InputGroup,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  ButtonType,
} from "@teamsoftware/reactcomponents";
import { fadeInUp, fadeOutDown } from "react-animations";
import { useForm } from "react-hook-form";
import { ShareMedium } from "../";
import { ReactSession } from "react-client-session";

export type ApplyPanelMobileFormData = {
  email: string;
};

type ApplyPanelMobileProps = {
  idVariant: string; // something unique to THIS apply panel (if multiple are on screen)
  onApply: (email: string | undefined) => void;
  onDisregard: (email: string | undefined) => void;
  onShare: (medium: ShareMedium) => void;
  defaultEmailValue?: ApplyPanelMobileFormData;
  alreadyApplied?: boolean;
};

type PanelBtnStyledProps = {
  isScrollingDown: boolean;
  isApplyPanelContainerVisible: boolean;
};
const PanelBtnStyled = styled(Panel)<PanelBtnStyledProps>`
  position: sticky;
  bottom: 0rem;
  border-radius: 0.5rem 0.5rem 0 0;
  ${Mixins.Flex.column({ gap: 0.625 })}

  ${(props) =>
    !props.isScrollingDown &&
    css`
      animation: 0.5s ${keyframes`${fadeInUp}`};
      animation-fill-mode: forwards;
    `}
  ${(props) =>
    props.isScrollingDown &&
    css`
      animation: 0.5s ${keyframes`${fadeOutDown}`};
      animation-fill-mode: forwards;
    `}

    
  ${(props) =>
    props.isApplyPanelContainerVisible &&
    css`
      position: static;
      animation: none;
      animation-fill-mode: none;
    `}
`;

const PanelLowerStyled = styled(Panel)`
  ${Mixins.Flex.column({ gap: 0.625 })}
  border-radius: 0 0 0.5rem 0.5rem;
  padding-top: 0;
`;

const DivIconButtonRowStyled = styled.div`
  ${Mixins.Flex.row({ gap: 0.625 })}
  margin-left: auto;
  margin-right: auto;
`;

const DivViewDetect = styled.div`
  height: 0px;
  width: 0px;
`;

const StyledHeader = styled(Typography)`
  display: inline;
`;

const Message = styled(Typography)`
  line-height: 1.4rem;
  display: inline;
`;

const StyledTag = styled(Tag)`
  color: ${(props) => props.theme.Link.Primary};
  margin-left: auto;
  margin-right: auto;
`;

export const ApplyPanelMobile = ({
  idVariant,
  defaultEmailValue,
  onApply,
  onDisregard,
  onShare,
  alreadyApplied = false,
}: ApplyPanelMobileProps) => {
  const { t } = useTranslation();
  const [isScrollingDown, setIsScrollingDown] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const viewDetectElRef = useRef<HTMLDivElement>(null);
  const lastScrollYRef = useRef<number>(0);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<ApplyPanelMobileFormData>({
    defaultValues: defaultEmailValue,
    mode: "onChange",
  });

  const emailValue = watch("email");
  const isEmailDisabled =
    !emailValue ||
    emailValue.length === 0 ||
    (!!errors.email?.message && errors.email?.message.length > 0);
  const emailRegEx: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isAuthenticated = ReactSession.get("userToken") !== undefined;

  const createCopyLink = () => {
    var linktoCopy = document.body.appendChild(document.createElement("input"));
    linktoCopy.value = window.location.href;
    linktoCopy.select();
    document.execCommand("copy");

    if (linktoCopy.parentNode) {
      linktoCopy.parentNode.removeChild(linktoCopy);
    }
  };

  const isInViewport = () => {
    const top: number =
      viewDetectElRef.current?.getBoundingClientRect().top ?? 9999;
    const yFromBottomViewport: number = top - window.innerHeight;
    return yFromBottomViewport <= 0;
  };

  const handleScrollEvent = () => {
    let scrollY: number =
      window.pageYOffset || document.documentElement.scrollTop;
    // Handle mobile's ability to negative scroll
    // (the bouncy scrolling)
    if (scrollY < 0) scrollY = 0;

    const isScrollingDown = scrollY > lastScrollYRef.current;
    setIsScrollingDown(isScrollingDown);

    lastScrollYRef.current = scrollY;

    // Check if it's in viewport
    const isWithinView = isInViewport();
    setIsVisible(isWithinView);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent, false);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const navigate = useNavigate();

  const goToSearch = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <>
      <DivViewDetect ref={viewDetectElRef}></DivViewDetect>
      <PanelBtnStyled
        isScrollingDown={isScrollingDown}
        isApplyPanelContainerVisible={isVisible}
      >
        <StyledHeader
          variant={TypographyVariant.H4}
          weight={FontWeight.Bold}
          color={Color.Default}
        >
          {`${t("applypanel.btn.text")}:`}
        </StyledHeader>
        {!alreadyApplied && (
          <>
            {!isAuthenticated && (
              <InputGroup
                label={
                  <Message
                    variant={TypographyVariant.TextSmall}
                    weight={FontWeight.Regular}
                    color={Color.Default}
                  >
                    {t("applypanel.emailLabel")}
                  </Message>
                }
                error={errors.email?.message}
                input={
                  <InputText
                    id="email"
                    fullWidth
                    autoFocus
                    type="email"
                    {...register("email", {
                      required: t("validation.required"),
                      pattern: {
                        value: emailRegEx,
                        message: t("validation.email"),
                      },
                    })}
                  />
                }
              />
            )}
            <Button
              id={`apply-now-${idVariant}`}
              data-testid="apply-panel-btn"
              variant={ButtonVariant.PRIMARY}
              onClick={() => onApply(emailValue)}
              fullWidth
              disabled={isEmailDisabled && !isAuthenticated}
            >
              {t("applypanel.btn.text")}
            </Button>
          </>
        )}
        {alreadyApplied && (
          <>
            <Message
              variant={TypographyVariant.TextDefault}
              weight={FontWeight.Regular}
              color={Color.Default}
            >
              {t("applypanel.alreadyApplied")}
            </Message>
            <Message
              variant={TypographyVariant.TextDefault}
              weight={FontWeight.Regular}
              color={Color.Default}
            >
              {t("applypanel.checkOthers")}
            </Message>
            <Button
              id={`go-search-btn`}
              data-testid="go-search-btn"
              variant={ButtonVariant.PRIMARY}
              onClick={() => goToSearch()}
              fullWidth
            >
              {t("applypanel.btn.goSearch")}
            </Button>
            <Button
              id={`disregard-btn`}
              data-testid="disregard-btn"
              variant={ButtonVariant.PRIMARY}
              buttonType={ButtonType.MINIMAL}
              onClick={() => onDisregard(emailValue)}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              {t("applypanel.btn.disregard")}
            </Button>
          </>
        )}
      </PanelBtnStyled>
      <PanelLowerStyled>
        <DivIconButtonRowStyled>
          <IconButton
            id={`share-btn-email-${idVariant}`}
            data-testid="share-btn-email"
            aria-label={t("applypanel.sharebtn.email")}
            icon={<Icon.Email />}
            onClick={() => onShare(ShareMedium.EMAIL)}
            variant={ButtonVariant.SECONDARY}
          />
          <IconButton
            id={`share-btn-linkedin-${idVariant}`}
            data-testid="share-btn-linkedin"
            aria-label="Linked In"
            icon={<Icon.LinkedIn />}
            onClick={() => onShare(ShareMedium.LINKEDIN)}
            variant={ButtonVariant.SECONDARY}
          />
          <IconButton
            id={`share-btn-facebook-${idVariant}`}
            data-testid="share-btn-facebook"
            aria-label="Facebook"
            icon={<Icon.Facebook />}
            onClick={() => onShare(ShareMedium.FACEBOOK)}
            variant={ButtonVariant.SECONDARY}
          />
          <IconButton
            id={`share-btn-twitter-${idVariant}`}
            data-testid="share-btn-twitter"
            aria-label="Twitter"
            icon={<Icon.Twitter />}
            onClick={() => onShare(ShareMedium.TWITTER)}
            variant={ButtonVariant.SECONDARY}
          />
        </DivIconButtonRowStyled>
        <StyledTag
          icon={<Icon.Hyperlink />}
          text={t("applypanel.copyJobLink")}
          onClick={createCopyLink}
        />
      </PanelLowerStyled>
    </>
  );
};
