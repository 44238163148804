import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import {
  ButtonType,
  ButtonVariant,
  Mixins,
  Icon,
  IconButton,
} from "@teamsoftware/reactcomponents";

export type CardProps = {
  index: number;
  title: string;
  description: string | string[];
  onRequestEdit: () => void;
  onRequestDelete: () => void;
};

const ArticleStyled = styled.article`
  ${Mixins.BaseMixins.borderRadius({ radius: "0.25rem" })}
  ${Mixins.Flex.row()}
  border: 1px solid ${(props) => props.theme.Hr.Line};
  width: 21.438rem;
  ${down("sm")} {
    width: 100%;
  }
`;

const HeaderStyled = styled.header`
  position: relative;
  color: ${(props) => props.theme.Text.Primary};
  font-weight: 700;
  line-height: 1.5rem;
`;

const IndexStyled = styled.span`
  position: absolute;
  margin-left: -1.75rem;
`;

const IconButtonStyled = styled(IconButton)`
  height: 100%;
`;

const PStyled = styled.p`
  line-height: 1.5rem;
`;

const CardTextContainerDiv = styled.div`
  ${Mixins.Flex.column()}
  width: 100%;
  padding: 1rem 0rem 1rem 2.5em;
`;

const ButtonDiv = styled.div`
  ${Mixins.Flex.column()}
  align-content: space-between;
`;

export const Card = ({
  index,
  title,
  description,
  onRequestEdit,
  onRequestDelete,
}: CardProps) => {
  return (
    <ArticleStyled data-testid="applyexperienceandhistory-card">
      <CardTextContainerDiv>
        <HeaderStyled>
          <IndexStyled>#{index + 1}</IndexStyled>
          <span data-testid="applyexperienceandhistory-card-title">
            {title}
          </span>
        </HeaderStyled>
        {Array.isArray(description) &&
          description.map((d) => (
            <PStyled key={d} data-testid="applyexperienceandhistory-card-desc">
              {d}
            </PStyled>
          ))}
        {!Array.isArray(description) && (
          <PStyled data-testid="applyexperienceandhistory-card-desc">
            {description}
          </PStyled>
        )}
      </CardTextContainerDiv>
      <ButtonDiv>
        <IconButtonStyled
          data-testid="applyexperienceandhistory-card-editbtn"
          icon={<Icon.Pencil />}
          buttonType={ButtonType.MINIMAL}
          variant={ButtonVariant.SECONDARY}
          onClick={onRequestEdit}
          rem={3}
        ></IconButtonStyled>
        <IconButtonStyled
          data-testid="applyexperienceandhistory-card-deletebtn"
          icon={<Icon.Trash />}
          buttonType={ButtonType.MINIMAL}
          variant={ButtonVariant.SECONDARY}
          onClick={onRequestDelete}
          rem={3}
        ></IconButtonStyled>
      </ButtonDiv>
    </ArticleStyled>
  );
};
