import { useQuery } from "react-query";
import axios from "utils/axios-api-base";

const queryKey: string = "UkFormatting";

export const useAPIGetUkFormatting = () => {
  return useQuery(
    [queryKey],
    async (): Promise<boolean | null> => {
      const { status, data } = await axios.get("Tenant/getUkFormatting", {
        headers: { TenantKey: window.location.hostname },
      });

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
};
