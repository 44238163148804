import { useEffect } from "react";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Checkbox,
  HorizontalRule,
  InputGroup,
  InputText,
  Mixins,
} from "@teamsoftware/reactcomponents";
import { useForm } from "react-hook-form";
import moment from "moment";

import { ApplyStepHeader } from "components/ApplyStepHeader";

export type ApplyEducationHistoryProps = {
  defaultValue?: ApplyEducationHistoryFormData;
  onSubmit: (data: ApplyEducationHistoryFormData) => void;
  onRequestClose: () => void;
};

export type ApplyEducationHistoryFormData = {
  id?: string;
  school: string;
  degree: string;
  place: string;
  graduation?: Date;
  currentlyEnrolled: boolean;
  didNotGraduate: boolean;
};

const StyledDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const HorizontalRuleStyled = styled(HorizontalRule)`
  margin: 1rem 0;
`;

const HorizontalRuleFooterStyled = styled(HorizontalRule)`
  margin-top: 3.75rem;
  margin-bottom: 2.75rem;
`;

const FooterButtonGroupDiv = styled.div`
  ${Mixins.Flex.row({ gap: 0.5 })}
`;

const FormContainer = styled.div`
  ${Mixins.Flex.column({ gap: 1 })}
`;

const FormSBSContainer = styled.div`
  ${up("md")} {
    ${Mixins.Flex.row({ gap: 1 })}
  }
  ${down("sm")} {
    ${Mixins.Flex.column({ gap: 1 })}
  }
  width: 100%;
`;

const FillDiV = styled.div`
  ${Mixins.Flex.flex()}
`;

export const ApplyEducationHistory = ({
  defaultValue,
  onSubmit,
  onRequestClose,
}: ApplyEducationHistoryProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<ApplyEducationHistoryFormData>({
    defaultValues: {
      ...defaultValue,
    },
  });

  const didNotGraduate = watch("didNotGraduate");
  const currentlyEnrolled = watch("currentlyEnrolled");
  const graduation = watch("graduation");
  const isEditing = !!defaultValue;
  const MINIMUM_DATE: string = "01/01/1945";
  const MAXIMUM_DATE: string = "12/31/9999";

  const determineGraduationDateValue = (): string => {
    if (defaultValue?.graduation && !!!dirtyFields.graduation) {
      return moment(defaultValue.graduation).toISOString().substring(0, 10);
    } else {
      if (
        graduation !== undefined &&
        graduation !== null &&
        graduation.toLocaleString().length > 0
      ) {
        return moment(graduation).toISOString().substring(0, 10);
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (didNotGraduate) setValue("graduation", undefined);
  }, [setValue, didNotGraduate]);

  return (
    <StyledDiv>
      <ApplyStepHeader
        title={t("apply.educationhistory.header")}
        description={t("apply.educationhistory.description")}
      />
      <HorizontalRuleStyled />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <InputGroup
            label={`${t("apply.educationhistory.form.school.label")}*`}
            error={errors.school?.message}
            input={
              <InputText
                id="educationhistory-school"
                data-testid="educationhistory-school"
                placeholder={t(
                  "apply.educationhistory.form.school.placeholder"
                )}
                fullWidth
                {...register("school", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.educationhistory.form.degree.label")}*`}
            error={errors.degree?.message}
            input={
              <InputText
                id="educationhistory-degree"
                data-testid="educationhistory-degree"
                placeholder={t(
                  "apply.educationhistory.form.degree.placeholder"
                )}
                fullWidth
                {...register("degree", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.educationhistory.form.location.label")}*`}
            error={errors.place?.message}
            input={
              <InputText
                id="educationhistory-location"
                data-testid="educationhistory-location"
                placeholder={t(
                  "apply.educationhistory.form.location.placeholder"
                )}
                fullWidth
                {...register("place", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.educationhistory.form.graduationdate.label")}*`}
            error={errors.graduation?.message}
            input={
              <InputText
                id="educationhistory-graduationdate"
                data-testid="educationhistory-graduationdate"
                type="date"
                placeholder={moment(new Date()).format()}
                value={determineGraduationDateValue()}
                disabled={didNotGraduate}
                {...register("graduation", {
                  required: didNotGraduate
                    ? undefined
                    : t("validation.required"),
                  validate: (value: Date | undefined) => {
                    const isValidDate =
                      !!value &&
                      ((moment(value) < moment() &&
                        moment(value) > moment(new Date(MINIMUM_DATE))) ||
                        (currentlyEnrolled &&
                          moment(value) > moment(new Date(MINIMUM_DATE)) &&
                          moment(value) < moment(new Date(MAXIMUM_DATE))));
                    if (!isValidDate && !didNotGraduate) {
                      return t("validation.dateBetween", {
                        startDate: MINIMUM_DATE,
                        endDate: currentlyEnrolled
                          ? MAXIMUM_DATE
                          : moment().format("L").toString(),
                      });
                    }
                  },
                })}
              />
            }
          />
          <FormSBSContainer>
            <InputGroup
              label={t("apply.educationhistory.form.currentlyenrolled.label")}
              input={
                <Checkbox
                  id="educationhistory-currentlyenrolled"
                  data-testid="educationhistory-currentlyenrolled"
                  {...register("currentlyEnrolled")}
                />
              }
            />
            <InputGroup
              label={t("apply.educationhistory.form.didnotgraduate.label")}
              input={
                <Checkbox
                  id="educationhistory-didnotgraduate"
                  data-testid="educationhistory-didnotgraduate"
                  {...register("didNotGraduate")}
                />
              }
            />
            <FillDiV></FillDiV>
          </FormSBSContainer>
        </FormContainer>
        <HorizontalRuleFooterStyled />
        <FooterButtonGroupDiv>
          <Button
            type="submit"
            variant={ButtonVariant.PRIMARY}
            buttonSize={ButtonSize.LARGE}
            data-testid="educationhistory-submitbtn"
          >
            {isEditing
              ? t("apply.modalupdatebutton")
              : t("apply.modalsavebutton")}
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.SUBTLE}
            buttonType={ButtonType.MINIMAL}
            onClick={() => onRequestClose()}
            data-testid="educationhistory-cancelbtn"
          >
            {t("apply.modalcancelbutton")}
          </Button>
        </FooterButtonGroupDiv>
      </form>
    </StyledDiv>
  );
};
