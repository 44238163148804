import "react-i18next";
import { default as en } from "./en.json";
import { default as es } from "./es.json";
import { default as frCA } from "./frCA.json";

type Lang = {
  key: string;
  name: string;
};

export const SupportedLanguages: Array<Lang> = [
  { key: "en", name: "English" },
  { key: "es", name: "Español" },
  { key: "frCA", name: "Français Canadien" },
];

export const Translations = {
  en,
  es,
  frCA,

  // If you add a new language here, you must ALSO
  // add the language import for moment in i18n.ts
  // Must also add translations to the @teamsoftware/reactcomponents components repo
};
