import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  Tag,
  Link,
  Mixins,
} from "@teamsoftware/reactcomponents";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import moment from "moment";

import { JobResultTag } from "models/JobResultTag";

type JobResultCardProps = {
  id: string;
  title: string;
  description: string;
  date?: moment.Moment;
  tags?: Array<JobResultTag>;
};

const MAX_DESCRIPTION_LENGTH = 380;

const HeaderStyled = styled.header`
  margin: 1rem 0;
  ${up("md")} {
    ${Mixins.Flex.row()}
  }
  ${down("sm")} {
    ${Mixins.Flex.column()}
  }
`;

const DivContentBodyStyled = styled(Typography)`
  ${Mixins.Flex.row({ gap: 1 })}
  ${Mixins.Flex.wrap()}
`;

const TagStyled = styled(Tag)`
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
`;

const LinkStyled = styled(Link)`
  ${Mixins.Flex.flex()}
`;

export const JobResultCard = ({
  id,
  title,
  date,
  tags,
  description,
}: JobResultCardProps) => {
  const formattedDateString = !!date ? date.format("D MMM") : undefined;

  const truncate = (text: string, n: number): string => {
    // If the string is already short, return it
    if (text.length <= n) return text;

    // Truncate the text to n
    var truncatedText = text.substr(0, n);

    // If the next char after truncation is whitespace,
    // then we have a whole word. Return the truncated string
    if (truncatedText.charAt(n) === " ")
      return truncatedText.trimRight() + "...";

    // Get the index of the last whitespace char
    const lastWhitespaceIndex: number = truncatedText.lastIndexOf(" ");

    // Return truncated text furthur
    // trimed to the last whitespace character
    return truncatedText.substr(0, lastWhitespaceIndex).trimRight() + "...";
  };

  const truncatedDescription = truncate(description, MAX_DESCRIPTION_LENGTH);

  return (
    <article>
      <HeaderStyled>
        <LinkStyled id={`job-${id}`} to={`/job/${id}`}>
          <Typography
            variant={TypographyVariant.H4}
            weight={FontWeight.Bold}
            color={Color.Contrast}
          >
            {title}
          </Typography>
        </LinkStyled>
        {!!formattedDateString && (
          <Typography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Subtle}
          >
            {formattedDateString}
          </Typography>
        )}
      </HeaderStyled>
      <DivContentBodyStyled
        variant={TypographyVariant.TextLarge}
        weight={FontWeight.SemiBold}
        color={Color.Subtle}
      >
        {tags?.map((tag) => (
          <TagStyled
            key={tag.title}
            text={tag.title}
            icon={tag.icon}
            onClick={tag.onClick}
          ></TagStyled>
        ))}
      </DivContentBodyStyled>
      <Typography
        variant={TypographyVariant.P}
        weight={FontWeight.Regular}
        color={Color.Contrast}
      >
        {truncatedDescription}
      </Typography>
    </article>
  );
};
