import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import {
  Checkbox,
  HorizontalRule,
  InputGroup,
  Typography,
  TypographyVariant,
  Color,
  FontWeight,
  Mixins,
  useStepper,
  StepperFooter,
} from "@teamsoftware/reactcomponents";
import { useForm } from "react-hook-form";

import { ApplyStepHeader } from "components/ApplyStepHeader";
import { ApplicationFormData } from "pages/ApplicationPage";
import { ExperienceCard } from "./components/ExperienceCard";
import { MyInfoCard } from "./components/MyInfoCard";
import { QuestionsCard } from "./components/QuestionsCard";

export type ApplyReviewProps = {
  stepMyInfo: number;
  stepExperience: number;
  stepQuestions: number;
  data: ApplicationFormData;
  disabled?: boolean;
  onSubmit: (data: ApplyReviewFormData) => void;
  companyName: string | undefined;
};

export type ApplyReviewFormData = {
  agreeToApplicationStatement: boolean;
};

const StyledDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const HorizontalRuleStyled = styled(HorizontalRule)`
  margin: 1rem 0;
`;

const HorizontalRuleFooterStyled = styled(HorizontalRule)`
  margin-top: 3.75rem;
  margin-bottom: 2.75rem;
`;

const AgreeInputGroup = styled(InputGroup)`
  margin-top: 2rem;
`;

const ApplicationStatementP = styled(Typography)`
  white-space: pre-wrap;
`;

const ApplicationStatementDiv = styled.div`
  margin-top: 3.5rem;
`;

const ReviewCardContainerDiv = styled.div`
  margin-top: 3.5rem;
  ${Mixins.Flex.column({ gap: 1.5 })}
`;

export const ApplyReview = ({
  stepMyInfo,
  stepExperience,
  stepQuestions,
  disabled,
  onSubmit,
  companyName,
  data,
}: ApplyReviewProps) => {
  const { t } = useTranslation();
  const { dispatch } = useStepper();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<ApplyReviewFormData>();

  // Sync form dirty state to stepper
  useEffect(() => {
    dispatch({ type: "setDirty", payload: isDirty });
  }, [dispatch, isDirty]);

  return (
    <StyledDiv>
      <ApplyStepHeader
        title={t("apply.review.header")}
        description={t("apply.review.description")}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ReviewCardContainerDiv>
          <MyInfoCard stepIndex={stepMyInfo} data={data.myInfoData} />
          <ExperienceCard
            stepIndex={stepExperience}
            data={data.experienceData}
          />
          <QuestionsCard stepIndex={stepQuestions} data={data.questionData} />
        </ReviewCardContainerDiv>
        <ApplicationStatementDiv>
          <Typography
            variant={TypographyVariant.H4}
            weight={FontWeight.Regular}
            color={Color.Contrast}
          >
            {t("apply.review.form.applicationstatment.header")}
          </Typography>
          <HorizontalRuleStyled />
          <ApplicationStatementP
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("apply.review.form.applicationstatment.legaltext", {
              company: companyName,
            })}
          </ApplicationStatementP>
        </ApplicationStatementDiv>
        <AgreeInputGroup
          label={t("apply.review.form.agreeapplicationstatement.label")}
          error={errors.agreeToApplicationStatement?.message}
          input={
            <Checkbox
              id="review-agreeapplicationstatement"
              data-testid="review-agreeapplicationstatement"
              {...register("agreeToApplicationStatement", {
                required: t("validation.required"),
              })}
            />
          }
        />
        <HorizontalRuleFooterStyled />
        <StepperFooter submitOnNext={true} disabled={disabled} />
      </form>
    </StyledDiv>
  );
};
