import { useMutation } from "react-query";
import { AxiosError } from "axios";
import axios from "utils/axios-api-base";
export type PasswordlessAuthApplicantRequest = {
  email: string;
  jobId: string;
  domain: string;
};

export const useAPICheckEmailAndSendEmail = <TData, TContext>(
  onSuccessCallback?: (
    data: TData,
    request: PasswordlessAuthApplicantRequest,
    context: TContext | undefined
  ) => Promise<void> | void,

  onErrorCallback?: (
    error: AxiosError,
    request: PasswordlessAuthApplicantRequest,
    context: TContext | undefined
  ) => Promise<void> | void
) => {
  return useMutation<TData, AxiosError, PasswordlessAuthApplicantRequest, TContext>(
    (request) => {
      return axios.get("Passwordless/CheckEmailAndSendEmail", {params: request});
    },
    {
      onError: async (error, request, context) => {
        if (onErrorCallback) {
          onErrorCallback(error, request, context);
        }
      },

      onSuccess: async (data, request, context) => {
        if (onSuccessCallback) {
          onSuccessCallback(data, request, context);
        }
      },
    }
  );
};