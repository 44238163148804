import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ReactSession } from "react-client-session";

import {
  Button,
  ButtonVariant,
  IconButton,
  Panel,
  Tag,
  Icon,
  Mixins,
  InputText,
  InputGroup,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  ButtonType,
} from "@teamsoftware/reactcomponents";

export enum ShareMedium {
  EMAIL,
  FACEBOOK,
  TWITTER,
  LINKEDIN,
}

export type ApplyPanelFormData = {
  email: string;
};

export type ApplyPanelProps = {
  idVariant: string; // something unique to THIS apply panel (if multiple are on screen)
  onApply: (email: string | undefined) => void;
  onDisregard: (email: string | undefined) => void;
  onShare: (medium: ShareMedium) => void;
  defaultEmailValue?: ApplyPanelFormData;
  alreadyApplied?: boolean;
};

const PanelStyled = styled(Panel)`
  ${Mixins.Flex.column({ gap: 0.625 })}
`;

const DivIconButtonRowStyled = styled.div`
  ${Mixins.Flex.row({ gap: 0.625 })}
  padding-left: 1.8rem;
  padding-right: 1.8rem;
`;

const IconLinkStyled = styled(Icon.Hyperlink)`
  font-size: 1.5rem;
`;

const StyledTag = styled(Tag)`
  color: ${(props) => props.theme.Link.Primary};
  padding-left: 3rem;
`;

const StyledHeader = styled(Typography)`
  display: inline;
`;

const Message = styled(Typography)`
  line-height: 1.4rem;
  display: inline;
`;

export const ApplyPanel = ({
  defaultEmailValue,
  idVariant,
  onApply,
  onDisregard,
  onShare,
  alreadyApplied = false,
  ...props
}: ApplyPanelProps & React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<ApplyPanelFormData>({
    defaultValues: defaultEmailValue,
    mode: "onChange",
  });

  const emailValue = watch("email");
  const isEmailDisabled =
    !emailValue ||
    emailValue.length === 0 ||
    (!!errors.email?.message && errors.email?.message.length > 0);
  const emailRegEx: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isAuthenticated = ReactSession.get("userToken") !== undefined;

  const createCopyLink = () => {
    var linktoCopy = document.body.appendChild(document.createElement("input"));
    linktoCopy.value = window.location.href;
    linktoCopy.select();
    document.execCommand("copy");

    if (linktoCopy.parentNode) {
      linktoCopy.parentNode.removeChild(linktoCopy);
    }
  };

  const navigate = useNavigate();

  const goToSearch = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <PanelStyled data-testid="apply-panel" {...props}>
      <StyledHeader
        variant={TypographyVariant.H4}
        weight={FontWeight.Bold}
        color={Color.Default}
      >
        {`${t("applypanel.btn.text")}:`}
      </StyledHeader>
      {!alreadyApplied && (
        <>
          {!isAuthenticated && (
            <InputGroup
              label={
                <Message
                  variant={TypographyVariant.TextSmall}
                  weight={FontWeight.Regular}
                  color={Color.Default}
                >
                  {t("applypanel.emailLabel")}
                </Message>
              }
              error={errors.email?.message}
              input={
                <InputText
                  id="email"
                  data-testid="apply-panel-email"
                  fullWidth
                  autoFocus
                  type="email"
                  {...register("email", {
                    required: t("validation.required"),
                    pattern: {
                      value: emailRegEx,
                      message: t("validation.email"),
                    },
                  })}
                />
              }
            />
          )}

          <Button
            id={`apply-now-${idVariant}`}
            data-testid="apply-panel-btn"
            variant={ButtonVariant.PRIMARY}
            onClick={() => onApply(emailValue)}
            fullWidth
            disabled={isEmailDisabled && !isAuthenticated}
          >
            {t("applypanel.btn.text")}
          </Button>

          <DivIconButtonRowStyled>
            <IconButton
              id={`share-btn-email-${idVariant}`}
              data-testid="share-btn-email"
              aria-label={t("applypanel.sharebtn.email")}
              icon={<Icon.Email />}
              onClick={() => onShare(ShareMedium.EMAIL)}
              variant={ButtonVariant.SECONDARY}
            />
            <IconButton
              id={`share-btn-linkedin-${idVariant}`}
              data-testid="share-btn-linkedin"
              aria-label="Linked In"
              icon={<Icon.LinkedIn />}
              onClick={() => onShare(ShareMedium.LINKEDIN)}
              variant={ButtonVariant.SECONDARY}
            />
            <IconButton
              id={`share-btn-facebook-${idVariant}`}
              data-testid="share-btn-facebook"
              aria-label="Facebook"
              icon={<Icon.Facebook />}
              onClick={() => onShare(ShareMedium.FACEBOOK)}
              variant={ButtonVariant.SECONDARY}
            />
            <IconButton
              id={`share-btn-twitter-${idVariant}`}
              data-testid="share-btn-twitter"
              aria-label="Twitter"
              icon={<Icon.Twitter />}
              onClick={() => onShare(ShareMedium.TWITTER)}
              variant={ButtonVariant.SECONDARY}
            />
          </DivIconButtonRowStyled>
          <StyledTag
            icon={<IconLinkStyled />}
            text={t("applypanel.copyJobLink")}
            onClick={createCopyLink}
          />
        </>
      )}
      {alreadyApplied && (
        <>
          <Message
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("applypanel.alreadyApplied")}
          </Message>
          <Message
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("applypanel.checkOthers")}
          </Message>
          <Button
            id={`go-search-btn`}
            data-testid="go-search-btn"
            variant={ButtonVariant.PRIMARY}
            onClick={() => goToSearch()}
            fullWidth
          >
            {t("applypanel.btn.goSearch")}
          </Button>
          <Button
            id={`disregard-btn`}
            data-testid="disregard-btn"
            variant={ButtonVariant.PRIMARY}
            buttonType={ButtonType.MINIMAL}
            onClick={() => onDisregard(emailValue)}
            fullWidth
            style={{ marginBottom: "70px" }}
          >
            {t("applypanel.btn.disregard")}
          </Button>
        </>
      )}
    </PanelStyled>
  );
};
