import styled from "styled-components";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";

export type ApplyStepHeaderProps = {
  title: string;
  description: string;
};
const TypeographyStyled = styled(Typography)`
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

export const ApplyStepHeader = ({
  title,
  description,
}: ApplyStepHeaderProps) => {
  return (
    <>
      <TypeographyStyled
        variant={TypographyVariant.H2}
        weight={FontWeight.Bold}
        color={Color.Contrast}
      >
        {title}
      </TypeographyStyled>
      <Typography
        variant={TypographyVariant.TextDefault}
        weight={FontWeight.Regular}
        color={Color.Default}
      >
        {description}
      </Typography>
    </>
  );
};
