import { useQuery } from "react-query";
import axios from "utils/axios-api-base";

const queryKey: string = "HasFormCC305";

export const useAPIGetFormCC305 = () => {
  return useQuery(
    [queryKey],
    async (): Promise<string | null> => {
      const { status, data } = await axios.get(
        "Tenant/getTenantHasFormCC305", { headers: { TenantKey: window.location.hostname } }
      );

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
