import React, { useState, useCallback, useRef } from "react";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  HorizontalRule,
  StepperFooter,
  useStepper,
} from "@teamsoftware/reactcomponents";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormCC305SubSection, { FormCC305SubSectionHandle } from "./components/FormCC305SubSection/FormCC305SubSection";
import { useAPIQuestionSectionsByPostingId } from "api/job-posting/useAPIQuestionSectionsByPostingId";
import { QuestionSection } from "./components/QuestionSection/QuestionSection";
import {
  Question,
  ParseSectionIdFromFieldName,
  ParseQuestionIdFromFieldName,
} from "models/Question";
import { useAPIGetFormCC305 } from "api/client-features/useAPIGetFormCC305";

const regexGuidPattern = new RegExp(
  "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$",
  "i"
);

const StyledForm = styled.form`
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const MarginTypography = styled(Typography)`
  margin-top: 2rem;
`;

const BottomHorizontalRule = styled(HorizontalRule)`
  margin-top: 5rem;
`;

export type ApplicationQuestionProps = {
  postingId: string;
  disabled?: boolean;
  defaultValues?: ApplyQuestionData[];
  companyName: string | undefined;
  onSubmit: (data: ApplyQuestionData[]) => void;
};

export type ApplyQuestionData = {
  section: ApplyQuestionSectionData;
  questionId: string;
  externalQuestionId: string;
  questionText: string;
  answers: ApplyQuestionAnswerData[];
  questionDisplayNumber: string;
  isFollowup: boolean;
};

export type ApplyQuestionSectionData = {
  id: string;
  externalId?: string;
  name: string;
  rank: number;
};

export type ApplyQuestionAnswerData = {
  answerId: string | null;
  externalAnswerId?: string;
  answerText: string;
};

export const ApplicationQuestions = ({
  onSubmit,
  disabled,
  postingId,
  defaultValues,
  companyName,
}: ApplicationQuestionProps) => {
  const { t } = useTranslation();
  const { dispatch } = useStepper();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const [questions, setQuestions] = useState<Question[]>([]);
  const { data: dataSections } = useAPIQuestionSectionsByPostingId(postingId);
  const { isLoading: hasFormCC0305Loading, data: hasFormCC0305 } = useAPIGetFormCC305();
  const formCC305Ref = useRef<FormCC305SubSectionHandle>(null);

  const onSectionQuestionModelUpdate = useCallback(
    (updatedSectionQuestions: Question[] | null, sectionId: string): void => {
      setQuestions((prevData) => {
        let newQuestionArray: Question[] = prevData.filter(
          (q) => q.sectionId !== sectionId
        );

        if (!!updatedSectionQuestions) {
          newQuestionArray = newQuestionArray.concat(updatedSectionQuestions);
        }

        return newQuestionArray;
      });
    },
    []
  );

  const onStepSubmit = (data: any) => {
    let formattedQuestionData: ApplyQuestionData[] = [];
    let questionFieldNames: string[] = Object.keys(data);
    const stringsToRemove = ["tosCheckbox", "cc305Options"];
    const filteredQuestionFieldNames = questionFieldNames.filter(name => !stringsToRemove.includes(name));
    for (let i: number = 0; i < filteredQuestionFieldNames.length; i++) {
      let answerValue = data[filteredQuestionFieldNames[i]];

      let parsedSectionId: string = ParseSectionIdFromFieldName(
        filteredQuestionFieldNames[i]
      );

      let parsedQuestionId: string = ParseQuestionIdFromFieldName(
        filteredQuestionFieldNames[i]
      );

      let questionData: Question | undefined = questions.find(
        (q) => q.id === parsedQuestionId
      );

      const sectionData = dataSections?.find((s) => s.id === parsedSectionId);

      if (!!!questionData || !!!sectionData) {
        //BCG: Should only happen if data is corrupted somehow. Add some remote logging here?
        alert(
          `Error: Question data invalid for question ID '${parsedQuestionId}'`
        );
        return;
      } else if (parsedSectionId !== questionData.sectionId) {
        //BCG: Should only happen if data is corrupted somehow. Add some remote logging here?
        alert(
          `Error: Section ID invalid for question ID '${parsedQuestionId}'`
        );
        return;
      } else {
        let selectedAnswers: ApplyQuestionAnswerData[] = [];

        if (Array.isArray(answerValue)) {
           // This is a checkbox group
          let filteredCheckboxGroupAnswers = answerValue.filter(
            (x: { checked: boolean }) => x.checked
          );

          selectedAnswers = filteredCheckboxGroupAnswers.map(
            (x: { value: string; label: string; checked: boolean }) => {
              // This is an answerId
              let answerOption = questionData?.answerOptions.find(
                (o) => o.id === x.value
              );
              return {
                answerId: x.value,
                externalAnswerId: answerOption?.externalID,
                answerText: x.label,
              } as ApplyQuestionAnswerData;
            }
          );
        } else {
          // This is a string or an answerId

          if (regexGuidPattern.test(answerValue)) {
            // This is an answerId
            let answerOption = questionData?.answerOptions.find(
              (o) => o.id === answerValue
            );

            selectedAnswers.push({
              answerId: answerValue,
              externalAnswerId: answerOption?.externalID,
              answerText:
                answerOption?.text ?? "**Joblink Error setting answer text**",
            } as ApplyQuestionAnswerData);
          } else {
            // This is a string answer value
            selectedAnswers.push({
              answerId: null,
              answerText: answerValue,
            } as ApplyQuestionAnswerData);
          }
        }

        formattedQuestionData.push({
          section: {
            id: sectionData.id,
            externalId: sectionData.externalID,
            name: sectionData.name,
            rank: sectionData.rank,
          },
          questionId: questionData.id,
          externalQuestionId: questionData.externalID,
          questionText: questionData.text,
          answers: selectedAnswers,
          questionDisplayNumber: questionData.displayuNumber,
          isFollowup: !!questionData.followupQuestionForAnswerOptionID,
        } as ApplyQuestionData);
      }
    }

    if (formCC305Ref.current) {
      const formCC305Data = formCC305Ref.current.getFormCC305Data();
      if (formCC305Data.selectedOption !== null) {
        formattedQuestionData.push({
          section: {
            id: "cc305-section",
            name: "CC305 Section",
            rank: 0,
          },
          questionId: "form-cc305-question",
          externalQuestionId: "form-cc305-external-id",
          questionText: "Form CC305 Question",
          answers: [
            {
              answerId: formCC305Data.answerId,
              externalAnswerId: undefined,
              answerText: formCC305Data.selectedOption,
            },
          ],
          questionDisplayNumber: "1",
          isFollowup: false,
        });
      }
    }

    console.log('Formatted Question Data:', formattedQuestionData);

    onSubmit(formattedQuestionData);
    dispatch({ type: "setStepNext" });
  };


  return (
    <StyledForm onSubmit={handleSubmit(onStepSubmit)}>
      <MarginTypography
        variant={TypographyVariant.H2}
        weight={FontWeight.Bold}
        color={Color.Contrast}
        data-testid="app-questions-overview"
      >
        {t("apply.questions.step.title")}
      </MarginTypography>

      <Typography
        variant={TypographyVariant.P}
        weight={FontWeight.SemiBold}
        color={Color.Default}
      >
        {t("apply.questions.description")}
      </Typography>

      {!!dataSections &&
        dataSections.map((section) => (
          <QuestionSection
            key={section.id}
            postingId={postingId}
            sectionId={section.id}
            sectionName={section.name}
            validationErrors={errors}
            validationRegister={register}
            validationSetValue={setValue}
            validationSetErrors={setError}
            validationClearErrors={clearErrors}
            formWatch={watch}
            onSectionQuestionModelUpdate={onSectionQuestionModelUpdate}
            defaultValues={defaultValues}
            companyName={companyName}
          />
        ))}
      {hasFormCC0305 && (
        <>
          <FormCC305SubSection
            ref={formCC305Ref}
            register={register}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            errors={errors}
          />
        </>
      )}
      <BottomHorizontalRule />

      <StepperFooter submitOnNext={true} disabled={disabled} />
    </StyledForm>
  );
};
