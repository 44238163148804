// ##############
// Support IE 11
// ##############
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// ##############
import React from "react";
import ReactDOM from "react-dom";

import "./locale/i18n";
import App from "./pages/App/App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
