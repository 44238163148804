import React from "react";
import styled from "styled-components";
import { Link, LinkVariant, Icon, Mixins } from "@teamsoftware/reactcomponents";
import { LocationDescriptor } from "history";

export type BackLinkProps = {
  text: string;
  to?: LocationDescriptor;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const LinkStyled = styled(Link)`
  ${Mixins.Flex.row({ gap: 0.25 })}
  ${Mixins.Flex.align({ direction: "center" })}
  margin: 1rem 0;
`;

export const BackLink = ({
  text,
  to = "#",
  onClick,
  ...props
}: BackLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <LinkStyled
      to={to}
      variant={LinkVariant.SUBTLE}
      onClick={onClick}
      icon={<Icon.Chevron variant={Icon.ChevronVariant.LEFT} />}
      {...props}
    >
      {text}
    </LinkStyled>
  );
};
