import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  InputText,
  Textarea,
  InputGroup,
  RadioButtonGroup,
  RadioButtonGroupOption,
  CheckboxGroup,
  CheckboxGroupOption,
  CheckboxGroupOptionState,
} from "@teamsoftware/reactcomponents";

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormSetError,
  UseFormClearErrors,
  ErrorOption,
  FieldValues,
} from "react-hook-form";

import {
  Question as QuestionModel,
  QuestionType,
  QuestionTypeIdToEnum,
  GenerateFieldName,
} from "models/Question";

export type QuestionProps = {
  questionData: QuestionModel | null;
  defaultAnswer?: string[];
  sectionId: string;
  isFollowUp?: boolean;
  validationErrors: any;
  validationRegister: UseFormRegister<FieldValues>;
  validationSetValue: UseFormSetValue<FieldValues>;
  validationSetErrors: UseFormSetError<FieldValues>;
  validationClearErrors: UseFormClearErrors<FieldValues>;
  removeDefaultAnswer?: (questionId: string) => void;
};

const StyledInputGroup = styled(InputGroup)`
  ${up("md")} {
    max-width: 50%;
  }
  ${down("sm")} {
    max-width: 100%;
  }
`;

export const Question = ({
  questionData,
  defaultAnswer,
  sectionId,
  isFollowUp,
  validationErrors,
  validationRegister,
  validationSetValue,
  validationSetErrors,
  validationClearErrors,
  removeDefaultAnswer,
}: QuestionProps) => {
  const { t } = useTranslation();

  const [questionType, setQuestionType] = useState<QuestionType>(
    QuestionType.Unknown
  );

  const [freeFormTextValue, setFreeFormTextValue] = useState<string>(
    defaultAnswer ? defaultAnswer[0] : ""
  );

  useEffect(() => {
    if (!!questionData) {
      setQuestionType(QuestionTypeIdToEnum(questionData.type));
    }
  }, [questionData]);

  useEffect(
    () => () => {
      // Clear answer on unmount if follow up question
      if (!!questionData && isFollowUp) {
        validationSetValue(
          GenerateFieldName(sectionId, questionData.id),
          undefined
        );
      }

      // Clear default answer so it doesn't repopulate
      if (!!questionData && !!removeDefaultAnswer) {
        removeDefaultAnswer(questionData.id);
      }
    },
    []
  );

  // Register uncontrolled inputs on first render (e.g. CheckboxGroup)
  useEffect(() => {
    if (!!questionData) {
      if (
        QuestionTypeIdToEnum(questionData.type) ===
        QuestionType.MultipleChoiceMultiAnswer
      ) {
        validationRegister(GenerateFieldName(sectionId, questionData.id), {
          required: questionData.isRequired,
        });

        if (!!defaultAnswer) {
          let formattedDefaultAnswer: CheckboxGroupOptionState[] = [];

          questionData.answerOptions.forEach((answer, index) => {
            formattedDefaultAnswer.push({
              index: index,
              elementId: GenerateFieldName(sectionId, questionData.id),
              label: parse(answer.text),
              value: answer.id,
              checked: defaultAnswer?.includes(answer.id),
            } as CheckboxGroupOptionState);
          });

          validationSetValue(
            GenerateFieldName(sectionId, questionData.id),
            formattedDefaultAnswer,
            { shouldDirty: true }
          );
        } else {
          validationSetValue(GenerateFieldName(sectionId, questionData.id), []);
        }
      }
    }
  }, []);

  const onMultipleChoiceMultiAnswerChange = useCallback(
    (newState: CheckboxGroupOptionState[], data: any): void => {
      if (
        data.required &&
        (newState.length === 0 ||
          newState.every((value) => value.checked === false))
      ) {
        validationSetErrors(
          GenerateFieldName(sectionId, data.questionId),
          {} as ErrorOption
        );
      } else {
        validationClearErrors(GenerateFieldName(sectionId, data.questionId));
        validationSetValue(
          GenerateFieldName(sectionId, data.questionId),
          newState
        );
      }
    },
    [validationSetValue, validationSetErrors, validationClearErrors, sectionId]
  );

  const buildRadioButtonGroupOptions = useCallback(
    (questionData: QuestionModel): RadioButtonGroupOption[] => {
      if (questionData.isRequired) {
        return questionData.answerOptions.map((answerOption) => {
          return {
            label: parse(answerOption.text),
            value: answerOption.id,
          } as RadioButtonGroupOption;
        });
      }

      let returnList: RadioButtonGroupOption[] = questionData.answerOptions.map(
        (answerOption) => {
          return {
            label: parse(answerOption.text),
            value: answerOption.id,
          } as RadioButtonGroupOption;
        }
      );

      returnList.push({
        label: t("apply.questions.notapplicable"),
        value: "",
      } as RadioButtonGroupOption);

      return returnList;
    },
    [t]
  );

  let questionHeaderHtml: string = "";

  if (!!questionData) {
    questionHeaderHtml = `${questionData.displayuNumber}. ${questionData.text}`;

    if (questionData.isRequired) {
      questionHeaderHtml = "<span>*</span> " + questionHeaderHtml;
    }
  }

  return (
    <>
      {!!!questionData && (
        <Typography
          variant={TypographyVariant.P}
          weight={FontWeight.SemiBold}
          color={Color.Default}
        >
          Error Loading Question
        </Typography>
      )}

      {!!questionData && (
        <li key={questionData.id}>
          <Typography
            variant={TypographyVariant.P}
            weight={FontWeight.SemiBold}
            color={Color.Default}
            dangerouslySetInnerHTML={{
              __html: questionHeaderHtml,
            }}
          />

          {questionType === QuestionType.MultipleChoiceMultiAnswer && (
            <StyledInputGroup
              label=""
              data-testid={`input-group-section-${sectionId}-question-${questionData.displayuNumber}`}
              error={
                validationErrors[GenerateFieldName(sectionId, questionData.id)]
                  ? t("validation.required")
                  : undefined
              }
              input={
                <CheckboxGroup
                  id={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                  data-testid={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                  onChange={onMultipleChoiceMultiAnswerChange}
                  data={{
                    questionId: questionData.id,
                    required: questionData.isRequired,
                  }}
                  options={questionData.answerOptions.map((answerOption) => {
                    let defaultChecked: boolean | undefined =
                      defaultAnswer?.includes(answerOption.id);

                    return {
                      label: parse(answerOption.text),
                      value: answerOption.id,
                      defaultChecked: defaultChecked,
                    } as CheckboxGroupOption;
                  })}
                />
              }
            />
          )}
          {(questionType === QuestionType.MultipleChoiceSingleAnswer ||
            questionType === QuestionType.BinaryChoice) && (
            <>
              <StyledInputGroup
                label=""
                data-testid={`input-group-section-${sectionId}-question-${questionData.displayuNumber}`}
                error={
                  validationErrors[
                    GenerateFieldName(sectionId, questionData.id)
                  ]?.message
                }
                input={
                  <RadioButtonGroup
                    id={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                    data-testid={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                    options={buildRadioButtonGroupOptions(questionData)}
                    {...validationRegister(
                      GenerateFieldName(sectionId, questionData.id),
                      {
                        required: questionData.isRequired
                          ? t("validation.required")
                          : false,
                      }
                    )}
                    defaultValue={defaultAnswer ? defaultAnswer[0] : undefined}
                  />
                }
              />
            </>
          )}
          {questionType === QuestionType.SingleLine && (
            <StyledInputGroup
              label=""
              data-testid={`input-group-section-${sectionId}-question-${questionData.displayuNumber}`}
              error={
                validationErrors[GenerateFieldName(sectionId, questionData.id)]
                  ? t("validation.required")
                  : undefined
              }
              input={
                <InputText
                  id={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                  data-testid={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                  fullWidth={true}
                  {...validationRegister(
                    GenerateFieldName(sectionId, questionData.id),
                    {
                      required: questionData.isRequired
                        ? t("validation.required")
                        : false,
                      onChange: (e) => {
                        setFreeFormTextValue(e.target.value);
                      },
                    }
                  )}
                  value={freeFormTextValue}
                />
              }
            />
          )}
          {questionType === QuestionType.MultiLine && (
            <StyledInputGroup
              label=""
              data-testid={`input-group-section-${sectionId}-question-${questionData.displayuNumber}`}
              error={
                validationErrors[GenerateFieldName(sectionId, questionData.id)]
                  ? t("validation.required")
                  : undefined
              }
              input={
                <Textarea
                  id={`section-${sectionId}-question-${questionData.displayuNumber}`}
                  data-testid={`section-${sectionId}-question-input-${questionData.displayuNumber}`}
                  {...validationRegister(
                    GenerateFieldName(sectionId, questionData.id),
                    {
                      required: questionData.isRequired
                        ? t("validation.required")
                        : false,
                      onChange: (e) => {
                        setFreeFormTextValue(e.target.value);
                      },
                    }
                  )}
                  value={freeFormTextValue}
                />
              }
            />
          )}
        </li>
      )}
    </>
  );
};
