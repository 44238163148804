import { useQuery } from "react-query";
import axios from "utils/axios-api-base";
import { TrackingTag } from "models/TrackingTag";

const queryKey: string = "TrackingTags";

type getTrackingTagsInfoRequest = {
  domain: string;
};

export const useAPITrackingTags = (request: getTrackingTagsInfoRequest) => {
  return useQuery(
    queryKey,
    async (): Promise<TrackingTag[] | null> => {
      const { status, data } = await axios.get("TrackingTags", {
        headers: {
          TrackingTagsKey: request.domain,
        },
      });
      // NoContent?
      if (status === 404) return null;
      // Has TrackingTags
      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
