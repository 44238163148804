import { useQuery } from "react-query";
import axios from "utils/axios-api-base";
import { Territory } from "locale/models/Territory";

const queryKey: string = "Territories";

export const useAPIGetTerritories = () => {
  return useQuery(
    [queryKey],
    async (): Promise<Territory[] | null> => {
      const { status, data } = await axios.get("Location/GetAllTerritories");

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
