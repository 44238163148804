import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";

import { JobListingContainer } from "components/JobListingContainer/JobListingContainer";
import { Header } from "components/Header/Header";
import { Title } from "./components/Title";
import { bodyTags } from "context/trackingTagsContext";

const DivStyled = styled.div`
  padding: 0 1rem;
`;

function createTrackingTagsBody(object: string) {
  return { __html: object };
}

export const JobSearchPage = () => {
  const desktopBreakpoint = useBreakpoint(up("md"));
  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header leftComponent={desktopBreakpoint ? <Title /> : undefined} />
      <DivStyled>
        {!desktopBreakpoint && <Title />}
        <JobListingContainer />
      </DivStyled>
    </>
  );
};
