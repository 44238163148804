import { useEffect } from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Checkbox,
  HorizontalRule,
  InputGroup,
  InputText,
  Mixins,
} from "@teamsoftware/reactcomponents";
import { useForm } from "react-hook-form";
import moment from "moment";

import { ApplyStepHeader } from "components/ApplyStepHeader";

export type ApplyCertificationHistoryProps = {
  defaultValue?: ApplyCertificationHistoryFormData;
  onSubmit: (data: ApplyCertificationHistoryFormData) => void;
  onRequestClose: () => void;
};

export type ApplyCertificationHistoryFormData = {
  id?: string;
  name: string;
  license: string;
  issuer: string;
  issued?: Date;
  inProgress: boolean;
};

const StyledDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const HorizontalRuleStyled = styled(HorizontalRule)`
  margin: 1rem 0;
`;

const HorizontalRuleFooterStyled = styled(HorizontalRule)`
  margin-top: 3.75rem;
  margin-bottom: 2.75rem;
`;

const FooterButtonGroupDiv = styled.div`
  ${Mixins.Flex.row({ gap: 0.5 })}
`;

const FormContainer = styled.div`
  ${Mixins.Flex.column({ gap: 1 })}
`;

export const ApplyCertificationHistory = ({
  defaultValue,
  onSubmit,
  onRequestClose,
}: ApplyCertificationHistoryProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<ApplyCertificationHistoryFormData>({
    defaultValues: {
      ...defaultValue,
    },
  });

  const inProgress = watch("inProgress");
  const dateIssued = watch("issued");
  const isEditing = !!defaultValue;
  const MINIMUM_DATE: string = "01/01/1945";

  const determineIssuedDateValue = (): string => {
    if (defaultValue?.issued && !!!dirtyFields.issued) {
      return moment(defaultValue.issued).toISOString().substring(0, 10);
    } else {
      if (
        dateIssued !== undefined &&
        dateIssued !== null &&
        dateIssued.toLocaleString().length > 0
      ) {
        return moment(dateIssued).toISOString().substring(0, 10);
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (inProgress) setValue("issued", undefined);
  }, [setValue, inProgress]);

  return (
    <StyledDiv>
      <ApplyStepHeader
        title={t("apply.certificationhistory.header")}
        description={t("apply.certificationhistory.description")}
      />
      <HorizontalRuleStyled />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <InputGroup
            label={`${t("apply.certificationhistory.form.name.label")}*`}
            error={errors.name?.message}
            input={
              <InputText
                id="certificationhistory-name"
                data-testid="certificationhistory-name"
                placeholder={t(
                  "apply.certificationhistory.form.name.placeholder"
                )}
                fullWidth
                {...register("name", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.certificationhistory.form.license.label")}*`}
            error={errors.license?.message}
            input={
              <InputText
                id="certificationhistory-license"
                data-testid="certificationhistory-license"
                placeholder={t(
                  "apply.certificationhistory.form.license.placeholder"
                )}
                fullWidth
                {...register("license", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.certificationhistory.form.issuer.label")}*`}
            error={errors.issuer?.message}
            input={
              <InputText
                id="certificationhistory-issuer"
                data-testid="certificationhistory-issuer"
                placeholder={t(
                  "apply.certificationhistory.form.issuer.placeholder"
                )}
                fullWidth
                {...register("issuer", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.certificationhistory.form.issued.label")}*`}
            error={errors.issued?.message}
            input={
              <InputText
                id="certificationhistory-issued"
                data-testid="certificationhistory-issued"
                type="date"
                placeholder={moment(new Date()).format()}
                value={determineIssuedDateValue()}
                disabled={inProgress}
                {...register("issued", {
                  required: inProgress ? undefined : t("validation.required"),
                  validate: (value: Date | undefined) => {
                    const isValidDate =
                      !!value &&
                      moment(value) < moment() &&
                      moment(value) > moment(new Date(MINIMUM_DATE));
                    if (!isValidDate && !inProgress) {
                      return t("validation.dateBetween", {
                        startDate: MINIMUM_DATE,
                        endDate: moment().format("L").toString(),
                      });
                    }
                  },
                })}
              />
            }
          />
          <InputGroup
            label={t("apply.certificationhistory.form.inprogress.label")}
            input={
              <Checkbox
                id="certificationhistory-inprogress"
                data-testid="certificationhistory-inprogress"
                {...register("inProgress")}
              />
            }
          />
        </FormContainer>
        <HorizontalRuleFooterStyled />
        <FooterButtonGroupDiv>
          <Button
            type="submit"
            variant={ButtonVariant.PRIMARY}
            buttonSize={ButtonSize.LARGE}
            data-testid="educationhistory-submitbtn"
          >
            {isEditing
              ? t("apply.modalupdatebutton")
              : t("apply.modalsavebutton")}
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.SUBTLE}
            buttonType={ButtonType.MINIMAL}
            onClick={() => onRequestClose()}
            data-testid="educationhistory-cancelbtn"
          >
            {t("apply.modalcancelbutton")}
          </Button>
        </FooterButtonGroupDiv>
      </form>
    </StyledDiv>
  );
};
