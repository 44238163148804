import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Translations } from "locale";
import { Locale } from "@teamsoftware/reactcomponents";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr-ca";

const resources = {
  en: {
    ...Locale.en,
    translation: Translations.en,
  },
  es: {
    ...Locale.es,
    translation: Translations.es,
  },
  frCA: {
    ...Locale.frCA,
    translation: Translations.frCA,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    fallbackLng: "en",
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: ["translation", "teamsoftware_reactcomponents"],
    defaultNS: "translation",
    fallbackNS: ["translation"],
    returnNull: false,
  });

moment.locale(i18n.language);

export default i18n;

// Make typed translations possible
declare module "react-i18next" {
  export interface Resources {
    translation: typeof resources.en.translation;
    teamsoftware_reactcomponents: typeof resources.en.teamsoftware_reactcomponents;
  }
}

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
