import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStepper } from "@teamsoftware/reactcomponents";

import { ApplyExperienceAndEducationData } from "components/ApplyExperienceAndEducation";
import { Card } from "../Card";

export type ExperienceCardProps = {
  stepIndex: number;
  data: ApplyExperienceAndEducationData;
};

const CardItemDiv = styled.div`
  margin-top: 1.5rem;
`;

type ExperienceCardItem = {
  key: string;
  title: string;
  line1?: string;
  line2?: string;
};

export const ExperienceCard = ({ stepIndex, data }: ExperienceCardProps) => {
  const { t } = useTranslation();

  const { dispatch } = useStepper();

  const [isSectionExperienceExpanded, setIsSectionExperienceExpanded] =
    useState<boolean>(false);

  const goToStep = () => {
    dispatch({ type: "setStep", payload: stepIndex });
  };

  const experienceItemsToDisplay: ExperienceCardItem[] = [];
  if (
    !!data &&
    (data.employmentHistory.length > 0 ||
      data.educationHistory.length > 0 ||
      data.certificationHistory.length > 0)
  ) {
    if (data.employmentHistory.length > 0) {
      experienceItemsToDisplay.push(
        ...data.employmentHistory.map<ExperienceCardItem>((item, i) => {
          const dateStartFormatted = moment(item.startDate).format("MMM YYYY");
          const dateEndFormatted = !!item.endDate
            ? moment(item.endDate).format("MMM YYYY")
            : t("apply.experience.employmenthistory.current");
          const datesFormatted = `${dateStartFormatted} - ${dateEndFormatted}`;
          const hasCompanyName =
            !!item.companyName && item.companyName.length > 0;
          return {
            key: item.id ?? i.toString(),
            title: item.jobTitle,
            line1: hasCompanyName ? item.companyName : datesFormatted,
            line2: hasCompanyName ? datesFormatted : undefined,
          };
        })
      );
    }
    if (data.educationHistory.length > 0) {
      experienceItemsToDisplay.push(
        ...data.educationHistory.map<ExperienceCardItem>((item, i) => {
          const hasDegree = !!item.degree && item.degree.length > 0;
          const dateStartFormatted = !!item.graduation
            ? `${t("apply.experience.educationhistory.graduated")}: ` +
              moment(item.graduation).format("MMM YYYY")
            : undefined;
          return {
            key: item.id ?? i.toString(),
            title: item.school,
            line1: hasDegree ? item.degree : dateStartFormatted,
            line2: hasDegree ? dateStartFormatted : undefined,
          };
        })
      );
    }
    if (data.certificationHistory.length > 0) {
      experienceItemsToDisplay.push(
        ...data.certificationHistory.map<ExperienceCardItem>((item, i) => {
          const hasIssuer = !!item.issuer && item.issuer.length > 0;
          const dateStartFormatted = !!item.issued
            ? `${t("apply.experience.certificationhistory.issued")}: ` +
              moment(item.issued).format("MMM YYYY")
            : undefined;
          return {
            key: item.id ?? i.toString(),
            title: item.name,
            line1: hasIssuer ? item.issuer : dateStartFormatted,
            line2: hasIssuer ? dateStartFormatted : undefined,
          };
        })
      );
    }
  }

  return (
    <>
      {!!experienceItemsToDisplay && experienceItemsToDisplay.length > 0 && (
        <Card
          title={t("apply.experience.step.title")}
          canExpand
          isExpanded={isSectionExperienceExpanded}
          onToggleExpand={() => setIsSectionExperienceExpanded((x) => !x)}
          onClickEdit={() => goToStep()}
        >
          {experienceItemsToDisplay.map((item, i) => {
            if (!isSectionExperienceExpanded && i > 1) return;
            if (!isSectionExperienceExpanded && i === 1) item.line2 = undefined; // Cut off the last line of the second item to fit an unexpanded card
            return (
              <CardItemDiv key={item.key}>
                <p>
                  <b>{item.title}</b>
                </p>
                {!!item.line1 && <p>{item.line1}</p>}
                {!!item.line2 && <p>{item.line2}</p>}
              </CardItemDiv>
            );
          })}
        </Card>
      )}
    </>
  );
};
