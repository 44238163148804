import styled from "styled-components";
import {
  HorizontalRule,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";

export type ApplyStepSubHeaderProps = {
  title: string;
  "data-testid"?: string;
};

const TypographyH4Styled = styled(Typography)`
  margin-top: 2.5rem;
`;

const HorizontalRuleStyled = styled(HorizontalRule)`
  margin: 1rem 0;
`;

export const ApplyStepSubHeader = ({
  title,
  ...props
}: ApplyStepSubHeaderProps) => {
  return (
    <>
      <TypographyH4Styled
        variant={TypographyVariant.H4}
        weight={FontWeight.SemiBold}
        color={Color.Subtle}
        {...props}
      >
        {title}
      </TypographyH4Styled>
      <HorizontalRuleStyled />
    </>
  );
};
