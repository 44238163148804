import { useMutation } from "react-query";
import { AxiosError } from "axios";
import axios from "utils/axios-api-base";

export type CountryTerritoryRequest = {
  countryName: string;
  territoryName: string;
};

export const useAPIGetCountryAndTerritoryId = <TData, TContext>(
  onSuccessCallback?: (
    data: TData,
    request: CountryTerritoryRequest,
    context: TContext | undefined
  ) => Promise<void> | void,

  onErrorCallback?: (
    error: AxiosError,
    request: CountryTerritoryRequest,
    context: TContext | undefined
  ) => Promise<void> | void
) => {
  return useMutation<TData, AxiosError, CountryTerritoryRequest, TContext>(
    (request) => {
      return axios.get("Application/GetCountryAndTerritoryId", {
        params: request,
      });
    },
    {
      onError: async (error, request, context) => {
        if (onErrorCallback) {
          onErrorCallback(error, request, context);
        }
      },

      onSuccess: async (data, request, context) => {
        if (onSuccessCallback) {
          onSuccessCallback(data, request, context);
        }
      },
    }
  );
};
