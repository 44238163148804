import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import {
  Mixins,
  Button,
  ButtonVariant,
  FontWeight,
  Icon,
  Typography,
  TypographyVariant,
  Color,
  ButtonSize,
  ButtonType,
} from "@teamsoftware/reactcomponents";
import { useTranslation } from "react-i18next";

export type CardProps = {
  title: string;
  canExpand?: boolean;
  isExpanded?: boolean;
  onToggleExpand?: () => void;
  onClickEdit: () => void;
};

const ContainerDiv = styled.div`
  ${Mixins.BaseMixins.borderRadius({ radius: "0.625rem" })}
  ${up("md")} {
    ${Mixins.Flex.row()}
  }
  ${down("sm")} {
    ${Mixins.Flex.column()}
  }
  border: 1px solid ${(props) => props.theme.Hr.Line};
  padding: 1rem;
  min-height: 13.5rem;
  position: relative;
`;

const CardTextDiv = styled.div`
  margin-top: 0.5rem;

  p {
    margin-top: 0.5rem;
    line-height: 1.5rem;
    b {
      font-weight: 600;
    }
  }
`;

const FlexDiv = styled.div`
  ${Mixins.Flex.flex()}
`;

const CardTextContainerDiv = styled.div`
  ${Mixins.Flex.flex()}
  padding: 0 1rem;
`;

const EditButton = styled(Button)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const ExpandButton = styled(Button)`
  ${up("md")} {
    ${Mixins.Flex.alignSelf({ direction: "end" })}
  }
  ${down("sm")} {
    ${Mixins.Flex.alignSelf({ direction: "start" })}
  }
`;

export const Card: React.FC<CardProps> = ({
  title,
  canExpand = false,
  isExpanded = false,
  onToggleExpand,
  onClickEdit,
  children,
}) => {
  const { t } = useTranslation();
  var desktopBreakpoint = useBreakpoint(up("md"));
  return (
    <ContainerDiv>
      <CardTextContainerDiv>
        <Typography
          variant={TypographyVariant.H4}
          weight={FontWeight.SemiBold}
          color={Color.Default}
          data-testid="applyreview-card-title"
        >
          {title}
        </Typography>
        <CardTextDiv>{children}</CardTextDiv>
      </CardTextContainerDiv>
      {canExpand && (
        <ExpandButton
          data-testid="applyreview-card-expand-btn"
          type="button"
          variant={ButtonVariant.SECONDARY}
          buttonSize={ButtonSize.SMALL}
          buttonType={ButtonType.MINIMAL}
          onClick={() => onToggleExpand?.()}
        >
          {isExpanded && (
            <>
              {t("apply.review.card.expandbtn_expanded")}{" "}
              <Icon.Chevron variant={Icon.ChevronVariant.UP} />
            </>
          )}
          {!isExpanded && (
            <>
              {t("apply.review.card.expandbtn_collapsed")}{" "}
              <Icon.Chevron variant={Icon.ChevronVariant.DOWN} />
            </>
          )}
        </ExpandButton>
      )}
      {desktopBreakpoint && <FlexDiv></FlexDiv>}
      <EditButton
        data-testid="applyreview-card-edit-btn"
        type="button"
        variant={ButtonVariant.SUBTLE}
        onClick={() => onClickEdit()}
      >
        {t("apply.review.card.editbtn")}
      </EditButton>
    </ContainerDiv>
  );
};
