import react from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import {
  Typography,
  TypographyVariant,
  Color,
  FontWeight,
} from "@teamsoftware/reactcomponents";
import { Header } from "components/Header";
import { bodyTags } from "context/trackingTagsContext";

export type HelpPageProps = {
  id: string;
};

const TitleTypography = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const TextTypography = styled(Typography)`
  white-space: pre-wrap;
  b {
    font-weight: bold;
  }
`;

export const HelpPage: React.FC = () => {
  const { t } = useTranslation();
  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }
  return (
    <>
      {bodyTags.map(function (object, i) {
        return (
          <div
            dangerouslySetInnerHTML={createTrackingTagsBody(object)}
            key={i}
          />
        );
      })}
      <Header leftComponent={<div></div>} />
      <div>
        <>
          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.mfaq")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("help.mfaqcontent")}
          </TextTypography>

          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.howtoapply")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            <div
              dangerouslySetInnerHTML={{ __html: t("help.howtoapplycontent") }}
            />
          </TextTypography>

          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.howtocontact")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("help.howtocontactcontent")}
          </TextTypography>

          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.commonerrors")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("help.commonerrorscontent")}
          </TextTypography>

          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.howtoenablecookies")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("help.howtoenablecookiescontent")}
          </TextTypography>

          <TitleTypography
            variant={TypographyVariant.H1}
            weight={FontWeight.Bold}
            color={Color.Default}
          >
            {t("help.stillneedhelp")}
          </TitleTypography>
          <TextTypography
            variant={TypographyVariant.TextDefault}
            weight={FontWeight.Regular}
            color={Color.Default}
          >
            {t("help.stillneedhelpcontent1")}
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=zZDp4Lh7Mkuv0HPooyryNG6tOqlug9NBnpOJIZOYAkJUMDZIR0M3Q1RRRjNURE5TNDdPRFZITTQ0Wi4u"
              target="_blank"
            >
              {t("help.stillneedhelplink")}
            </a>
            {t("help.stillneedhelpcontent2")}
          </TextTypography>
        </>
      </div>
    </>
  );
};
