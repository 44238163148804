import React, { useContext, createContext, FunctionComponent } from "react";
import { useAPITenant } from "api/tenant/useAPITenant";

export type Tenant = {
  id: string;
  isLoading: boolean;
  name: string;
  styleURL?: string;
  scriptURL?: string;
};

const TenantContext = createContext<Tenant | null>(null);

export const TenantContextProvider: FunctionComponent = (props) => {
  const { isLoading, data } = useAPITenant({
    domain: window.location.hostname,
  });

  let tenant: Tenant = {
    id: "",
    name: "",
    isLoading: isLoading,
  };

  if (!isLoading && !!data) {
    tenant = {
      ...tenant,
      id: data.id,
      isLoading: isLoading,
      name: data.tenantName,
      styleURL: data.hasStylesheet
        ? `${process.env.REACT_APP_TENANT_BUCKET_BASE_URL}/${data.id}/style.css`
        : undefined,
      scriptURL: data.hasScript
        ? `${process.env.REACT_APP_TENANT_BUCKET_BASE_URL}/${data.id}/script.js`
        : undefined,
    };
  }

  return (
    <TenantContext.Provider value={tenant}>
      {props.children}
    </TenantContext.Provider>
  );
};

export const useTenant = (): Tenant => {
  const tenant = useContext<Tenant | null>(TenantContext);

  if (tenant === null)
    throw new Error(
      "Make sure this component has a <TenantContextProvider /> somewhere above itself in the component tree."
    );

  return tenant;
};
