import axios from "axios";
import moment from "moment";

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const isIsoDateString = (value: any): boolean => {
  return value && typeof value === "string" && isoDateFormat.test(value);
};

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = moment(value);
    } else if (typeof value === "object") {
      handleDates(value);
    }
  }
};

// Parse dates into moment objects
instance.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
