import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  HorizontalRule,
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  RadioButtonGroup,
  Checkbox,
  InputGroup,
  Icon,
} from "@teamsoftware/reactcomponents";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UseFormRegister, FieldValues, ErrorOption } from "react-hook-form";
import CC305Modal from "components/CC305Modal/CC305Modal";

const SectionHeaderTypography = styled(Typography)`
  margin-top: 2rem;
  white-space: pre-wrap;
`;

const MarginSpacer = styled.div`
  margin-bottom: 2rem;
`;

const StatementText = styled(Typography)`
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const InputGroupStyled = styled(InputGroup)`
  margin-top: 1rem;
`;

interface FormCC305SubSectionProps {
  register: UseFormRegister<FieldValues>;
  setValue: (name: string, value: any) => void;
  watch: (name: string) => any;
  clearErrors: (name: string) => void;
  setError: (name: string, error: ErrorOption) => void;
  errors: { [key: string]: any };
  questionData?: {
    question: string;
    answerOptions: { id: string; text: string }[];
  };
}

export interface FormCC305SubSectionHandle {
  getFormCC305Data: () => {
    selectedOption: string | null;
    isReadOnly: boolean;
    answerId: string | null;
    tosChecked: boolean;
  };
  setTosError: (error: boolean) => void;
}

const FormCC305SubSection = forwardRef<FormCC305SubSectionHandle, FormCC305SubSectionProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { register, setValue, watch, clearErrors, setError, errors, questionData } = props;

    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [tosChecked, setTosChecked] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const defaultQuestionData = {
      question: t("nonDiscriminationAffirmativeAction.question"),
      answerOptions: [
        { id: "1", text: t("nonDiscriminationAffirmativeAction.selectionTexts.yesSelectionText") },
        { id: "2", text: t("nonDiscriminationAffirmativeAction.selectionTexts.noSelectionText") },
        { id: "3", text: t("nonDiscriminationAffirmativeAction.selectionTexts.doNotSelectionText") },
      ],
    };

    const questionDataToUse = questionData || defaultQuestionData;

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isReadOnly) {
        const value = event.target.value;
        setSelectedOption(value);
        const answerId = questionDataToUse.answerOptions.find(option => option.text === value)?.id || null;
        setSelectedAnswerId(answerId);
        setValue("cc305Options", value);
        clearErrors("cc305Options");
      }
    };

    const handleTosCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setTosChecked(isChecked);
      setValue("tosCheckbox", isChecked);
      if (isChecked) {
        clearErrors("tosCheckbox");
      } else {
        setError("tosCheckbox", {
          type: "manual",
          message: t("validation.required"),
        });
      }
    };

    const openModal = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };
    useImperativeHandle(ref, () => ({
      getFormCC305Data: () => ({
        selectedOption,
        isReadOnly,
        answerId: selectedAnswerId,
        tosChecked,
      }),
      setTosError: (error: boolean) => {
        if (error) {
          setError("tosCheckbox", {
            type: "manual",
            message: t("validation.required"),
          });
        } else {
          clearErrors("tosCheckbox");
        }
      },
    }));

    return (
      <div>
        <SectionHeaderTypography
          variant={TypographyVariant.H4}
          weight={FontWeight.SemiBold}
          color={Color.Subtle}
        >
          {t("nonDiscriminationAffirmativeAction.header")}
        </SectionHeaderTypography>
        <HorizontalRule />
        <StatementText
          variant={TypographyVariant.Subhead}
          weight={FontWeight.Regular}
          color={Color.Default}
        >
          {t("nonDiscriminationAffirmativeAction.organizationStatement")}
        </StatementText>
        <StyledButton
          buttonSize={ButtonSize.SMALL}
          buttonType={ButtonType.STANDARD}
          onClick={(event) => {
            event.preventDefault();
            openModal();
          }}
          variant={ButtonVariant.SUBTLE}
          iconLeft={<Icon.Document />}
        >
          {t("nonDiscriminationAffirmativeAction.viewFormCC305")}
        </StyledButton>
        <InputGroupStyled
          label={t("apply.termsandconditions.label")}
          data-testid="tosCheck"
          error={errors.tosCheckbox?.message}
          input={
            <Checkbox
              id="tosCheckbox"
              data-testid="tos-checkbox"
              {...register("tosCheckbox", {
                required: t("validation.required"),
              })}
              checked={tosChecked}
              onChange={handleTosCheckboxChange}
            />
          }
        />
        <InputGroupStyled
          label={
            <Typography
              variant={TypographyVariant.TextDefault}
              weight={FontWeight.Bold}
            >
              {t("nonDiscriminationAffirmativeAction.pleaseSelectOne")}
            </Typography>
          }
          data-testid="cc305-nondiscrimination"
          error={errors.cc305Options?.message}
          input={
            <RadioButtonGroup
              id="cc305-subsection-options"
              value={watch("cc305Options") || ""}
              {...register("cc305Options", {
                required: t("validation.required")
              })}
              onChange={handleOptionChange}
              options={questionDataToUse.answerOptions.map(option => ({
                label: option.text,
                value: option.text,
              }))}
            />
          }
        />
        <MarginSpacer />
        <StatementText
          variant={TypographyVariant.TextDefault}
          weight={FontWeight.Regular}
          color={Color.Default}
        >
          {t("nonDiscriminationAffirmativeAction.ombControlNumber")}
          <br />
          {t("nonDiscriminationAffirmativeAction.expirationDate")}
        </StatementText>
        <StatementText
          variant={TypographyVariant.TextDefault}
          weight={FontWeight.Regular}
          color={Color.Default}
        >
          {t("nonDiscriminationAffirmativeAction.publicBurdenStatement")}
        </StatementText>
        {isModalOpen && (
          <CC305Modal
            onClose={closeModal}
            onCloseIntent={closeModal}
            onOpen={() => {}}
            hasCloseIntent={true}
          />
        )}
      </div>
    );
  }
);

export default FormCC305SubSection;
