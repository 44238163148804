import { useMutation } from "react-query";
import { AxiosError } from "axios";

import axios from "utils/axios-api-base";
import { StandardApplicantPlusCaptcha } from "models/StandardApplicant";

export const useAPISubmitApplicant = <TData, TContext>(
  onSuccessCallback?: (
    data: TData,
    applicant: StandardApplicantPlusCaptcha,
    context: TContext | undefined
  ) => Promise<void> | void,

  onErrorCallback?: (
    error: AxiosError,
    applicant: StandardApplicantPlusCaptcha,
    context: TContext | undefined
  ) => Promise<void> | void
) => {
  return useMutation<TData, AxiosError, StandardApplicantPlusCaptcha, TContext>(
    (applicant) => {
      return axios.post("Application/Submit", applicant, {});
    },
    {
      onError: async (error, applicant, context) => {
        if (onErrorCallback) {
          onErrorCallback(error, applicant, context);
        }
      },

      onSuccess: async (data, applicant, context) => {
        if (onSuccessCallback) {
          onSuccessCallback(data, applicant, context);
        }
      },
    }
  );
};
