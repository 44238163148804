import {
  Link,
  Mixins,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Header } from "components/Header";
import { bodyTags } from "context/trackingTagsContext";

const ContainerStyled = styled.div`
  ${Mixins.Flex.column()}
  ${Mixins.Flex.align({ direction: "center" })}
`;

const IconDiv = styled.div`
  font-size: 8rem;
  svg {
    display: block;
  }
  padding-top: 4rem;
`;

const HeaderStyled = styled(Typography)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 1.5rem;
`;

const PStyled = styled.p`
  padding-left: 1rem;
  margin-top: 1rem;
  max-width: 25rem;
  line-height: 1.5rem;
  b {
    font-weight: 600;
  }
`;

const LinkStyled = styled(Link)`
  margin-top: 2rem;
`;

export const PasswordlessLoginExpiredPage: React.FC = () => {
  const { t } = useTranslation();
  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }
  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header />
      <ContainerStyled>
        <>
          <IconDiv>
            <img
              src={process.env.PUBLIC_URL + "/magnifyingGlassIcon.png"}
              width="120rem"
              height="120rem"
            />
          </IconDiv>
          <HeaderStyled
            variant={TypographyVariant.H1}
            weight={FontWeight.SemiBold}
            color={Color.Default}
          >
            {t("passwordlessLogin.linkExpired.title")}
          </HeaderStyled>
          <PStyled>{t("passwordlessLogin.linkExpired.message")}</PStyled>
          <LinkStyled to="/">
            {t("passwordlessLogin.linkExpired.careerPageLink")}
          </LinkStyled>
        </>
      </ContainerStyled>
    </>
  );
};
