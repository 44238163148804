import { useQuery } from "react-query";
import axios from "utils/axios-api-base";

const queryKey: string = "Authentication";

export const useAPIAuthenticate = (userId: string) => {
  return useQuery(
    [queryKey, userId],
    async (): Promise<string | null> => {
      if (userId === null) {
        return null;
      }
      const { status, data } = await axios.get(`Passwordless/${userId}`);
      // NoContent
      if (status === 401) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
