import {
  Icon,
  Link,
  Loading,
  Mixins,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAPIJobDetails } from "api/job-posting/useAPIJobDetails";
import { BackLink } from "components/BackLink";
import { Header } from "components/Header";
import { CircleVariant } from "@teamsoftware/reactcomponents/dist/components/Icon";
import { bodyTags } from "context/trackingTagsContext";
import { useParams } from "react-router-dom";

export type ApplicationConfirmationProps = {
  id: string;
};

const ContainerStyled = styled.div`
  ${Mixins.Flex.column()}
  ${Mixins.Flex.align({ direction: "center" })}
`;

const IconDiv = styled.div`
  font-size: 8rem;
  color: rgba(103, 161, 66, 1);
  svg {
    display: block;
  }
`;

const HeaderStyled = styled(Typography)`
  margin-top: 1.5rem;
`;

const PStyled = styled.p`
  margin-top: 1rem;
  max-width: 37.5rem;
  line-height: 1.5rem;
  b {
    font-weight: 600;
  }
`;

const LinkStyled = styled(Link)`
  margin-top: 2rem;
`;

export const ApplicationConfirmation: React.FC = () => {
  let { id }: any = useParams();
  const { t } = useTranslation();
  const { isLoading, data } = useAPIJobDetails(id);
  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }
  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header
        leftComponent={
          <BackLink
            id="backToSearchLink"
            data-testid="nav-back"
            text={t("job.nav.back")}
            to={`/`}
          />
        }
      />
      <ContainerStyled>
        <Loading isLoading={isLoading} />
        {!isLoading && (
          <>
            <IconDiv>
              <Icon.Circle variant={CircleVariant.CHECK_FILLED} />
            </IconDiv>
            <HeaderStyled
              variant={TypographyVariant.H1}
              weight={FontWeight.SemiBold}
              color={Color.Default}
            >
              {t("confirmation.title")}
            </HeaderStyled>
            <PStyled>
              {t("confirmation.messagePartA")}&nbsp;
              <b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.jobTitle ?? "????",
                  }}
                />
              </b>
              {t("confirmation.messagePartB")}
            </PStyled>
            <LinkStyled to="/">{t("confirmation.backBtn")}</LinkStyled>
          </>
        )}
      </ContainerStyled>
    </>
  );
};
