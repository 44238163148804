import { useTranslation } from "react-i18next";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled, { css } from "styled-components";

type TypographyStyledProps = {
  isMobile: boolean;
};

const TypographyStyled = styled(Typography)<TypographyStyledProps>`
  ${(props) =>
    props.isMobile &&
    css`
      margin: 1rem 0;
    `}
`;

export const Title = () => {
  const { t } = useTranslation();
  const desktopBreakpoint = useBreakpoint(up("md"));
  return (
    <TypographyStyled
      id="pageTitleHeader"
      variant={TypographyVariant.H1}
      weight={FontWeight.Bold}
      color={Color.Contrast}
      data-testid="page-title"
      isMobile={!desktopBreakpoint}
    >
      {t("jobsearch.title")}
    </TypographyStyled>
  );
};
