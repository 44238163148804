import { FunctionComponent } from "react";
import styled from "styled-components";
import { Mixins } from "@teamsoftware/reactcomponents";

const DivStyled = styled.div`
  ${Mixins.Flex.row()}
  //display: none; // Whitelabel override
  padding: 20px;
`;

const SpanStyled = styled.span`
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 50px;
  // Whitelabel override:
  // background-image: url(https://teamsoftware.com/wp-content/uploads/2018/10/team_logo.svg);
`;

export const HeaderLogo: FunctionComponent = () => {
  return (
    <DivStyled
      data-testid="header-logo"
      id="header-logo"
      className="header-logo"
    >
      <SpanStyled className="logo"></SpanStyled>
    </DivStyled>
  );
};
