import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Panel,
  InputGroup,
  InputSelect,
  SelectOption,
  InputText,
  Button,
  ButtonVariant,
  HorizontalRule,
  Icon,
  Mixins,
  Loading,
  Pagination,
} from "@teamsoftware/reactcomponents";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";

import { JobResultCard } from "./components/JobResultCard";
import { Modal } from "components/Modal";
import { JobResultTag } from "models/JobResultTag";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { SearchRequest, useAPISearchJobs } from "api/search/useAPISearchJobs";
import { DistanceConstants } from "utils/DistanceConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { MyTagManager } from "context/trackingTagsContext";

type TemporaryJobFromAPIType = {
  id: string;
  title: string;
  description: string;
  date: moment.Moment;
  tags?: Array<JobResultTag>;
};

const DivContainerStyled = styled.div`
  ${up("md")} {
    /* Make the Modal snap inside content area when in desktop mode */
    position: relative;
  }
`;

const HeaderStyled = styled.header`
  ${up("md")} {
    ${Mixins.Flex.row({ gap: 0.625 })}
    margin: 2rem 0;
  }
  ${down("sm")} {
    ${Mixins.Flex.column({ gap: 0.625 })}
    margin: 1rem 0;
  }
  ${Mixins.Flex.align({ direction: "center" })}
`;

const DivSearchPanelDesktopStyled = styled.div`
  ${Mixins.Flex.row({ gap: 0.625 })}
`;

const DivSearchPanelMobileStyled = styled.div`
  ${Mixins.Flex.column({ gap: 0.625 })}
`;

const SpanSearchResultTextStyled = styled.span`
  ${Mixins.Flex.flex()}
  font-weight: 600;
  font-size: 16px;
`;

const SpanSearchResultSortStyled = styled.span`
  color: ${(props) => props.theme.Text.Ghosted};
`;

const InputGroupFlex1 = styled(InputGroup)`
  ${Mixins.Flex.flex()}
`;

const InputGroupFlex2 = styled(InputGroup)`
  ${Mixins.Flex.flex({ i: 2 })}
`;

const InputGroupFlex3 = styled(InputGroup)`
  ${Mixins.Flex.flex({ i: 2 })}
`;

const DivButtonStyled = styled.div`
  ${Mixins.Flex.flex()}
`;

const DivSearchButtonDesktopStyled = styled.div`
  ${Mixins.Flex.flex()}
  margin-top: 1.5rem;
`;

const PanelStyled = styled(Panel)`
  margin: 0.5rem;
`;

const PaginationStyled = styled(Pagination)`
  margin: 1rem 0;
`;

type FormData = {
  what: string;
  where: string;
  within: number;
  positionTypes: string[];
  sortBy: string;
  pageLimit: number;
  pageOffset: number;
};

const DEFAULT_SEARCH_WITHIN: number = 25;
const DEFAULT_PAGE_SIZE: number = 20;
const DEFAULT_SORT_BY: string = "job_title ASC";

export const JobListingContainer = () => {
  const { t } = useTranslation();
  const desktopBreakpoint = useBreakpoint(up("md"));
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
  const [hasSearchModalCloseIntent, setHasSearchModalCloseIntent] =
    useState<boolean>(false);

  {
    const { search } = useLocation();
    var urlQueryParams = React.useMemo(
      () => new URLSearchParams(search),
      [search]
    );
  }

  function jobTypesToValues(element: string) {
    switch (element) {
      case "fulltime":
        return "1e9b2fea-da38-4b50-9691-4e9a865d652e" as string;
        break;
      case "parttime":
        return "6ca26e3b-c400-4304-9b51-49de02234e6f" as string;
        break;
      case "temporary":
        return "eb6c64a1-82a7-4e53-880a-9079f9ebfa7d" as string;
        break;
      case "asneeded":
        return "339103f2-78dd-4580-a38a-3a371c48fbd1" as string;
        break;
      case "contractor":
        return "dea0383c-0d6e-456a-b9d2-10fa305dcb6e" as string;
        break;
      case "coop":
        return "1c2435d7-82ee-4588-b01c-737a8d8c53d2" as string;
        break;
      case "civic":
        return "2f29dbe9-dd9d-48de-b56c-ab60260fae17" as string;
        break;
      default:
        return null;
        break;
    }
  }

  const navigate = useNavigate();
  let urlKeywords: string | null = urlQueryParams.get("keywords");
  let urlWhere: string | null = urlQueryParams.get("where");
  let urlWithin: string | null = urlQueryParams.get("within");
  let urlJobType: string[] | null = urlQueryParams.getAll("jobtype");
  if (urlJobType.length > 0) {
    var urlJobTypeValues: any = urlJobType
      .map(jobTypesToValues)
      .filter((item) => typeof item == "string");
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      what: urlKeywords ? urlKeywords : "",
      where: urlWhere ? urlWhere : "",
      within: urlWithin ? parseInt(urlWithin) : DEFAULT_SEARCH_WITHIN,
      positionTypes: urlJobTypeValues ? urlJobTypeValues : [],
      sortBy: DEFAULT_SORT_BY,
      pageOffset: 0,
      pageLimit: DEFAULT_PAGE_SIZE,
    },
  });

  const watchSearchWhat = watch("what", "");
  const watchSearchWithin = watch("within", DEFAULT_SEARCH_WITHIN);
  const watchSearchPositionTypes = watch("positionTypes", []);

  const [isSearchTextFocused, setIsSearchTextFocused] =
    useState<boolean>(false);

  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    searchKeywords: "",
    locationKeywordsOrPostalCode: "",
    distanceLimitInMeters:
      DEFAULT_SEARCH_WITHIN * DistanceConstants.METERS_IN_MILE,
    positionTypes: [],
    sortBy: DEFAULT_SORT_BY,
    pageLimit: DEFAULT_PAGE_SIZE,
    pageOffset: 0,
  });
  const searchQuery = useAPISearchJobs(searchRequest);

  const withinOptions: SelectOption<number>[] = [
    {
      key: "5",
      label: "5",
      value: 5,
      isSelected: watchSearchWithin === 5,
    },
    {
      key: "10",
      label: "10",
      value: 10,
      isSelected: watchSearchWithin === 10,
    },
    {
      key: "25",
      label: "25",
      value: 25,
      isSelected: watchSearchWithin === 25,
    },
    {
      key: "50",
      label: "50",
      value: 50,
      isSelected: watchSearchWithin === 50,
    },
  ];

  const jobTypeOptions: SelectOption<string>[] = [
    {
      key: "1e9b2fea-da38-4b50-9691-4e9a865d652e",
      label: "Full-Time",
      value: "1e9b2fea-da38-4b50-9691-4e9a865d652e",
      isSelected:
        watchSearchPositionTypes?.includes(
          "1e9b2fea-da38-4b50-9691-4e9a865d652e"
        ) ?? false,
    },
    {
      key: "6ca26e3b-c400-4304-9b51-49de02234e6f",
      label: "Part-Time",
      value: "6ca26e3b-c400-4304-9b51-49de02234e6f",
      isSelected:
        watchSearchPositionTypes?.includes(
          "6ca26e3b-c400-4304-9b51-49de02234e6f"
        ) ?? false,
    },
    {
      key: "eb6c64a1-82a7-4e53-880a-9079f9ebfa7d",
      label: "Temporary / Seasonal",
      value: "eb6c64a1-82a7-4e53-880a-9079f9ebfa7d",
      isSelected:
        watchSearchPositionTypes?.includes(
          "eb6c64a1-82a7-4e53-880a-9079f9ebfa7d"
        ) ?? false,
    },
    {
      key: "339103f2-78dd-4580-a38a-3a371c48fbd1",
      label: "As Needed",
      value: "339103f2-78dd-4580-a38a-3a371c48fbd1",
      isSelected:
        watchSearchPositionTypes?.includes(
          "339103f2-78dd-4580-a38a-3a371c48fbd1"
        ) ?? false,
    },
    {
      key: "dea0383c-0d6e-456a-b9d2-10fa305dcb6e",
      label: "Contractor",
      value: "dea0383c-0d6e-456a-b9d2-10fa305dcb6e",
      isSelected:
        watchSearchPositionTypes?.includes(
          "dea0383c-0d6e-456a-b9d2-10fa305dcb6e"
        ) ?? false,
    },
    {
      key: "1c2435d7-82ee-4588-b01c-737a8d8c53d2",
      label: "Coop / Intern",
      value: "1c2435d7-82ee-4588-b01c-737a8d8c53d2",
      isSelected:
        watchSearchPositionTypes?.includes(
          "1c2435d7-82ee-4588-b01c-737a8d8c53d2"
        ) ?? false,
    },
    {
      key: "2f29dbe9-dd9d-48de-b56c-ab60260fae17",
      label: "Civic Leadership",
      value: "2f29dbe9-dd9d-48de-b56c-ab60260fae17",
      isSelected:
        watchSearchPositionTypes?.includes(
          "2f29dbe9-dd9d-48de-b56c-ab60260fae17"
        ) ?? false,
    },
  ];

  const sortByOptions: SelectOption<string>[] = [
    {
      isSelected: false,
      key: "AZ",
      label: t("jobsearch.resultheader.sortoptions.az"),
      value: "job_title ASC",
    },
    {
      isSelected: false,
      key: "ZA",
      label: t("jobsearch.resultheader.sortoptions.za"),
      value: "job_title DESC",
    },
    {
      isSelected: false,
      key: "MostRecent",
      label: t("jobsearch.resultheader.sortoptions.mostrecent"),
      value: "activation_date DESC",
    },
    {
      isSelected: false,
      key: "LeastRecent",
      label: t("jobsearch.resultheader.sortoptions.leastrecent"),
      value: "activation_date ASC",
    },
  ];

  const stripHtml = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const jobs: TemporaryJobFromAPIType[] =
    searchQuery?.data?.results?.map((result) => {
      const tags: JobResultTag[] = [
        {
          title: `${result.postingPlace}, ${result.postingTerritory}`,
          icon: <Icon.Pin />,
        },
        {
          title: result.positionTypeStr,
          icon: <Icon.Time />,
        },
      ];

      if (!!result.minimumPay || !!result.maximumPay) {
        let formattedSalaryRange: string;
        if (!!result.minimumPay && !!result.maximumPay)
          formattedSalaryRange = `${result.minimumPay} - ${result.maximumPay}`;
        else if (!!result.minimumPay)
          formattedSalaryRange = result.minimumPay.toString();
        else formattedSalaryRange = result.maximumPay.toString();

        tags.push({
          title: formattedSalaryRange,
          icon: <Icon.Circle variant={Icon.CircleVariant.DOLLAR} />,
        });
      }

      const r: TemporaryJobFromAPIType = {
        id: result.postingId,
        title: stripHtml(result.jobTitle),
        description: stripHtml(result.jobOverview),
        date: result.activationDate,
        tags: [
          {
            title: `${result.postingPlace}, ${result.postingTerritory}`,
            icon: <Icon.Pin />,
          },
          {
            title: result.positionTypeStr,
            icon: <Icon.Time />,
          },
        ],
      };
      return r;
    }) ?? [];

  const jobResultCount = searchQuery?.data?.totalResults ?? 0;
  const numberOfPages =
    Math.ceil(jobResultCount / searchRequest.pageLimit) ?? 0;

  const modalOnClose = () => {
    setIsSearchTextFocused(false);
    setHasSearchModalCloseIntent(false);
    setIsSearchModalOpen(false);
  };

  const modalOnOpen = () => {
    setIsSearchTextFocused(true);
  };

  const modalOnCloseIntent = () => {
    setHasSearchModalCloseIntent(true);
  };

  const fuaxSearchOnClick: MouseEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSearchModalOpen(true);
  };

  const search = () => {
    setHasSearchModalCloseIntent(true);
  };
  var urlQueryParamsList: string[] = [];
  var urlQuery: string = "";
  const onSubmitSearch: SubmitHandler<FormData> = useCallback(
    (data) => {
      setSearchRequest({
        searchKeywords: data.what,
        locationKeywordsOrPostalCode: data.where,
        distanceLimitInMeters: data.within * DistanceConstants.METERS_IN_MILE,
        positionTypes: data.positionTypes,
        sortBy: data.sortBy,
        pageLimit: data.pageLimit,
        pageOffset: data.pageOffset,
      });
      urlQueryParamsList = [];
      urlQuery = "";
      if (data.what.length > 0) {
        urlQueryParamsList.push("keywords=" + data.what);
      }
      if (data.where.length > 0) {
        urlQueryParamsList.push("where=" + data.where);
      }
      if (data.within !== DEFAULT_SEARCH_WITHIN) {
        urlQueryParamsList.push("within=" + data.within);
      }
      if (data.positionTypes.length > 0) {
        data.positionTypes.map((element) => {
          switch (element) {
            case "1e9b2fea-da38-4b50-9691-4e9a865d652e":
              urlQueryParamsList.push("jobtype=fulltime");
              break;
            case "6ca26e3b-c400-4304-9b51-49de02234e6f":
              urlQueryParamsList.push("jobtype=parttime");
              break;
            case "eb6c64a1-82a7-4e53-880a-9079f9ebfa7d":
              urlQueryParamsList.push("jobtype=temporary");
              break;
            case "339103f2-78dd-4580-a38a-3a371c48fbd1":
              urlQueryParamsList.push("jobtype=asneeded");
              break;
            case "dea0383c-0d6e-456a-b9d2-10fa305dcb6e":
              urlQueryParamsList.push("jobtype=contractor");
              break;
            case "1c2435d7-82ee-4588-b01c-737a8d8c53d2":
              urlQueryParamsList.push("jobtype=coop");
              break;
            case "2f29dbe9-dd9d-48de-b56c-ab60260fae17":
              urlQueryParamsList.push("jobtype=civic");
              break;
          }
        });
      }
      if (urlQueryParamsList.length > 0) {
        urlQuery = "?";
        urlQueryParamsList.forEach((element) => {
          if (urlQuery.length > 1) {
            urlQuery = [urlQuery, element].join("&");
          } else {
            urlQuery = [urlQuery, element].join("");
          }
        });
      }
      navigate(urlQuery);
      MyTagManager.dataLayer({
        dataLayer: {
          event: "Search",
        },
      });
    },
    [setSearchRequest]
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      let pageOffset: number = pageNumber - 1;

      setValue("pageOffset", pageOffset);

      handleSubmit(onSubmitSearch)();
    },
    [setValue, handleSubmit, onSubmitSearch]
  );

  const onSearchWithinChange = (values: SelectOption<number>[]) => {
    let withinValue: number = DEFAULT_SEARCH_WITHIN;

    if (values.length > 0) {
      withinValue = values[0].value ?? DEFAULT_SEARCH_WITHIN;
    }

    setValue("within", withinValue);
  };

  const onSearchJobTypeChange = (values: SelectOption<string>[]) => {
    const stringValueArray: string[] = values
      .map((v) => v.value)
      .filter((v): v is string => !!v);
    setValue("positionTypes", stringValueArray);
  };

  const searchButtonDisabled = searchQuery?.isFetching ?? true;
  const showLoading = searchQuery?.isFetching ?? true;

  useEffect(() => {
    register("pageOffset");
    register("positionTypes");
    register("within");
  }, [register]);

  const onSortTypeChange = useCallback(
    (values: SelectOption<string>[]) => {
      let sortValue: string = DEFAULT_SORT_BY;

      if (values.length > 0) {
        sortValue = values[0].value ?? DEFAULT_SORT_BY;
      }
      setValue("sortBy", sortValue);
      setSortType(sortValue);
    },
    [setValue, handleSubmit, onSubmitSearch]
  );

  const [sortType, setSortType] = useState<string>(DEFAULT_SORT_BY);

  const printSortType = useEffect(() => {
    handleSubmit(onSubmitSearch)();
  }, [sortType]);

  return (
    <DivContainerStyled>
      <form onSubmit={handleSubmit(onSubmitSearch)}>
        {!!isSearchModalOpen && (
          <Modal
            onOpen={modalOnOpen}
            onClose={modalOnClose}
            onCloseIntent={modalOnCloseIntent}
            hasCloseIntent={hasSearchModalCloseIntent}
          >
            <DivSearchPanelMobileStyled>
              <InputGroupFlex1
                label={t("jobsearch.searchbar.what.label")}
                error={errors.what?.message}
                input={
                  <InputText
                    id="search-what"
                    placeholder={t("jobsearch.searchbar.what.placeholder")}
                    autoFocus={isSearchTextFocused}
                    fullWidth
                    {...register("what")}
                  />
                }
              />
              <InputGroupFlex1
                label={t("jobsearch.searchbar.where.label")}
                error={errors.where?.message}
                input={
                  <InputText
                    id="search-where"
                    placeholder={t("jobsearch.searchbar.where.placeholder")}
                    fullWidth
                    {...register("where")}
                  />
                }
              />
              <InputGroupFlex1
                label={t("jobsearch.searchbar.within.label")}
                error={errors.within?.message}
                input={
                  <InputSelect
                    id="search-within"
                    aria-labelledby="search-within-label"
                    placeholder={t("jobsearch.searchbar.within.placeholder")}
                    options={withinOptions}
                    onOptionChange={onSearchWithinChange}
                  />
                }
              />
              <InputGroupFlex1
                label={t("jobsearch.searchbar.jobtype.label")}
                error={errors.positionTypes?.[0]?.message}
                input={
                  <InputSelect
                    id="search-job-type"
                    aria-labelledby="search-job-type-label"
                    placeholder={t("jobsearch.searchbar.jobtype.placeholder")}
                    isMultiSelect={true}
                    options={jobTypeOptions}
                    onOptionChange={onSearchJobTypeChange}
                  />
                }
              />
              <DivButtonStyled>
                <Button
                  id="search-submit"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  className="primary"
                  aria-label={t("jobsearch.searchbar.button.label")}
                  onClick={search}
                  disabled={searchButtonDisabled}
                  fullWidth
                >
                  {t("jobsearch.searchbar.button.label")}
                </Button>
              </DivButtonStyled>
            </DivSearchPanelMobileStyled>
          </Modal>
        )}
        {!desktopBreakpoint && (
          <div>
            <InputText
              id="fuax-search-what"
              placeholder={t("jobsearch.searchbar.what.placeholder")}
              value={watchSearchWhat}
              onClick={fuaxSearchOnClick}
              icon={<Icon.Search />}
              readOnly={true}
              fullWidth
            />
          </div>
        )}
        {desktopBreakpoint && (
          <PanelStyled>
            <DivSearchPanelDesktopStyled>
              <InputGroupFlex3
                label={t("jobsearch.searchbar.what.label")}
                error={errors.what?.message}
                input={
                  <InputText
                    id="search-what"
                    placeholder={t("jobsearch.searchbar.what.placeholder")}
                    fullWidth
                    {...register("what")}
                  />
                }
              />
              <InputGroupFlex2
                label={t("jobsearch.searchbar.where.label")}
                error={errors.where?.message}
                input={
                  <InputText
                    id="search-where"
                    placeholder={t("jobsearch.searchbar.where.placeholder")}
                    fullWidth
                    {...register("where")}
                  />
                }
              />
              <InputGroupFlex1
                label={t("jobsearch.searchbar.within.label")}
                error={errors.within?.message}
                input={
                  <InputSelect
                    id="search-within"
                    aria-labelledby="search-within-label"
                    placeholder={t("jobsearch.searchbar.within.placeholder")}
                    options={withinOptions}
                    onOptionChange={onSearchWithinChange}
                  />
                }
              />
              <InputGroupFlex2
                label={t("jobsearch.searchbar.jobtype.label")}
                error={errors.positionTypes?.[0]?.message}
                input={
                  <InputSelect
                    id="search-job-type"
                    aria-labelledby="search-job-type-label"
                    placeholder={t("jobsearch.searchbar.jobtype.placeholder")}
                    isMultiSelect={true}
                    options={jobTypeOptions}
                    onOptionChange={onSearchJobTypeChange}
                  />
                }
              />
              <DivSearchButtonDesktopStyled>
                <Button
                  id="search-submit"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  className="primary"
                  onClick={search}
                  disabled={searchButtonDisabled}
                  fullWidth
                >
                  {t("jobsearch.searchbar.button.label")}
                </Button>
              </DivSearchButtonDesktopStyled>
            </DivSearchPanelDesktopStyled>
          </PanelStyled>
        )}
      </form>
      <section>
        <HeaderStyled>
          <SpanSearchResultTextStyled>
            {t("jobsearch.resultheader.foundresults", {
              total: jobResultCount,
            })}
          </SpanSearchResultTextStyled>
          {desktopBreakpoint && (
            <>
              <SpanSearchResultSortStyled id="job-search-resultheader-sortby">
                {t("jobsearch.resultheader.sortby")}
              </SpanSearchResultSortStyled>
              <InputSelect
                id="result-sort"
                aria-labelledby="job-search-resultheader-sortby"
                options={sortByOptions}
                onOptionChange={onSortTypeChange}
                placeholder={t("jobsearch.resultheader.sortoptions.az")}
              />
            </>
          )}
        </HeaderStyled>
        <Loading isLoading={showLoading} />
        {!showLoading &&
          jobs.map((job) => (
            <React.Fragment key={job.id}>
              <HorizontalRule />
              <JobResultCard
                id={job.id}
                title={job.title}
                description={job.description}
                date={job.date}
                tags={job.tags}
              />
            </React.Fragment>
          ))}
        {!showLoading && (
          <PaginationStyled
            numberOfPages={numberOfPages}
            startOnPage={searchRequest.pageOffset + 1}
            onPageChange={onPageChange}
          />
        )}
      </section>
    </DivContainerStyled>
  );
};
