import {
  Link,
  Mixins,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { BackLink } from "components/BackLink";
import { Header } from "components/Header";
import { bodyTags } from "context/trackingTagsContext";

export type PasswordlessLoginProps = {
  id: string;
};

const ContainerStyled = styled.div`
  ${Mixins.Flex.column()}
  ${Mixins.Flex.align({ direction: "center" })}
`;

const IconDiv = styled.div`
  font-size: 8rem;
  svg {
    display: block;
  }
  padding-top: 4rem;
`;

const HeaderStyled = styled(Typography)`
  margin-top: 1.5rem;
`;

const PStyled = styled.p`
  padding-left: 1rem;
  margin-top: 1rem;
  max-width: 37.5rem;
  line-height: 1.5rem;
  b {
    font-weight: 600;
  }
`;

const LinkStyled = styled(Link)`
  margin-top: 2rem;
`;

export const PasswordlessLoginPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const jobTitle = location.state as string;
  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }
  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header
        leftComponent={
          <BackLink
            id="backToSearchLink"
            data-testid="nav-back"
            text={t("job.nav.back")}
            to={`/`}
          />
        }
      />
      <ContainerStyled>
        <>
          <IconDiv>
            <img
              src={process.env.PUBLIC_URL + "/emailIcon.png"}
              width="120rem"
              height="120rem"
            />
          </IconDiv>
          <HeaderStyled
            variant={TypographyVariant.H1}
            weight={FontWeight.SemiBold}
            color={Color.Default}
          >
            {t("passwordlessLogin.title")}
          </HeaderStyled>
          <PStyled>
            {t("passwordlessLogin.messagePartA")}&nbsp;<b> {jobTitle}</b>
            {t("passwordlessLogin.messagePartB")}
          </PStyled>
          <LinkStyled to="/">{t("confirmation.backBtn")}</LinkStyled>
        </>
      </ContainerStyled>
    </>
  );
};
