import { TenantContextProvider } from "context/tenantContext";
import { TrackingTagContextProvider } from "context/trackingTagsContext";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, DefaultTheme } from "@teamsoftware/reactcomponents";
import { useTranslation, Trans, I18nextProvider } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import TenantContainer from "components/TenantContainer/TenantContainer";
import { JobSearchPage } from "pages/JobSearchPage/JobSearchPage";
import { JobPage } from "pages/JobPage/JobPage";
import { ApplicationPage } from "pages/ApplicationPage/ApplicationPage";
import { ApplicationConfirmation } from "pages/ApplicationConfirmation/ApplicationConfirmation";
import { HelpPage } from "pages/HelpPage/HelpPage";
import { PasswordlessLoginPage } from "pages/PasswordlessLogin/PasswordlessLoginPage";
import { PasswordlessLoginExpiredPage } from "pages/PasswordlessLoginExpired/PasswordlessLoginExpiredPage";
import { AuthenticationPage } from "pages/AuthenticationPage/AuthenticationPage";
import { ReactSession } from "react-client-session";
const DivStyled = styled.div`
  margin: 0 auto;
  max-width: 59rem;
  position: relative;
`;

const queryClient = new QueryClient();

const App = () => {
  ReactSession.setStoreType("sessionStorage");

  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <TenantContextProvider>
              <TrackingTagContextProvider>
                <Router>
                  <DivStyled className="App">
                    <TenantContainer>
                      <Routes>
                        <Route path="/" element={<JobSearchPage />} />
                        <Route path="/job/:id" element={<JobPage />} />
                        <Route
                          path="/apply/job/:id"
                          element={<ApplicationPage />}
                        />
                        <Route
                          path="/confirmation/:id"
                          element={<ApplicationConfirmation />}
                        />
                        <Route path="/help" element={<HelpPage />} />
                        <Route
                          path="/passwordlessLogin"
                          element={<PasswordlessLoginPage />}
                        />
                        <Route
                          path="/passwordlessLoginExpired"
                          element={<PasswordlessLoginExpiredPage />}
                        />
                        <Route
                          path="/Authentication/*"
                          element={<AuthenticationPage />}
                        />
                      </Routes>
                    </TenantContainer>
                  </DivStyled>
                </Router>
              </TrackingTagContextProvider>
            </TenantContextProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

export default App;
