import { useContext, createContext, FunctionComponent } from "react";
import TagManager from "react-gtm-module";
import { useAPITrackingTags } from "api/tracking-tags/useAPITrackingTags";
import { Helmet } from "react-helmet-async";
import i18n from "locale/i18n";
import { TrackingTag } from "models/TrackingTag";

export const MyTagManager = TagManager;

const TrackingTagContext = createContext<TrackingTag | null>(null);

export var scripts: string[] = [];
export var noScripts: string[] = [];
export var bodyTags: string[] = [];

export const TrackingTagContextProvider: FunctionComponent = (props) => {
  const { isLoading, data } = useAPITrackingTags({
    domain: window.location.hostname,
  });

  let trackingTag: TrackingTag = {
    id: "",
    clientId: "",
    tagId: "",
    tagProvider: "",
    tagHtml: "",
    tagType: "",
    isLoading: isLoading,
  };

  if (!isLoading && !!data) {
    var tagManagerArgs = {
      gtmId: "",
    };
    data?.map((tag) => {
      if (tag.tagProvider === "gtm") {
        tagManagerArgs.gtmId = tag.tagId;
        trackingTag.id = tag.id;
        trackingTag.clientId = tag.clientId;
        trackingTag.tagId = tag.tagId;
        trackingTag.tagProvider = tag.tagProvider;
      }
      if (
        tag.tagProvider !== "gtm" &&
        tag.tagType === "script" &&
        !scripts.includes(tag.tagHtml)
      ) {
        scripts.push(tag.tagHtml);
      }
      if (
        tag.tagProvider !== "gtm" &&
        tag.tagType === "noscript" &&
        !noScripts.includes(tag.tagHtml)
      ) {
        noScripts.push(tag.tagHtml);
      }
      if (
        tag.tagProvider !== "gtm" &&
        tag.tagType === "bodyTag" &&
        !bodyTags.includes(tag.tagHtml)
      ) {
        bodyTags.push(tag.tagHtml);
      }
    });
    if (tagManagerArgs.gtmId.length !== 0) {
      MyTagManager.initialize(tagManagerArgs);
    }
  }

  return (
    <>
      <TrackingTagContext.Provider value={trackingTag}>
        {scripts.map(function (object, i) {
          return (
            <Helmet
              htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}
              key={i}
            >
              <script type="text/javascript">{object}</script>;
            </Helmet>
          );
        })}
        {noScripts.map(function (object, i) {
          return (
            <Helmet
              htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}
              key={i}
            >
              <noscript>{object}</noscript>;
            </Helmet>
          );
        })}
        {props.children}
      </TrackingTagContext.Provider>
    </>
  );
};

export const useTrackingTag = (): TrackingTag => {
  const trackingTag = useContext<TrackingTag | null>(TrackingTagContext);
  if (trackingTag === null)
    throw new Error(
      "Make sure this component has a <TrackingTagContextProvider /> somewhere above itself in the component tree."
    );

  if (trackingTag === undefined) {
    throw new Error("UNDEFINED ALERT FOR TRACKING TAG");
  }

  return trackingTag;
};
