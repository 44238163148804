import { useMutation } from "react-query";
import { AxiosError } from "axios";

import axios from "utils/axios-api-base";
import { ApplicationInfo } from "models/ApplicationInfo";

export const useAPISetAsCompleted = <TData, TContext>(
  onSuccessCallback?: (
    data: TData,
    applicationInfo: ApplicationInfo,
    context: TContext | undefined
  ) => Promise<void> | void,

  onErrorCallback?: (
    error: AxiosError,
    applicationInfo: ApplicationInfo,
    context: TContext | undefined
  ) => Promise<void> | void
) => {
  return useMutation<TData, AxiosError, ApplicationInfo, TContext>(
    (appInfo) => {
      return axios.post("Application/SetAsCompleted", appInfo, {});
    },
    {
      onError: async (error, appInfo, context) => {
        if (onErrorCallback) {
          onErrorCallback(error, appInfo, context);
        }
      },

      onSuccess: async (data, appInfo, context) => {
        if (onSuccessCallback) {
          onSuccessCallback(data, appInfo, context);
        }
      },
    }
  );
};
