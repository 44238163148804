import React, { useEffect, useState, useCallback } from "react";
import { useAPIQuestionsBySectionId } from "api/job-posting/useAPIQuestionsBySectionId";
import { useAPIGetEEOData } from "api/client-features/useAPIGetEEOData";
import { Question } from "../Question/Question";
import {
  Question as QuestionModel,
  GenerateFieldName,
  QuestionType,
  QuestionTypeIdToEnum,
} from "models/Question";
import { ApplyQuestionData } from "../../ApplicationQuestions";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  HorizontalRule,
  Loading,
  CheckboxGroupOptionState,
} from "@teamsoftware/reactcomponents";

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormSetError,
  UseFormClearErrors,
  UseFormWatch,
  FieldValues,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { RemoveHtmlTags } from "utils/RemoveHtmlTags";


const SectionHeaderTypography = styled(Typography)`
  margin-top: 2rem;
`;

const EEOStatement = styled(Typography)`
  white-space: pre-wrap;
`;

export type QuestionSectionProps = {
  postingId: string;
  sectionId: string;
  sectionName: string;
  validationErrors: any;
  defaultValues?: ApplyQuestionData[];
  companyName: string | undefined;
  validationRegister: UseFormRegister<FieldValues>;
  validationSetValue: UseFormSetValue<FieldValues>;
  validationSetErrors: UseFormSetError<FieldValues>;
  validationClearErrors: UseFormClearErrors<FieldValues>;
  formWatch: UseFormWatch<FieldValues>;
  onSectionQuestionModelUpdate: (
    updatedSectionQuestions: QuestionModel[] | null,
    sectionId: string,
  ) => void;
};

export const QuestionSection = ({
  postingId,
  sectionId,
  sectionName,
  validationErrors,
  defaultValues,
  companyName,
  validationRegister,
  validationSetValue,
  validationSetErrors,
  validationClearErrors,
  formWatch,
  onSectionQuestionModelUpdate,
}: QuestionSectionProps) => {
  const { isLoading, data: questions } = useAPIQuestionsBySectionId(
    postingId,
    sectionId,
  );

  const { isLoading: eeoLoading, data: eeoData } = useAPIGetEEOData();

  const { t } = useTranslation();

  const [currentFormState, setCurrentFormState] = useState<any>(null);

  const followUpQuestions = questions?.filter(
    (q) => !!q.followupQuestionForAnswerOptionID,
  );

  // sort the followupQuestions by answerOptionId
  followUpQuestions?.sort((a, b) =>
    a.followupQuestionForAnswerOptionID > b.followupQuestionForAnswerOptionID
      ? 1
      : b.followupQuestionForAnswerOptionID >
        a.followupQuestionForAnswerOptionID
      ? -1
      : 0,
  );

  const getFollowUpQuestions = (
    question: QuestionModel,
    displayNumberIndex: number,
  ): QuestionModel[] | null | undefined => {
    let matchedFollowUpQuestions: QuestionModel[] = [];

    for (let i: number = 0; i < question.answerOptions.length; i++) {
      let partialFollowUpList = followUpQuestions?.filter(
        (q) =>
          q.followupQuestionForAnswerOptionID === question.answerOptions[i].id,
      );

      if (!!partialFollowUpList && partialFollowUpList.length > 0) {
        matchedFollowUpQuestions.push(...partialFollowUpList);
      }
    }

    if (
      matchedFollowUpQuestions !== undefined &&
      matchedFollowUpQuestions.length > 0
    ) {
      return assignDisplayNumbersToFollowupQuestions(
        matchedFollowUpQuestions,
        displayNumberIndex,
      );
    }

    return null;
  };

  const isAFollowUpQuestionForIndex = useCallback(
    (question: QuestionModel): number => {
      if (!!!question.followupQuestionForAnswerOptionID) {
        return -1;
      }

      if (!!questions) {
        for (let i: number = 0; i < questions?.length; i++) {
          if (
            questions[i].answerOptions.filter(
              (a) => a.id === question.followupQuestionForAnswerOptionID,
            ).length > 0
          ) {
            return i;
          }
        }
      }

      return -1;
    },
    [questions],
  );

  useEffect(() => {
    if (!isLoading && !!questions) {
      // Initialize by only adding parent questions
      let newQuestionArray: QuestionModel[] = [];
      let parentQuestions: QuestionModel[] = questions.filter((question) => {
        let followUpQuestionForIndex = isAFollowUpQuestionForIndex(question);

        if (followUpQuestionForIndex === -1) {
          // Not a followup question so add it
          return true;
        }
      });

      parentQuestions.forEach((question, index) => {
        question.displayuNumber = (index + 1).toString();
      });

      newQuestionArray = parentQuestions;

      // Then gather all possible follow up questions for this section
      parentQuestions.forEach((parentQuestion, index) => {
        let foundFollowUpQuestions = getFollowUpQuestions(
          parentQuestion,
          index,
        );

        if (!!foundFollowUpQuestions && foundFollowUpQuestions.length > 0) {
          newQuestionArray.push(...foundFollowUpQuestions);
        }
      });

      onSectionQuestionModelUpdate(newQuestionArray, sectionId);
    }
  }, [questions, isLoading, sectionId]);

  useEffect(() => {
    const subscription = formWatch((data) => {
      setCurrentFormState(data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [formWatch]);

  const showFollowUpQuestions = (question: QuestionModel): boolean => {
    if (followUpQuestions === undefined || followUpQuestions.length === 0) {
      // There are no follow up questions
      return false;
    }

    if (!!question.followupQuestionForAnswerOptionID) {
      // This question is a follow up question itself. Cannot have nested follow up questions
      return false;
    }

    if (
      !!questions &&
      questions.filter((q) => q.id === question.id).length === 0
    ) {
      // The question passed in is not in the array retrieved by the api
      return false;
    }

    if (!!!currentFormState && !!!defaultValues) {
      return false;
    }

    let tryDefaultValue: boolean = false;

    // Has form state
    if (!!currentFormState) {
      let currentlySelectedAnswer =
        currentFormState[GenerateFieldName(sectionId, question.id)];

      if (undefined === currentlySelectedAnswer) {
        // react-hook-form doesn't register that it has a value for some components with a default value.
        tryDefaultValue = true;
      }

      let isCheckboxGroup: boolean = false;

      if (Array.isArray(currentlySelectedAnswer)) {
        isCheckboxGroup = true;
      }

      // Handle single answer Id
      if (
        !!currentlySelectedAnswer &&
        !isCheckboxGroup &&
        followUpQuestions.filter(
          (q) =>
            q.followupQuestionForAnswerOptionID === currentlySelectedAnswer,
        ).length > 0
      ) {
        return true;
      }

      // Handle array of checkbox group options
      if (!!currentlySelectedAnswer && isCheckboxGroup) {
        let matchFound: boolean = false;

        currentlySelectedAnswer.forEach(
          (answerOption: CheckboxGroupOptionState) => {
            if (
              followUpQuestions.filter(
                (q) =>
                  answerOption.checked === true &&
                  q.followupQuestionForAnswerOptionID === answerOption.value,
              ).length > 0
            ) {
              matchFound = true;
              return;
            }
          },
        );

        return matchFound;
      }
    }

    // Otherwise if we have default values...
    if (
      (!!!currentFormState && !!defaultValues) ||
      (!!currentFormState && tryDefaultValue)
    ) {
      return doesQuestionHaveFollowUpWithDefaultAnswerData(question);
    }

    return false;
  };

  const getCurrentlySelectedAnswerOptions = (
    question: QuestionModel,
  ): any[] => {
    let returnArray: any[] = [];
    let tryDefaultValue: boolean = false;

    // Has form state
    if (!!currentFormState) {
      let currentlySelectedAnswer =
        currentFormState[GenerateFieldName(sectionId, question.id)];

      if (undefined === currentlySelectedAnswer) {
        // react-hook-form doesn't register that it has a value for some components with a default value.
        tryDefaultValue = true;
      }

      let isCheckboxGroup: boolean = false;

      if (Array.isArray(currentlySelectedAnswer)) {
        isCheckboxGroup = true;
      }

      // Handle single answer Id
      if (!!currentlySelectedAnswer && !isCheckboxGroup) {
        returnArray.push(currentlySelectedAnswer);
      } else if (!!currentlySelectedAnswer && isCheckboxGroup) {
        // Handle array of checkbox group options
        currentlySelectedAnswer.forEach((answer: any) => {
          returnArray.push(answer.value);
        });
      }
    }

    // Otherwise if we have default values...
    if (
      (!!!currentFormState && !!defaultValues) ||
      (!!currentFormState && tryDefaultValue)
    ) {
      let matchingDefaultAnswer = getQuestionDefaultAnswer(question);

      if (!!matchingDefaultAnswer && Array.isArray(matchingDefaultAnswer)) {
        returnArray.push(...matchingDefaultAnswer);
      }
    }

    return returnArray;
  };

  const hasFollowUpQuestion = (question: QuestionModel): boolean => {
    for (let i: number = 0; i < question.answerOptions.length; i++) {
      let matches = followUpQuestions?.filter(
        (q) =>
          q.followupQuestionForAnswerOptionID === question.answerOptions[i].id,
      );

      if (matches !== undefined && matches.length > 0) {
        return true;
      }
    }

    return false;
  };

  const doesQuestionHaveFollowUpWithDefaultAnswerData = (
    question: QuestionModel,
  ): boolean => {
    let followUpQuestionIds = getFollowUpQuestions(question, 0)?.map((q) => {
      return q.id;
    });

    if (
      !!!defaultValues ||
      !!!followUpQuestionIds ||
      followUpQuestionIds.length === 0
    ) {
      return false;
    }

    if (!!followUpQuestionIds) {
      let defaultAnswers: ApplyQuestionData[] = defaultValues?.filter(
        (dv) => followUpQuestionIds?.includes(dv.questionId),
      );

      if (
        defaultAnswers === undefined ||
        defaultAnswers.length === 0 ||
        defaultAnswers.length < 1
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  const getQuestionDefaultAnswer = (
    question: QuestionModel,
  ): string[] | undefined => {
    if (!!!defaultValues) {
      return undefined;
    }

    let defaultAnswer: ApplyQuestionData[] = defaultValues?.filter(
      (dv) => dv.questionId === question.id,
    );

    if (
      defaultAnswer === undefined ||
      defaultAnswer.length === 0 ||
      defaultAnswer.length < 1
    ) {
      return undefined;
    }

    let questionTypeEnum: QuestionType = QuestionTypeIdToEnum(question.type);

    if (
      questionTypeEnum === QuestionType.MultiLine ||
      questionTypeEnum === QuestionType.SingleLine
    ) {
      return defaultAnswer[0]?.answers.map((a) => {
        return a.answerText;
      });
    } else {
      return defaultAnswer[0]?.answers.map((a) => {
        if (!!a.answerId) {
          return a.answerId;
        } else {
          return ""; // Should never happen because answerId should only be null for the multiline and single line question types
        }
      });
    }
  };

  const onRemoveDefaultAnswer = useCallback(
    (questionId: string) => {
      if (!!!defaultValues) {
        return;
      }

      let defaultValueIndex: number = -1;

      for (let i = 0; i < defaultValues.length; i++) {
        if (defaultValues[i].questionId === questionId) {
          defaultValueIndex = i;
          break;
        }
      }

      if (defaultValueIndex !== -1) {
        defaultValues.splice(defaultValueIndex, 1);
      }
    },
    [defaultValues],
  );

  const assignDisplayNumbersToFollowupQuestions = useCallback(
    (
      followUpQuestions: QuestionModel[],
      displayNumberIndex: number,
    ): QuestionModel[] | undefined => {
      if (followUpQuestions !== undefined && followUpQuestions.length > 0) {
        let previousAnswerOptionId: string | undefined = undefined;
        let previousDisplayLetter: string = "a";
        let returnArray: QuestionModel[] = followUpQuestions;

        returnArray.forEach((question) => {
          if (
            previousAnswerOptionId ===
            question.followupQuestionForAnswerOptionID
          ) {
            let newDisplayLetter = String.fromCharCode(
              previousDisplayLetter?.charCodeAt(0) + 1,
            );
            question.displayuNumber = `${displayNumberIndex}${newDisplayLetter}`;

            previousDisplayLetter = newDisplayLetter;
            previousAnswerOptionId = question.followupQuestionForAnswerOptionID;
          } else {
            question.displayuNumber = `${displayNumberIndex}a`;

            previousAnswerOptionId = question.followupQuestionForAnswerOptionID;
            previousDisplayLetter = "a";
          }
        });

        return followUpQuestions;
      }

      return undefined;
    },
    [],
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      {!isLoading && !eeoLoading ? (
        <>
          {sectionName !== "EEO" || eeoData!.eeoIsTurnedOn === true ? (
            <>
              <SectionHeaderTypography
                variant={TypographyVariant.H4}
                weight={FontWeight.SemiBold}
                color={Color.Subtle}
                data-testid={`question-section-${sectionId}`}
              >
                {sectionName !== "EEO" ||
                eeoData!.equalityMonitoringIsTurnedOn === false
                  ? sectionName
                  : t("apply.questions.equalitymonitoring")}
              </SectionHeaderTypography>
  
              <HorizontalRule />
  
              {sectionName === "EEO" ? (
                <>
                  <SectionHeaderTypography
                    variant={TypographyVariant.Subhead}
                    weight={FontWeight.SemiBold}
                    color={Color.Subtle}
                    data-testid={`eeo-title`}
                  >
                    {RemoveHtmlTags(eeoData?.eeoTitle)}
                  </SectionHeaderTypography>
                  <EEOStatement
                    variant={TypographyVariant.Subhead}
                    weight={FontWeight.Regular}
                    color={Color.Default}
                  >
                    {RemoveHtmlTags(eeoData?.eeoDescription)}
                  </EEOStatement>
                </>
              ) : (
                false
              )}
             

              <ol type="1">
                {questions?.map((question, index) => {
                  const defaultValuesOfQuestion = defaultValues?.find(
                    (a) => a.questionId === question.id,
                  );
  
                  let defaultAnswers: string[] | undefined =
                    defaultValuesOfQuestion?.answers.map((ad) => {
                      if (!!ad.answerId) {
                        return ad.answerId;
                      }
  
                      return ad.answerText;
                    });
  
                  const showFollowUpQ =
                    hasFollowUpQuestion(question) &&
                    showFollowUpQuestions(question);
  
                  const followups = getFollowUpQuestions(question, index + 1);
  
                  return (
                    <div key={question.id}>
                      {question.followupQuestionForAnswerOptionID === null && (
                        <Question
                          questionData={{
                            ...question,
                            displayuNumber: (index + 1).toString(),
                          }}
                          defaultAnswer={defaultAnswers}
                          sectionId={sectionId}
                          validationErrors={validationErrors}
                          validationRegister={validationRegister}
                          validationSetValue={validationSetValue}
                          validationSetErrors={validationSetErrors}
                          validationClearErrors={validationClearErrors}
                        />
                      )}
  
                      {showFollowUpQ &&
                        !!followups &&
                        followups
                          .filter((followupq) => {
                            return getCurrentlySelectedAnswerOptions(
                              question,
                            ).includes(
                              followupq.followupQuestionForAnswerOptionID,
                            );
                          })
                          .map((followUpQuestion) => (
                            <Question
                              key={followUpQuestion.id}
                              questionData={followUpQuestion}
                              defaultAnswer={getQuestionDefaultAnswer(
                                followUpQuestion,
                              )}
                              sectionId={sectionId}
                              isFollowUp={true}
                              removeDefaultAnswer={onRemoveDefaultAnswer}
                              validationErrors={validationErrors}
                              validationRegister={validationRegister}
                              validationSetValue={validationSetValue}
                              validationSetErrors={validationSetErrors}
                              validationClearErrors={validationClearErrors}
                            />
                          ))}
                    </div>
                  );
                })}
              </ol>
            </>
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </>
  );
}