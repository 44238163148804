import { useQuery } from "react-query";
import axios from "utils/axios-api-base";

const queryKey: string = "RightToBeForgotten";

export const useAPIGetRightToBeForgotten = () => {
  return useQuery(
    [queryKey],
    async (): Promise<boolean | null> => {
      const { status, data } = await axios.get(
        "Tenant/getTenantHasRightToBeForgotten", { headers: {TenantKey: window.location.hostname, } }
      );

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
