import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  HorizontalRule,
  StepperFooter,
  useArray,
  useStepper,
  Modal,
} from "@teamsoftware/reactcomponents";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { ApplyStepHeader } from "components/ApplyStepHeader";
import {
  ApplyEmploymentHistory,
  ApplyEmploymentHistoryFormData,
} from "./components/ApplyEmploymentHistory";
import {
  ApplyEducationHistory,
  ApplyEducationHistoryFormData,
} from "./components/ApplyEducationHistory";
import {
  ApplyCertificationHistory,
  ApplyCertificationHistoryFormData,
} from "./components/ApplyCertificationHistory";
import { CardProps } from "./components/Card";
import { Section } from "./components/Section";
import { JobDetails } from "components/JobDetail/JobDetails";

export type ApplyExperienceAndEducationProps = {
  defaultValue?: ApplyExperienceAndEducationData;
  jobData: JobDetails | null | undefined;
  onSubmit: (data: ApplyExperienceAndEducationData) => void;
};

export type ApplyExperienceAndEducationData = {
  employmentHistory: ApplyEmploymentHistoryFormData[];
  educationHistory: ApplyEducationHistoryFormData[];
  certificationHistory: ApplyCertificationHistoryFormData[];
};

const StyledDiv = styled.div`
  margin-bottom: 5rem;
  padding: 0 1rem;
`;

const StepperFooterStyled = styled.div`
  margin-top: 2.75rem;
`;

const HorizontalRuleFooterStyled = styled(HorizontalRule)`
  margin: 1rem 0;
  margin-top: 5rem;
`;

export const ApplyExperienceAndEducation = ({
  defaultValue,
  jobData,
  onSubmit,
}: ApplyExperienceAndEducationProps) => {
  const { t } = useTranslation();
  const { dispatch } = useStepper();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalBody, setModalBody] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentArray, setCurrentArray] = useState<string>("");
  const [minJobsMet, setMinJobsMet] = useState<boolean>(true);

  //#############
  // Employment History
  //#############
  const [showEmploymentHistory, setShowEmploymentHistory] =
    useState<boolean>(false);
  const [employmentHistory, employmentHistoryActions] =
    useArray<ApplyEmploymentHistoryFormData>(
      defaultValue?.employmentHistory ?? [],
    );
  const [currentEmploymentHistory, setCurrentEmploymentHistory] =
    useState<ApplyEmploymentHistoryFormData>();

  const onClickAddEmploymentHistory = () => {
    setShowEmploymentHistory(true);
  };

  const onSubmitEmploymentHistory = (data: ApplyEmploymentHistoryFormData) => {
    if (!!data.id) {
      const index = employmentHistory.findIndex((v) => v.id === data.id);
      employmentHistoryActions.update(index, data);
    } else {
      employmentHistoryActions.push({ id: uuid(), ...data });
    }
    setCurrentEmploymentHistory(undefined);
    setShowEmploymentHistory(false);
  };

  const onRequestCloseEmploymentHistory = () => {
    setCurrentEmploymentHistory(undefined);
    setShowEmploymentHistory(false);
  };

  const onRequestEditEmploymentHistory = (index: number) => {
    const item = employmentHistory[index];
    setCurrentEmploymentHistory(item);
    setShowEmploymentHistory(true);
  };

  const onRequestDeleteEmploymentHistory = (index: number) => {
    setCurrentIndex(index);
    setCurrentArray("employmentHistory");
    setModalTitle(t("apply.deleteExperienceModal.job.title"));
    setModalBody(t("apply.deleteExperienceModal.job.body"));
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    if (
      !!jobData?.minNumJobs &&
      employmentHistory.length < jobData?.minNumJobs
    ) {
      setMinJobsMet(false);
    }
    if (
      !!jobData?.minNumJobs &&
      employmentHistory.length >= jobData?.minNumJobs
    ) {
      setMinJobsMet(true);
    }
  }, [employmentHistory]);

  //#############

  //#############
  // Education History
  //#############
  const [showEducationHistory, setShowEducationHistory] =
    useState<boolean>(false);
  const [educationHistory, educationHistoryActions] =
    useArray<ApplyEducationHistoryFormData>(
      defaultValue?.educationHistory ?? [],
    );
  const [currentEducationHistory, setCurrentEducationHistory] =
    useState<ApplyEducationHistoryFormData>();

  const onClickAddEducationHistory = () => {
    setShowEducationHistory(true);
  };

  const onSubmitEducationHistory = (data: ApplyEducationHistoryFormData) => {
    if (!!data.id) {
      const index = educationHistory.findIndex((v) => v.id === data.id);
      educationHistoryActions.update(index, data);
    } else {
      educationHistoryActions.push({ id: uuid(), ...data });
    }
    setCurrentEducationHistory(undefined);
    setShowEducationHistory(false);
  };

  const onRequestCloseEducationHistory = () => {
    setCurrentEducationHistory(undefined);
    setShowEducationHistory(false);
  };

  const onRequestEditEducationHistory = (index: number) => {
    const item = educationHistory[index];
    setCurrentEducationHistory(item);
    setShowEducationHistory(true);
  };

  const onRequestDeleteEducationHistory = (index: number) => {
    setCurrentIndex(index);
    setCurrentArray("educationHistory");
    setModalTitle(t("apply.deleteExperienceModal.education.title"));
    setModalBody(t("apply.deleteExperienceModal.education.body"));
    setIsDeleteModalOpen(true);
  };
  //#############

  //#############
  // Certification History
  //#############
  const [showCertificationHistory, setShowCertificationHistory] =
    useState<boolean>(false);
  const [certificationHistory, certificationHistoryActions] =
    useArray<ApplyCertificationHistoryFormData>(
      defaultValue?.certificationHistory ?? [],
    );
  const [currentCertificationHistory, setCurrentCertificationHistory] =
    useState<ApplyCertificationHistoryFormData>();

  const onClickAddCertificationHistory = () => {
    setShowCertificationHistory(true);
  };

  const onSubmitCertificationHistory = (
    data: ApplyCertificationHistoryFormData,
  ) => {
    if (!!data.id) {
      const index = certificationHistory.findIndex((v) => v.id === data.id);
      certificationHistoryActions.update(index, data);
    } else {
      certificationHistoryActions.push({ id: uuid(), ...data });
    }
    setCurrentCertificationHistory(undefined);
    setShowCertificationHistory(false);
  };

  const onRequestCloseCertificationHistory = () => {
    setCurrentCertificationHistory(undefined);
    setShowCertificationHistory(false);
  };

  const onRequestEditCertificationHistory = (index: number) => {
    const item = certificationHistory[index];
    setCurrentCertificationHistory(item);
    setShowCertificationHistory(true);
  };

  const onRequestDeleteCertificationHistory = (index: number) => {
    setCurrentIndex(index);
    setCurrentArray("certificationHistory");
    setModalTitle(t("apply.deleteExperienceModal.certification.title"));
    setModalBody(t("apply.deleteExperienceModal.certification.body"));
    setIsDeleteModalOpen(true);
  };
  //#############

  const onClickNext = () => {
    // Submit it to main application state
    if (
      !!jobData?.minNumJobs &&
      employmentHistory.length < jobData?.minNumJobs
    ) {
      alert(
        t("apply.experience.employmenthistory.minimumJobs.message", {
          minNumbJobs: jobData?.minNumJobs,
        }),
      );
      setMinJobsMet(false);
      return;
    }
    onSubmit({
      employmentHistory,
      educationHistory,
      certificationHistory,
    });
    // Continue to next step
    dispatch({ type: "setStepNext" });
  };

  const onClose = () => {
    setIsDeleteModalOpen(false);
  };

  const removeCard = () => {
    switch (currentArray) {
      case "employmentHistory": {
        employmentHistoryActions.remove(currentIndex);
        break;
      }
      case "educationHistory": {
        educationHistoryActions.remove(currentIndex);
        break;
      }
      case "certificationHistory": {
        certificationHistoryActions.remove(currentIndex);
        break;
      }
    }
    setIsDeleteModalOpen(false);
  };

  const anyModalOpen =
    showEmploymentHistory || showEducationHistory || showCertificationHistory;

  return (
    <StyledDiv>
      <Modal
        showModal={isDeleteModalOpen}
        hasPrimary={false}
        hasDestroy={true}
        title={modalTitle}
        onRequestClose={onClose}
        onRequestDestroy={removeCard}
        label={{
          destroyButton: t("apply.modaldeletebutton"),
          cancelButton: t("apply.modalcancelbutton"),
        }}
      >
        {modalBody}
      </Modal>
      {showEmploymentHistory && (
        <ApplyEmploymentHistory
          defaultValue={currentEmploymentHistory}
          onSubmit={onSubmitEmploymentHistory}
          onRequestClose={onRequestCloseEmploymentHistory}
          jobData={jobData}
        />
      )}
      {showEducationHistory && (
        <ApplyEducationHistory
          defaultValue={currentEducationHistory}
          onSubmit={onSubmitEducationHistory}
          onRequestClose={onRequestCloseEducationHistory}
        />
      )}
      {showCertificationHistory && (
        <ApplyCertificationHistory
          defaultValue={currentCertificationHistory}
          onSubmit={onSubmitCertificationHistory}
          onRequestClose={onRequestCloseCertificationHistory}
        />
      )}
      {!anyModalOpen && (
        <>
          <ApplyStepHeader
            title={t("apply.experience.header")}
            description={t("apply.experience.description")}
          />
          <Section
            title={t("apply.experience.employmenthistory.header")}
            description={t("apply.experience.employmenthistory.description")}
            error={
              minJobsMet
                ? ""
                : t("apply.experience.employmenthistory.minimumJobs.message", {
                    minNumbJobs: jobData?.minNumJobs,
                  })
            }
            linkTerm={t("apply.experience.employmenthistory.addlinkterm")}
            cards={employmentHistory.map<CardProps>((item, i) => {
              const dateStartFormatted = moment(item.startDate).format(
                "MMM YYYY",
              );
              const dateEndFormatted = !!item.endDate
                ? moment(item.endDate).format("MMM YYYY")
                : t("apply.experience.employmenthistory.current");
              let datesFormatted = `${dateStartFormatted} - ${dateEndFormatted}`;
              return {
                index: i,
                title: item.jobTitle,
                description: [item.companyName, datesFormatted],
                onRequestEdit: () => onRequestEditEmploymentHistory(i),
                onRequestDelete: () => onRequestDeleteEmploymentHistory(i),
              };
            })}
            onClickAdd={onClickAddEmploymentHistory}
          />
          <Section
            title={t("apply.experience.educationhistory.header")}
            description={t("apply.experience.educationhistory.description")}
            linkTerm={t("apply.experience.educationhistory.addlinkterm")}
            cards={educationHistory.map<CardProps>((item, i) => {
              let description: string[] = [item.degree];
              if (!!item.graduation) {
                description.push(
                  `${t("apply.experience.educationhistory.graduated")}: ` +
                    moment(item.graduation).format("MMM YYYY"),
                );
              }
              return {
                index: i,
                title: item.school,
                description: description,
                onRequestEdit: () => onRequestEditEducationHistory(i),
                onRequestDelete: () => onRequestDeleteEducationHistory(i),
              };
            })}
            onClickAdd={onClickAddEducationHistory}
          />
          <Section
            title={t("apply.experience.certificationhistory.header")}
            description={t("apply.experience.certificationhistory.description")}
            linkTerm={t("apply.experience.certificationhistory.addlinkterm")}
            cards={certificationHistory.map<CardProps>((item, i) => {
              let description: string[] = [item.issuer];
              if (!!item.issued) {
                description.push(
                  `${t("apply.experience.certificationhistory.issued")}: ` +
                    moment(item.issued).format("MMM YYYY"),
                );
              }
              return {
                index: i,
                title: item.name,
                description: description,
                onRequestEdit: () => onRequestEditCertificationHistory(i),
                onRequestDelete: () => onRequestDeleteCertificationHistory(i),
              };
            })}
            onClickAdd={onClickAddCertificationHistory}
          />
          <HorizontalRuleFooterStyled />
          <StepperFooterStyled>
            <StepperFooter submitOnNext={false} onClickNext={onClickNext} />
          </StepperFooterStyled>
        </>
      )}
    </StyledDiv>
  );
};
