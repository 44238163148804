import { FunctionComponent, useEffect, useRef, MouseEventHandler } from "react";
import styled, { css, keyframes } from "styled-components";
import { up } from "styled-breakpoints";
import { fadeInUp, fadeOutDown, fadeIn, fadeOut } from "react-animations";
import {
  ButtonVariant,
  Button,
  Panel,
  Mixins,
  ButtonType,
} from "@teamsoftware/reactcomponents";

export type ModalProps = {
  hasCloseIntent: boolean;
  onCloseIntent: () => void;
  onClose: () => void;
  onOpen: () => void;
};

type StyledProps = {
  isClosing?: boolean;
};

const PanelStyled = styled(Panel)<StyledProps>`
  position: absolute;
  display: inline-block;
  margin: 0 auto;
  max-width: 40rem;
  ${up("md")} {
    top: 2rem;
  }
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 9999;
  ${(props) =>
    !props.isClosing &&
    css`
      animation: 0.5s ${keyframes`${fadeInUp}`};
    `}
  ${(props) =>
    !!props.isClosing &&
    css`
      animation: 0.5s ${keyframes`${fadeOutDown}`};
    `}
`;

const DivContainerStyled = styled.div<StyledProps>`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 9998;
  background-color: #989898;
  ${(props) =>
    !props.isClosing &&
    css`
      animation: 0.5s ${keyframes`${fadeIn}`};
    `}
  ${(props) =>
    !!props.isClosing &&
    css`
      animation: 0.5s ${keyframes`${fadeOut}`};
    `}
`;

const HeaderStyled = styled.header`
  ${Mixins.Flex.row()}
  ${Mixins.Flex.justify({ direction: "end" })}
`;

export const Modal: FunctionComponent<
  ModalProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  onClose,
  onOpen,
  onCloseIntent,
  hasCloseIntent,
  ...props
}) => {
  const panelRef = useRef<HTMLDivElement>(null);

  const stopPropagation: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    var panelElement = panelRef.current;
    if (panelElement) {
      if (hasCloseIntent) {
        panelElement?.addEventListener("animationend", onClose);
      } else {
        panelElement?.addEventListener("animationend", onOpen);
      }
    }
    return () => {
      panelElement?.removeEventListener("animationend", onClose);
      panelElement?.removeEventListener("animationend", onOpen);
    };
  }, [onOpen, onClose, hasCloseIntent]);

  return (
    <DivContainerStyled
      isClosing={hasCloseIntent}
      onClick={onCloseIntent}
      data-testid="modal-bg"
    >
      <PanelStyled
        isClosing={hasCloseIntent}
        ref={panelRef}
        onClick={stopPropagation}
        {...props}
      >
        <HeaderStyled>
          <Button
            variant={ButtonVariant.SUBTLE}
            buttonType={ButtonType.MINIMAL}
            onClick={onCloseIntent}
            data-testid="modal-btn"
          >
            Cancel
          </Button>
        </HeaderStyled>

        {children}
      </PanelStyled>
    </DivContainerStyled>
  );
};
