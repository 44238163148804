import { useQuery } from "react-query";
import axios from "utils/axios-api-base";

import { useTenant } from "context/tenantContext";
import moment from "moment";

export type SearchRequest = {
  searchKeywords: string;
  locationKeywordsOrPostalCode: string;
  distanceLimitInMeters: number;
  positionTypes: string[] | undefined;
  sortBy: string;
  pageLimit: number;
  pageOffset: number;
};

export type SearchResponseJob = {
  postingId: string;
  jobTitle: string;
  jobOverview: string;
  postingPlace: string;
  postingTerritory: string;
  positionTypeStr: string;
  minimumPay: number;
  maximumPay: number;
  activationDate: moment.Moment;
};

export type SearchResponse = {
  results: SearchResponseJob[];
  totalResults: number;
};

export const useAPISearchJobs = (request: SearchRequest) => {
  const tenant = useTenant();

  return useQuery(
    // What makes this query unique?
    [
      request,
      request.searchKeywords,
      request.locationKeywordsOrPostalCode,
      request.distanceLimitInMeters,
      request.positionTypes,
      request.sortBy,
      request.pageLimit,
      request.pageOffset,
    ],
    // Query
    async (): Promise<SearchResponse | null> => {
      const { data } = await axios.post<SearchResponse | null>(
        "search",
        request,
        {
          headers: {
            "tenant-id": tenant.id,
          },
        }
      );
      return data;
    },
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  );
};
