import { useQuery } from "react-query";
import axios from "utils/axios-api-base";
import { JobDetails } from "components/JobDetail/JobDetails";

const queryKey: string = "JobDetails";

export const useAPIJobDetails = (postingId: string) => {
  return useQuery(
    [queryKey, postingId],
    async (): Promise<JobDetails | null> => {
      const { status, data } = await axios.get(`JobPosting/${postingId}`);
      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;
      // Has tenant
      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
