import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  Loading,
  HorizontalRule,
  Icon,
  Tag,
  Mixins,
} from "@teamsoftware/reactcomponents";
import { down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { JobDetail } from "components/JobDetail";
import { ApplyPanel, ShareMedium } from "components/ApplyPanel";
import { ApplyPanelMobile } from "components/ApplyPanel/Mobile/ApplyPanelMobile";
import { Header } from "components/Header";
import { BackLink } from "components/BackLink";
import { JobResultTag } from "models/JobResultTag";
import { useAPIJobDetails } from "api/job-posting/useAPIJobDetails";
import { useAPICheckEmailAndSendEmail } from "api/passwordless/useAPICheckEmailAndSendEmail";
import { useAPIGetUkFormatting } from "api/client-features/useAPIGetUkFormatting";
import { PasswordlessAuthApplicantRequest } from "api/passwordless/useAPICheckEmailAndSendEmail";
import { Helmet } from "react-helmet-async";
import { MyTagManager, bodyTags } from "context/trackingTagsContext";
import { useAPIGetCompletionState } from "api/apply/useAPIGetCompletionState";
import { ApplicationInfo } from "models/ApplicationInfo";

export type JobPageProps = {
  id: string;
};

const DivAssideStyled = styled.div`
  flex: 0 0 20rem;
  padding-left: 0.5rem;
  ${down("sm")} {
    flex: initial;
  }
`;

const ApplyPanelStyled = styled(ApplyPanel)`
  margin-top: -3rem;
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
`;

const DivContainerStyled = styled.div`
  position: relative;
  ${up("md")} {
    ${Mixins.Flex.row()}
  }
  ${down("sm")} {
    ${Mixins.Flex.column()}
  }
`;

const DivMainContainerStyled = styled.div`
  padding: 0 1rem;
`;

const DivTagsContainer = styled.div`
  margin: 0.5rem 0;
  ${Mixins.Flex.row({ gap: 1 })}
  ${Mixins.Flex.wrap()}
`;

const TypographyTagsContainer = styled(Typography)`
  ${Mixins.Flex.row({ gap: 0 })}
  ${Mixins.Flex.wrap()}
`;

const TagsStyled = styled(Tag)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
`;

const BackToSearchLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <BackLink
      id="backToSearchLink"
      data-testid="nav-back"
      text={t("job.nav.back")}
      onClick={() => navigate(-1)}
    />
  );
};

export const JobPage: FunctionComponent = () => {
  let { id }: any = useParams();
  const { t } = useTranslation();
  const [jobTags, setJobTags] = useState<JobResultTag[]>([]);
  const [isJobExpired, setIsJobExpired] = useState<boolean>(false);
  const { isLoading, data } = useAPIJobDetails(id);
  const [alreadyApplied, setAlreadyApplied] = useState<boolean>(false);
  const [disregarding, setDisregarding] = useState<boolean>(false);
  const navigate = useNavigate();
  const desktopBreakpoint = useBreakpoint(up("md"));
  const jobTitle: string = data?.jobTitle ?? "Error";
  let applyEmail: string = "";
  const [applicantEmail, setApplicantEmail] = useState<string>();
  const [willGetCompletionState, setWillGetCompletionState] =
    useState<boolean>(false);
  const { isLoading: ukLoading, data: hasUkFormatting } =
    useAPIGetUkFormatting();

  // Set structured data (meta-data) for Google Job API
  const websiteStructuredData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: data?.companyName,
    alternateName: data?.companyName,
    url: data?.companyUrl,
  };

  const jobStructuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: data?.jobTitle,
    description: data?.jobOverview,
    employmentType: data?.positionType,
    datePosted: data?.activationDate?.toISOString(),
    validThrough: data?.expirationDate?.toISOString(),
    hiringOrganization: {
      "@type": "Organization",
      name: data?.companyName,
      sameAs: data?.companyUrl,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: data?.address1,
        addressLocality: data?.place,
        addressRegion: data?.territory,
        postalCode: data?.postalCode,
        addressCountry: data?.country,
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        minValue: data?.minimumPay,
        maxValue: data?.maximumPay,
        unitText: data?.compensationType === "Salary" ? "YEAR" : "HOUR",
      },
    },
  };

  const useAPICheckEmailAndSendEmail_Success = () => {
    if (
      (typeof completionData === "boolean" && !completionData) ||
      disregarding
    ) {
      navigate(`/passwordlessLogin`, { state: jobTitle });
    }
  };

  const useAPICheckEmailAndSendEmail_Failed = () => {
    if (typeof applicantEmail == "string" && applicantEmail.length !== 0) {
      navigate(`/apply/job/${id}`, { state: applicantEmail });
    }
  };

  const { mutate } = useAPICheckEmailAndSendEmail(
    useAPICheckEmailAndSendEmail_Success,
    useAPICheckEmailAndSendEmail_Failed,
  );

  const applicationInfo: ApplicationInfo = {
    jobId: data?.externalId,
    email: applicantEmail,
  };

  const { isLoading: completionLoading, data: completionData } =
    useAPIGetCompletionState(applicationInfo, willGetCompletionState);

  useEffect(() => {
    if (
      typeof completionData == "boolean" &&
      !!applicantEmail &&
      !completionLoading
    ) {
      setWillGetCompletionState(false);
      setAlreadyApplied(completionData);
      const applicantRequest: PasswordlessAuthApplicantRequest = {
        email: applicantEmail,
        jobId: id,
        domain: window.location.hostname,
      };
      if (completionData === false || disregarding) {
        mutate(applicantRequest);
      }
    }
    if (!!!completionData && !!applicantEmail) {
      setWillGetCompletionState(true);
    }
  }, [applicantEmail, completionData, disregarding]);

  const onApply = (email: string | undefined) => {
    if (email !== undefined) {
      applyEmail = email;
      setApplicantEmail(email);
      MyTagManager.dataLayer({
        dataLayer: {
          event: "Started applying",
        },
      });
    } else {
      navigate(`/apply/job/${id}`);
    }
  };

  const onDisregard = (email: string | undefined) => {
    setDisregarding(true);
  };

  const onShare = (medium: ShareMedium) => {
    console.log(`share=>${medium}`);
  };

  useEffect(() => {
    if (data && !ukLoading) {
      let locationTag: JobResultTag = {
        title: `${data.place}, ${data.territory}`,
        icon: <Icon.Pin />,
      };

      let positionTypeTag: JobResultTag = {
        title: data.positionType,
        icon: <Icon.Time />,
      };

      let salaryTag: JobResultTag = {
        title: hasUkFormatting
          ? `£${data.minimumPay}-£${data.maximumPay}`
          : `$${data.minimumPay}-$${data.maximumPay}`, // BCG: Will need to revisit this to deal with currency types
        icon: hasUkFormatting ? (
          <Icon.Circle variant={Icon.CircleVariant.POUND} />
        ) : (
          <Icon.Circle variant={Icon.CircleVariant.DOLLAR} />
        ),
      };

      setJobTags([locationTag, positionTypeTag, salaryTag]);

      let currentDate = new Date();
      setIsJobExpired(currentDate > data.expirationDate);
    }
  }, [data]);

  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }

  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header
        leftComponent={
          desktopBreakpoint ? (
            <BackToSearchLink data-testid="nav-back" />
          ) : undefined
        }
      />
      <div>
        <Loading isLoading={isLoading} />
        {!isLoading && data && !isJobExpired && (
          <>
            <Helmet>
              {/* Standard metadata tags */}
              <meta itemProp="name" content={websiteStructuredData.name} />

              {/* Facebook tags */}
              <meta property="og:url" content={websiteStructuredData.url} />
              <meta property="og:type" content={"website"} />
              <meta property="og:name" content={websiteStructuredData.name} />
              <meta property="og:title" content={websiteStructuredData.name} />

              {/* Twitter tags */}
              <meta name="twitter:title" content={websiteStructuredData.name} />
              <meta
                name="twitter:description"
                content={websiteStructuredData.name}
              />

              <script type="application/ld+json">
                {JSON.stringify(jobStructuredData)}
              </script>
              <script type="application/ld+json">
                {JSON.stringify(websiteStructuredData)}
              </script>
            </Helmet>
            <DivMainContainerStyled>
              {!desktopBreakpoint && <BackToSearchLink />}
              <Typography
                id="pageTitleHeader"
                variant={TypographyVariant.H1}
                weight={FontWeight.Bold}
                color={Color.Contrast}
                data-testid="page-title"
                dangerouslySetInnerHTML={{ __html: jobTitle }}
              />
              <DivTagsContainer>
                <TypographyTagsContainer
                  variant={TypographyVariant.TextLarge}
                  weight={FontWeight.SemiBold}
                  color={Color.Subtle}
                >
                  {jobTags?.map((tag) => (
                    <TagsStyled
                      key={tag.title}
                      icon={tag.icon}
                      text={tag.title}
                      onClick={tag.onClick}
                    />
                  ))}
                </TypographyTagsContainer>
              </DivTagsContainer>
              <HorizontalRule />
              <DivContainerStyled>
                <JobDetail jobDetails={data} data-testid="job-detail" />
                {desktopBreakpoint && (
                  <DivAssideStyled>
                    <ApplyPanelStyled
                      idVariant="main"
                      onApply={onApply}
                      onDisregard={onDisregard}
                      onShare={onShare}
                      alreadyApplied={alreadyApplied}
                    />
                  </DivAssideStyled>
                )}
                {!desktopBreakpoint && (
                  <ApplyPanelMobile
                    idVariant="main"
                    onApply={onApply}
                    onDisregard={onDisregard}
                    onShare={onShare}
                    alreadyApplied={alreadyApplied}
                  />
                )}
              </DivContainerStyled>
            </DivMainContainerStyled>
          </>
        )}

        {!isLoading && !!!data && (
          <DivMainContainerStyled>
            <Typography
              id="pageTitleHeader"
              variant={TypographyVariant.TextLarge}
              weight={FontWeight.Bold}
              color={Color.Contrast}
              data-testid="page-title"
            >
              {t("jobdetail.noJobDataError")}
            </Typography>
          </DivMainContainerStyled>
        )}

        {!isLoading && data && isJobExpired && (
          <DivMainContainerStyled>
            <Typography
              id="postingExpiredMessage"
              variant={TypographyVariant.TextLarge}
              weight={FontWeight.Bold}
              color={Color.Contrast}
              data-testid="posting-expired"
            >
              {t("jobdetail.postingExpired")}
            </Typography>
          </DivMainContainerStyled>
        )}
      </div>
    </>
  );
};
