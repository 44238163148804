import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { useAPIAuthenticate } from "api/passwordless/useAPIAuthenticate";
import { Loading } from "@teamsoftware/reactcomponents";

export type AuthenticationPageProps = {
  id: string;
  jobId: string;
};

export const AuthenticationPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  let userId: any = searchParams.get("id");
  let jobId: any = searchParams.get("jobid");
  const navigate = useNavigate();
  const { isLoading, data } = useAPIAuthenticate(userId);

  if (!isLoading) {
    if (data !== undefined) {
      ReactSession.set("userToken", data);
      navigate(`/apply/job/${jobId}`);
    }
    if (data === undefined) {
      navigate(`/passwordlessLoginExpired`);
    }
  }
  return (
    <div>
      <Loading isLoading={isLoading}> </Loading>
    </div>
  );
};
