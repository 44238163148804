import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  useStepper,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
} from "@teamsoftware/reactcomponents";

import { Card } from "../Card";
import { ApplyQuestionData } from "components/ApplicationQuestions";
import { useAPIGetEEOData } from "api/client-features/useAPIGetEEOData";

export type QuestionsCardProps = {
  stepIndex: number;
  data: ApplyQuestionData[];
};

const CardItemDiv = styled.div`
  margin-top: 1.5rem;
`;

const SectionContainerDiv = styled.div`
  margin-bottom: 2rem;
`;

const SectionHeaderTypography = styled(Typography)`
  margin-top: 2rem;
`;

type QuestionsCardSection = {
  sectionId: string;
  sectionTitle: string;
  sectionRank: number;
  questions: QuestionsCardItem[];
};

type QuestionsCardItem = {
  key: string;
  questionText: string;
  displayNumber: string;
  isFollowUp: boolean;
  answerText?: string[];
};

export const QuestionsCard = ({ stepIndex, data }: QuestionsCardProps) => {
  const { t } = useTranslation();

  const { dispatch } = useStepper();

  const [isSectionQuestionsExpanded, setIsSectionQuestionsExpanded] =
    useState<boolean>(false);

  const [questionsHaveErrors, setQuestionsHaveErrors] =
    useState<boolean>(false);

  const goToStep = () => {
    dispatch({ type: "setStep", payload: stepIndex });
  };

  const { isLoading: eeoLoading, data: eeoData } = useAPIGetEEOData();

  const questionSections: QuestionsCardSection[] = [];

  // Build question sections
  if (!!data && data.length > 0) {
    data.forEach((question) => {
      if (
        !questionSections.some(
          (section) => section.sectionId === question.section.id,
        )
      ) {
        questionSections.push({
          sectionId: question.section.id,
          sectionTitle: question.section.name,
          sectionRank: question.section.rank,
          questions: [],
        } as QuestionsCardSection);
      }

      let indexToUpdate: number = questionSections.findIndex(
        (section) => section.sectionId === question.section.id,
      );

      if (indexToUpdate === -1) {
        // Invalid section id
        setQuestionsHaveErrors(true);
        return;
      }

      questionSections[indexToUpdate].questions.push({
        key: question.questionId,
        displayNumber: question.questionDisplayNumber,
        questionText: question.questionText,
        answerText: question.answers.map<string>((q) => q.answerText),
        isFollowUp: question.isFollowup,
      } as QuestionsCardItem);
    });

    questionSections.sort((a, b) => {
      if (a.sectionRank > b.sectionRank) {
        return 1;
      } else if (a.sectionRank < b.sectionRank) {
        return -1;
      }

      return 0;
    });
  }

  questionSections.forEach((section) => {
    section.questions.sort((a, b) => {
      if (a.displayNumber < b.displayNumber) {
        return -1;
      } else {
        return 1;
      }
    });
  });

  return (
    <>
      {questionsHaveErrors && (
        <Card title={t("apply.questions.step.title")} onClickEdit={() => {}}>
          Error loading question data
        </Card>
      )}
      {!questionsHaveErrors && (
        <Card
          title={t("apply.questions.step.title")}
          onClickEdit={() => goToStep()}
          canExpand
          isExpanded={isSectionQuestionsExpanded}
          onToggleExpand={() => setIsSectionQuestionsExpanded((x) => !x)}
        >
          <div>
            {questionSections.map((section, i) => {
              if (!isSectionQuestionsExpanded && i > 0) return;

              return (
                <SectionContainerDiv key={section.sectionId}>
                  <SectionHeaderTypography
                    variant={TypographyVariant.TextLarge}
                    weight={FontWeight.SemiBold}
                    color={Color.Default}
                  >
                    {!eeoLoading &&
                    (eeoData?.equalityMonitoringIsTurnedOn === false ||
                      section.sectionTitle !== "EEO")
                      ? section.sectionTitle
                      : t("apply.questions.equalitymonitoring")}
                  </SectionHeaderTypography>
                  {!!section.questions &&
                    section.questions.map((question, questionIndex) => {
                      if (
                        (!isSectionQuestionsExpanded && questionIndex > 1) ||
                        (question.isFollowUp &&
                          (!!!question.answerText ||
                            question.answerText[0] === ""))
                      )
                        return;

                      return (
                        <CardItemDiv key={question.key}>
                          <p>
                            <b>
                              {question.displayNumber}. {question.questionText}
                            </b>
                          </p>
                          {!!question.answerText &&
                            question.answerText?.map(
                              (answertext, answerTextIndex) => {
                                if (!!answertext && answertext.length !== 0) {
                                  return (
                                    <p key={answerTextIndex}>{answertext}</p>
                                  );
                                } else {
                                  return <p key={answerTextIndex}>N/A</p>;
                                }
                              },
                            )}
                        </CardItemDiv>
                      );
                    })}
                </SectionContainerDiv>
              );
            })}
          </div>
        </Card>
      )}
    </>
  );
};
