import React, { FunctionComponent } from "react";
import { Modal, UnorderedList, Typography, TypographyVariant, FontWeight } from "@teamsoftware/reactcomponents";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface CC305ModalProps {
  onClose: () => void;
  onCloseIntent: () => void;
  onOpen: () => void;
  hasCloseIntent: boolean;
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1rem;
`;

const TitleWithSpacing = styled(Typography)`
  margin-bottom: 1rem;
`;

const JustifiedText = styled(Typography)`
  text-align: justify;
  margin-bottom: 1rem;
`;

const CC305Modal: FunctionComponent<CC305ModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const modalCC305Content = {
    mainTitle: t("modalCC305Content.mainTitle"),
    minorTitle: t("modalCC305Content.minorTitle"),
    text: t("modalCC305Content.text"),
    disabilityListTitle: t("modalCC305Content.disabilityListTitle"),
    disabilityList: t("modalCC305Content.disabilityList"),
    disabilitiesText: t("modalCC305Content.disabilitiesText"),
    doneViewingText: t("Done Viewing"),
  };

  const disabilityItems = modalCC305Content.disabilityList.split(";").map((item, index) => ({
    key: index.toString(),
    text: item.trim(),
  }));

  return (
    <Modal
      hasPrimary
      label={{
        deleteButton: t('Delete'),
        primaryButton: t(modalCC305Content.doneViewingText),
      }}
      onRequestClose={onClose}
      onRequestPrimary={onClose}
      showModal
      title={modalCC305Content.mainTitle}
    >
      <ModalContent>
        <TitleWithSpacing variant={TypographyVariant.SubheadSmall} weight={FontWeight.Bold}>
          {modalCC305Content.minorTitle}
        </TitleWithSpacing>
        <JustifiedText variant={TypographyVariant.TextDefault} weight={FontWeight.Regular}>
          {modalCC305Content.text} 
        </JustifiedText>
        <TitleWithSpacing variant={TypographyVariant.SubheadSmall} weight={FontWeight.Bold}>
          {modalCC305Content.disabilityListTitle}
        </TitleWithSpacing>
        <JustifiedText variant={TypographyVariant.TextDefault} weight={FontWeight.Regular}>
          {modalCC305Content.disabilitiesText}
        </JustifiedText>
        <UnorderedList listItems={disabilityItems} />
      </ModalContent>
    </Modal>
  );
};

export default CC305Modal;
