import styled from "styled-components";
import { up, down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { Mixins } from "@teamsoftware/reactcomponents";

import { HeaderLogo } from "./components/HeaderLogo";
import { HeaderNav } from "./components/HeaderNav";

const HeaderStyled = styled.header`
  ${Mixins.Flex.column()}
  ${down("sm")} {
    background-color: ${(props) => props.theme.Panel.Background};
  }
`;

type HeaderProps = {
  leftComponent?: JSX.Element;
};

export const Header = ({ leftComponent }: HeaderProps) => {
  var desktopBreakpoint = useBreakpoint(up("md"));
  return (
    <HeaderStyled data-testid="header">
      {desktopBreakpoint && <HeaderLogo />}
      <HeaderNav leftComponent={leftComponent} />
    </HeaderStyled>
  );
};
