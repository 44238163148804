import { useQuery } from "react-query";
import axios from "utils/axios-api-base";
import { StandardApplicant } from "@kwantek/standard_applicant";

export type LoadProgressRequest = {
  email: string;
  jobId: string | undefined;
};
const queryKey: string = "ApplicationProgress";

export const useAPILoadProgress = (request: LoadProgressRequest) => {
  return useQuery(
    [queryKey, request],
    async (): Promise<StandardApplicant | null> => {
      const { status, data } = await axios.get("Application/LoadProgress", {
        params: request,
      });
      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      return data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
