import { useEffect } from "react";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Checkbox,
  HorizontalRule,
  InputGroup,
  InputText,
  Mixins,
  RadioButtonGroup,
  Textarea,
  InputPhoneNumber,
} from "@teamsoftware/reactcomponents";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { ApplyStepHeader } from "components/ApplyStepHeader";
import { JobDetails } from "components/JobDetail/JobDetails";
import { useAPIGetUkFormatting } from "api/client-features/useAPIGetUkFormatting";

export type ApplyEmploymentHistoryProps = {
  defaultValue?: ApplyEmploymentHistoryFormData;
  jobData: JobDetails | null | undefined;
  onSubmit: (data: ApplyEmploymentHistoryFormData) => void;
  onRequestClose: () => void;
};

export type ApplyEmploymentHistoryFormData = {
  id?: string;
  jobTitle: string;
  companyName: string;
  startDate: Date;
  endDate?: Date;
  place: string;
  roleDescription: string;
  startingPay?: number;
  endingPay?: number;
  supervisorName?: string;
  supervisorPhone?: string;
  canContactSupervisor: number;
  contactSupervisorReason?: string;
  stillWorkHere: boolean;
};

const StyledDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const HorizontalRuleStyled = styled(HorizontalRule)`
  margin: 1rem 0;
`;

const HorizontalRuleFooterStyled = styled(HorizontalRule)`
  margin-top: 3.75rem;
  margin-bottom: 2.75rem;
`;

const FooterButtonGroupDiv = styled.div`
  ${Mixins.Flex.row({ gap: 0.5 })}
`;

const FormContainer = styled.div`
  ${Mixins.Flex.column({ gap: 1 })}
`;

const FormSBSContainer = styled.div`
  ${up("md")} {
    ${Mixins.Flex.row({ gap: 1 })}
  }
  ${down("sm")} {
    ${Mixins.Flex.column({ gap: 1 })}
  }
  width: 100%;
`;

const InputGroupSBS = styled(InputGroup)`
  ${Mixins.Flex.flex()}
`;

export const ApplyEmploymentHistory = ({
  defaultValue,
  jobData,
  onSubmit,
  onRequestClose,
}: ApplyEmploymentHistoryProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
    control,
  } = useForm<ApplyEmploymentHistoryFormData>({
    defaultValues: {
      ...defaultValue,
    },
  });

  var desktopBreakpoint = useBreakpoint(up("md"));
  const stillWorkHere = watch("stillWorkHere");
  const canContactSupervisor = watch("canContactSupervisor");
  const cannotContactSupervisor = canContactSupervisor === 2;
  const contactSupervisorReasonRows = desktopBreakpoint ? 2 : 10;
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const MINIMUM_DATE: string = "01/01/1945";
  const { isLoading: ukLoading, data: hasUkFormatting } =
    useAPIGetUkFormatting();

  const onChangeCanContactSupervisor: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    const v = event.target.value;
    const vInt = parseInt(v);
    if (!isNaN(vInt) && isFinite(vInt)) {
      setValue("canContactSupervisor", vInt);
    }
  };

  const determineStartDateValue = (): string => {
    if (defaultValue?.startDate && !!!dirtyFields.startDate) {
      return moment(defaultValue.startDate).toISOString().substring(0, 10);
    } else {
      if (
        startDate !== undefined &&
        startDate !== null &&
        startDate.toLocaleString().length > 0
      ) {
        return moment(startDate).toISOString().substring(0, 10);
      }
      return "";
    }
  };

  const determineEndDateValue = (): string => {
    if (defaultValue?.endDate && !!!dirtyFields.endDate) {
      return moment(defaultValue.endDate).toISOString().substring(0, 10);
    } else {
      if (
        endDate !== undefined &&
        endDate !== null &&
        endDate.toLocaleString().length > 0
      ) {
        return moment(endDate).toISOString().substring(0, 10);
      } else {
        return "";
      }
    }
  };

  const isEditing = !!defaultValue;

  useEffect(() => {
    register("canContactSupervisor", {
      required: jobData?.mayWeContactRequired
        ? t("validation.required")
        : false,
    });
  }, [register, t]);

  useEffect(() => {
    if (stillWorkHere) setValue("endDate", undefined);
  }, [setValue, stillWorkHere]);

  return (
    <StyledDiv>
      <ApplyStepHeader
        title={t("apply.employmenthistory.header")}
        description={t("apply.employmenthistory.description")}
      />
      <HorizontalRuleStyled />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <InputGroup
            label={`${t("apply.employmenthistory.form.jobtitle.label")}*`}
            error={errors.jobTitle?.message}
            input={
              <InputText
                id="employmenthistory-jobtitle"
                data-testid="employmenthistory-jobtitle"
                placeholder={t(
                  "apply.employmenthistory.form.jobtitle.placeholder",
                )}
                fullWidth
                {...register("jobTitle", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <InputGroup
            label={`${t("apply.employmenthistory.form.companyname.label")}*`}
            error={errors.companyName?.message}
            input={
              <InputText
                id="employmenthistory-companyname"
                data-testid="employmenthistory-companyname"
                placeholder={t(
                  "apply.employmenthistory.form.companyname.placeholder",
                )}
                fullWidth
                {...register("companyName", {
                  required: t("validation.required"),
                })}
              />
            }
          />
          <FormSBSContainer>
            {jobData?.startEndDatesEnabled && (
              <InputGroupSBS
                label={
                  jobData.startEndDatesRequired
                    ? `${t("apply.employmenthistory.form.start.label")}*`
                    : t("apply.employmenthistory.form.start.label")
                }
                error={errors.startDate?.message}
                input={
                  <InputText
                    id="employmenthistory-startdate"
                    data-testid="employmenthistory-startdate"
                    type="date"
                    placeholder={moment(new Date()).format()}
                    value={determineStartDateValue()}
                    fullWidth
                    {...register("startDate", {
                      required: jobData?.startEndDatesRequired
                        ? t("validation.required")
                        : false,
                      validate: (value: Date) => {
                        if (jobData?.startEndDatesRequired) {
                          const isValidDate =
                            moment(value) < moment() &&
                            moment(value) > moment(new Date(MINIMUM_DATE));
                          if (!isValidDate) {
                            return t("validation.dateBetween", {
                              startDate: MINIMUM_DATE,
                              endDate: moment().format("L").toString(),
                            });
                          }
                        }
                      },
                    })}
                  />
                }
              />
            )}
            {!desktopBreakpoint && jobData?.startEndDatesEnabled && (
              <InputGroupSBS
                label={t("apply.employmenthistory.form.stillworkhere.label")}
                input={
                  <Checkbox
                    id="employmenthistory-stillworkhere"
                    data-testid="employmenthistory-stillworkhere"
                    {...register("stillWorkHere")}
                  />
                }
              />
            )}
            {jobData?.startEndDatesEnabled && (
              <InputGroupSBS
                label={
                  jobData.startEndDatesRequired
                    ? `${t("apply.employmenthistory.form.end.label")}*`
                    : t("apply.employmenthistory.form.end.label")
                }
                error={errors.endDate?.message}
                input={
                  <InputText
                    id="employmenthistory-enddate"
                    data-testid="employmenthistory-enddate"
                    type="date"
                    placeholder={moment(new Date()).format()}
                    value={determineEndDateValue()}
                    fullWidth
                    disabled={stillWorkHere}
                    {...register("endDate", {
                      required: jobData?.startEndDatesRequired
                        ? stillWorkHere
                          ? undefined
                          : t("validation.required")
                        : false,
                      validate: (value: Date | undefined) => {
                        if (jobData?.startEndDatesRequired) {
                          const isValidDate =
                            moment(value) < moment() &&
                            moment(value) > moment(new Date(MINIMUM_DATE));
                          if (!stillWorkHere && !isValidDate) {
                            return t("validation.dateBetween", {
                              startDate: MINIMUM_DATE,
                              endDate: moment().format("L").toString(),
                            });
                          }
                        }
                      },
                    })}
                  />
                }
              />
            )}
          </FormSBSContainer>
          {desktopBreakpoint && jobData?.startEndDatesEnabled && (
            <InputGroup
              label={t("apply.employmenthistory.form.stillworkhere.label")}
              input={
                <Checkbox
                  id="employmenthistory-stillworkhere"
                  data-testid="employmenthistory-stillworkhere"
                  {...register("stillWorkHere")}
                />
              }
            />
          )}
          {jobData?.locationEnabled && (
            <InputGroup
              label={
                jobData.locationRequired
                  ? `${t("apply.employmenthistory.form.location.label")}*`
                  : t("apply.employmenthistory.form.location.label")
              }
              error={errors.place?.message}
              input={
                <InputText
                  id="employmenthistory-location"
                  data-testid="employmenthistory-location"
                  placeholder={t(
                    "apply.employmenthistory.form.location.placeholder",
                  )}
                  fullWidth
                  {...register("place", {
                    required: jobData?.locationRequired
                      ? t("validation.required")
                      : false,
                  })}
                />
              }
            />
          )}

          {jobData?.descriptionEnabled && (
            <InputGroup
              label={
                jobData.descriptionRequired
                  ? `${t("apply.employmenthistory.form.description.label")}*`
                  : t("apply.employmenthistory.form.description.label")
              }
              error={errors.roleDescription?.message}
              input={
                <Textarea
                  id="employmenthistory-roledescription"
                  data-testid="employmenthistory-roledescription"
                  placeholder={t(
                    "apply.employmenthistory.form.description.placeholder",
                  )}
                  {...register("roleDescription", {
                    required: jobData.descriptionRequired
                      ? t("validation.required")
                      : false,
                  })}
                />
              }
            />
          )}

          <FormSBSContainer>
            {jobData?.wageStartEnabled && !ukLoading && (
              <InputGroupSBS
                label={
                  jobData.wageStartRequired
                    ? `${t("apply.employmenthistory.form.startingpay.label")}*`
                    : t("apply.employmenthistory.form.startingpay.label")
                }
                error={errors.startingPay?.message}
                input={
                  <InputText
                    id="employmenthistory-startingpay"
                    data-testid="employmenthistory-startingpay"
                    placeholder={
                      hasUkFormatting
                        ? t(
                            "apply.employmenthistory.form.startingpay.placeholderUK",
                          )
                        : t(
                            "apply.employmenthistory.form.startingpay.placeholderUS",
                          )
                    }
                    fullWidth
                    {...register("startingPay", {
                      required: jobData.wageStartRequired
                        ? t("validation.required")
                        : false,
                      pattern: {
                        value: /[+-]?([0-9]*[.])?[0-9]+/,
                        message: t("validation.payRate"),
                      },
                    })}
                  />
                }
              />
            )}

            {jobData?.wageEndEnabled && !ukLoading && (
              <InputGroupSBS
                label={
                  jobData.wageEndRequired
                    ? `${t("apply.employmenthistory.form.endingpay.label")}*`
                    : t("apply.employmenthistory.form.endingpay.label")
                }
                error={errors.endingPay?.message}
                input={
                  <InputText
                    id="employmenthistory-endingpay"
                    data-testid="employmenthistory-endingpay"
                    placeholder={
                      hasUkFormatting
                        ? t(
                            "apply.employmenthistory.form.endingpay.placeholderUK",
                          )
                        : t(
                            "apply.employmenthistory.form.endingpay.placeholderUS",
                          )
                    }
                    fullWidth
                    {...register("endingPay", {
                      required: jobData.wageEndRequired
                        ? t("validation.required")
                        : false,
                      pattern: {
                        value: /[+-]?([0-9]*[.])?[0-9]+/,
                        message: t("validation.payRate"),
                      },
                    })}
                  />
                }
              />
            )}
          </FormSBSContainer>
          <FormSBSContainer>
            {jobData?.supervisorNameEnabled && (
              <InputGroupSBS
                label={
                  jobData.supervisorNameRequired
                    ? `${t(
                        "apply.employmenthistory.form.supervisorname.label",
                      )}*`
                    : t("apply.employmenthistory.form.supervisorname.label")
                }
                error={errors.supervisorName?.message}
                input={
                  <InputText
                    id="employmenthistory-supervisorname"
                    data-testid="employmenthistory-supervisorname"
                    placeholder={t(
                      "apply.employmenthistory.form.supervisorname.placeholder",
                    )}
                    fullWidth
                    {...register("supervisorName", {
                      required: jobData.supervisorNameRequired
                        ? t("validation.required")
                        : false,
                    })}
                  />
                }
              />
            )}

            {jobData?.supervisorPhoneEnabled && (
              <Controller
                control={control}
                name="supervisorPhone"
                rules={{
                  required: jobData.supervisorPhoneRequired
                    ? t("validation.required")
                    : false,
                  pattern: {
                    value: /^[\+\(\s.\-\/\d\)]{9,25}$/,
                    message: t("validation.phone"),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputGroupSBS
                    label={
                      jobData.supervisorPhoneRequired
                        ? `${t(
                            "apply.employmenthistory.form.supervisorphone.label",
                          )}*`
                        : t(
                            "apply.employmenthistory.form.supervisorphone.label",
                          )
                    }
                    error={errors.supervisorPhone?.message}
                    input={
                      <InputPhoneNumber
                        id="employmenthistory-supervisorphone"
                        data-testid="employmenthistory-supervisorphone"
                        type="phone"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={value}
                        onChange={(number) => onChange(number)}
                      />
                    }
                  />
                )}
              />
            )}
          </FormSBSContainer>

          {jobData?.mayWeContactEnabled && (
            <InputGroup
              label={
                jobData.mayWeContactRequired
                  ? `${t(
                      "apply.employmenthistory.form.contactsupervisor.label",
                    )}*`
                  : t("apply.employmenthistory.form.contactsupervisor.label")
              }
              error={errors.canContactSupervisor?.message}
              input={
                <RadioButtonGroup
                  id="employmenthistory-cancontactsupervisor"
                  data-testid="employmenthistory-cancontactsupervisor"
                  name="canContactSupervisor"
                  options={[
                    {
                      label: t("form.yes"),
                      value: 1,
                    },
                    { label: t("form.no"), value: 2 },
                  ]}
                  defaultValue={defaultValue?.canContactSupervisor}
                  onChange={onChangeCanContactSupervisor}
                />
              }
            />
          )}

          {jobData?.mayWeContactEnabled && cannotContactSupervisor && (
            <InputGroup
              label={t(
                "apply.employmenthistory.form.contactsupervisorreason.label",
              )}
              error={errors.contactSupervisorReason?.message}
              input={
                <Textarea
                  id="employmenthistory-contactsupervisorreason"
                  data-testid="employmenthistory-contactsupervisorreason"
                  placeholder={t(
                    "apply.employmenthistory.form.contactsupervisorreason.placeholder",
                  )}
                  maxLength={150}
                  rows={contactSupervisorReasonRows}
                  {...register("contactSupervisorReason", {
                    required: jobData.mayWeContactRequired
                      ? t("validation.required")
                      : false,
                    max: {
                      value: 150,
                      message: t("validation.max"),
                    },
                  })}
                />
              }
            />
          )}
        </FormContainer>
        <HorizontalRuleFooterStyled />
        <FooterButtonGroupDiv>
          <Button
            type="submit"
            variant={ButtonVariant.PRIMARY}
            buttonSize={ButtonSize.LARGE}
            data-testid="employmenthistory-submitbtn"
          >
            {isEditing
              ? t("apply.modalupdatebutton")
              : t("apply.modalsavebutton")}
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.SUBTLE}
            buttonType={ButtonType.MINIMAL}
            onClick={() => onRequestClose()}
            data-testid="employmenthistory-cancelbtn"
          >
            {t("apply.modalcancelbutton")}
          </Button>
        </FooterButtonGroupDiv>
      </form>
    </StyledDiv>
  );
};
