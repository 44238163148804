/* eslint-disable indent */
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import styled from "styled-components";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  ListItem,
  UnorderedList,
} from "@teamsoftware/reactcomponents";

import { JobDetails } from "components/JobDetail/JobDetails";

export type Company = {
  name: string;
  description: string;
};

export type Job = {
  description: string;
  requirements: string[];
  education: string;
  benefits: string;
};

export type JobDetailProps = {
  className?: string;
  jobDetails: JobDetails | null | undefined;
};

const zeroPaddingContainerString = 0 + "rem";

const zeroPaddingContainer: CSSProperties = {
  padding: zeroPaddingContainerString,
};

const unorderedListPaddingString = 1 + "rem";

const unorderedListPadding: CSSProperties = {
  paddingLeft: unorderedListPaddingString,
};

const DivContainerStyled = styled.div`
  flex: 1 1 auto;
`;

/* Since the user can format the job description, we need to revert some global CSS */
const StyledTypography = styled(Typography)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert !important;
  }
  ,
  p,
  li,
  td {
    & strong {
      font-weight: bold !important;
    }
    & em {
      font-style: italic !important;
    }
  }

  ol,
  ul {
    list-style: revert !important;
    padding: revert !important;
    margin: revert !important;
  }
`;

export const JobDetail = ({ jobDetails, className }: JobDetailProps) => {
  const { t } = useTranslation();

  const buildBenefitListItems = (jobDetails: JobDetails): ListItem[] => {
    let createdList: ListItem[] = jobDetails.benefits.map(
      (item) => ({ key: item.id, text: parse(item.name) }) as ListItem,
    );

    if (!!jobDetails.displayRelocation) {
      createdList.push({
        key: "relocationItem",
        text: t("jobdetail.relocation"),
        testId: "job-detail-relocation",
      });
    }

    return createdList;
  };

  const buildWorkShiftListItems = (jobDetails: JobDetails): ListItem[] => {
    let createdList: ListItem[] = [];

    if (!!jobDetails.shiftWorkDay) {
      createdList.push({
        key: "shift-day",
        text: t("jobdetail.shiftDay"),
      });
    }

    if (!!jobDetails.shiftSchoolHours) {
      createdList.push({
        key: "shift-school",
        text: t("jobdetail.shiftschool"),
      });
    }

    if (!!jobDetails.shiftWorkNight) {
      createdList.push({
        key: "shift-night",
        text: t("jobdetail.shiftNight"),
      });
    }

    if (!!jobDetails.shiftWorkThird) {
      createdList.push({
        key: "shift-third",
        text: t("jobdetail.shiftThird"),
      });
    }

    if (!!jobDetails.shiftWorkWeekend) {
      createdList.push({
        key: "shift-weekend",
        text: t("jobdetail.shiftweekend"),
      });
    }

    if (!!jobDetails.shiftWorkSummer) {
      createdList.push({
        key: "shift-summer",
        text: t("jobdetail.shiftsummer"),
      });
    }

    if (!!jobDetails.shiftWorkOther) {
      createdList.push({
        key: "shift-other",
        text: t("jobdetail.shiftother"),
      });
    }

    return createdList;
  };

  return (
    <DivContainerStyled data-testid="job-detail" className={className}>
      <Typography
        variant={TypographyVariant.H2}
        weight={FontWeight.Bold}
        color={Color.Default}
        data-testid="job-detail-overview"
      >
        {t("jobdetail.overview")}
      </Typography>
      {!!jobDetails &&
        !!jobDetails.companyName &&
        !!jobDetails.jobCompanyOverview && (
          <>
            <Typography
              variant={TypographyVariant.TextLarge}
              weight={FontWeight.SemiBold}
              color={Color.Subtle}
              data-testid="job-detail-company-description-header"
            >
              {t("jobdetail.aboutcompany", { company: jobDetails.companyName })}
            </Typography>
            <StyledTypography
              variant={TypographyVariant.P}
              weight={FontWeight.Regular}
              color={Color.Default}
              data-testid="job-detail-company-description"
              dangerouslySetInnerHTML={{
                __html: jobDetails.jobCompanyOverview,
              }}
            ></StyledTypography>
          </>
        )}
      {!!jobDetails && !!jobDetails.jobOverview && (
        <>
          <Typography
            variant={TypographyVariant.H3}
            weight={FontWeight.Bold}
            color={Color.Default}
            data-testid="job-detail-job-description-header"
          >
            {t("jobdetail.jobdetails")}
          </Typography>
          <StyledTypography
            variant={TypographyVariant.P}
            weight={FontWeight.Regular}
            color={Color.Default}
            data-testid="job-detail-job-description"
            dangerouslySetInnerHTML={{ __html: jobDetails.jobOverview }}
          ></StyledTypography>
        </>
      )}
      {!!jobDetails &&
        !!jobDetails.preferredCertifications &&
        jobDetails.preferredCertifications.length > 0 && (
          <>
            <Typography
              variant={TypographyVariant.H2}
              weight={FontWeight.Bold}
              color={Color.Default}
              data-testid="job-detail-certifications-header"
            >
              {t("jobdetail.certification")}
            </Typography>
            <UnorderedList
              style={unorderedListPadding}
              data-testid="job-detail-certifications"
              listItems={jobDetails.preferredCertifications.map(
                (item) =>
                  ({ key: item.id, text: parse(item.name) }) as ListItem,
              )}
            />
          </>
        )}
      {!!jobDetails &&
        !!jobDetails.preferredEducation &&
        jobDetails.preferredEducation.length > 0 && (
          <>
            <Typography
              variant={TypographyVariant.H2}
              weight={FontWeight.Bold}
              color={Color.Default}
              data-testid="job-detail-education-header"
            >
              {t("jobdetail.education")}
            </Typography>
            <UnorderedList
              style={unorderedListPadding}
              data-testid="job-detail-education"
              listItems={jobDetails.preferredEducation.map(
                (item) =>
                  ({ key: item.id, text: parse(item.name) }) as ListItem,
              )}
            />
          </>
        )}
      {!!jobDetails &&
        ((!!jobDetails.benefits && jobDetails.benefits.length > 0) ||
          (!!jobDetails.additionalInfoBenefits &&
            jobDetails.additionalInfoBenefits.length > 0)) && (
          <Typography
            variant={TypographyVariant.H2}
            weight={FontWeight.Bold}
            color={Color.Default}
            data-testid="job-detail-benefits-header"
          >
            {t("jobdetail.benefits")}
          </Typography>
        )}
      {!!jobDetails &&
        !!jobDetails.additionalInfoBenefits &&
        jobDetails.additionalInfoBenefits.length > 0 && (
          <StyledTypography
            variant={TypographyVariant.P}
            weight={FontWeight.Regular}
            color={Color.Default}
            data-testid="job-detail-additionalInfoBenefits"
            dangerouslySetInnerHTML={{
              __html: jobDetails.additionalInfoBenefits,
            }}
          ></StyledTypography>
        )}
      {!!jobDetails &&
        !!jobDetails.benefits &&
        jobDetails.benefits.length > 0 && (
          <UnorderedList
            style={unorderedListPadding}
            data-testid="job-detail-benefits"
            listItems={buildBenefitListItems(jobDetails)}
          />
        )}
      {!!jobDetails && !!jobDetails.displayReportsTo && (
        <Typography
          variant={TypographyVariant.P}
          weight={FontWeight.Regular}
          color={Color.Default}
          data-testid="job-detail-reportsTo"
        >
          {t("jobdetail.reportsTo")} {jobDetails.reportsTo}.
        </Typography>
      )}

      {!!jobDetails && !!jobDetails.displayTravel && (
        <Typography
          variant={TypographyVariant.P}
          weight={FontWeight.Regular}
          color={Color.Default}
          data-testid="job-detail-travel"
        >
          {t("jobdetail.travel")}
        </Typography>
      )}

      {!!jobDetails &&
        !!jobDetails.screenings &&
        jobDetails.screenings.length > 0 && (
          <div style={zeroPaddingContainer} data-testid="job-detail-screenings">
            <Typography
              variant={TypographyVariant.P}
              weight={FontWeight.Regular}
              color={Color.Default}
            >
              {t("jobdetail.screenings")}
            </Typography>

            <UnorderedList
              style={unorderedListPadding}
              listItems={jobDetails.screenings.map(
                (item) =>
                  ({
                    key: item.id,
                    text: parse(item.name),
                    description: item.description,
                  }) as ListItem,
              )}
            />
          </div>
        )}

      {!!jobDetails && !!jobDetails.displayWorkShifts && (
        <div style={zeroPaddingContainer}>
          <Typography
            variant={TypographyVariant.P}
            weight={FontWeight.Regular}
            color={Color.Default}
            data-testid="job-detail-shifts-description"
          >
            {t("jobdetail.shifts")}
          </Typography>

          <UnorderedList
            style={unorderedListPadding}
            data-testid="job-detail-shifts"
            listItems={buildWorkShiftListItems(jobDetails)}
          />
        </div>
      )}
    </DivContainerStyled>
  );
};
