import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Mixins,
  InputText,
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  HorizontalRule,
  Loading,
  InputGroup,
  InputSelect,
  SelectOption,
  Checkbox,
  StepperFooter,
  useStepper,
  FilePicker,
  InputPhoneNumber,
} from "@teamsoftware/reactcomponents";
import {
  useForm,
  NestedValue,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { up, down } from "styled-breakpoints";

import { useAPIGetCountries } from "api/location/useAPIGetCountries";
import { useAPIGetTerritories } from "api/location/useAPIGetTerritories";
import { ApplyStepHeader } from "components/ApplyStepHeader";
import { ApplyStepSubHeader } from "components/ApplyStepSubHeader";
import { Country } from "locale/models/Country";
import { Territory } from "locale/models/Territory";
import { useAPIGetConsentSeparate } from "api/client-features/useAPIGetConsentSeparate";

const StyledDiv = styled.div`
  margin-bottom: 5rem;
  padding: 0 1rem;
`;

const MarginTypography = styled(Typography)`
  margin-top: 2rem;
`;

const BottomHorizontalRule = styled(HorizontalRule)`
  margin-top: 5rem;
`;

const GridCol = styled.div`
  ${Mixins.Flex.column({ gap: 1.5 })}
`;

const GridRow = styled.div`
  ${up("md")} {
    ${Mixins.Flex.row({ gap: 1.5 })}
  }

  ${down("sm")} {
    ${Mixins.Flex.column({ gap: 1.5 })}
  }
`;

const InputGroupStyled = styled(InputGroup)`
  margin-top: 1rem;
`;

const StyledInpurGroup = styled(InputGroup)`
  ${Mixins.Flex.spacer}
`;

const TermsTextContainer = styled.div`
  display: inline;
`;

const TermsText = styled(Typography)`
  display: inline;
`;

const LegalPageUrl = process.env.REACT_APP_LEGAL_PAGE_URL;
const FilePickerAcceptedFileTypes = ".pdf, .docx, .doc, .txt, .rtf";
const USA_COUNTRY_ID: string = "cca99d61-99ef-479a-8ab2-874fb3018375";
const CANADA_COUNTRY_ID: string = "329f3729-8412-43b7-8c40-02210f74583c";
const spaceValidationRegex: RegExp = /^[^-\s][\w\s-]+$/;
const postalCodeValidationRegex: RegExp = /^(?=.{0,20}$)([-'\w]+\s*)+$/;

export type ApplyMyInfoFormData = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  country: Country | NestedValue<Country> | undefined;
  territory: Territory | NestedValue<Territory> | string | undefined;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  tos: boolean;
  ppl: boolean;
  resumeFile?: File;
};

export type ApplyMyInfoNestedFormData = {
  firstName: string;
  lastName: string;
  email: string;
  country: Country | NestedValue<Country> | undefined; // Nested lets us utilize this as a settable complex object (with error messaging)
  territory: Territory | NestedValue<Territory> | string | undefined; // Nested lets us utilize this as a settable complex object (with error messaging)
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  tos: boolean;
  ppl: boolean;
  resumeFile?: File;
};

export type ApplyMyInfoProps = {
  defaultValue?: ApplyMyInfoFormData;
  hasFormData: boolean;
  disabled?: boolean;
  onSubmit: (data: ApplyMyInfoFormData) => void;
};

interface DataFormInterface {
  dataForm: ApplyMyInfoNestedFormData;
}

export const ApplyMyInfo = ({
  defaultValue,
  hasFormData,
  onSubmit,
  disabled,
}: ApplyMyInfoProps) => {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
  } = useForm<DataFormInterface>({
    defaultValues: { dataForm: defaultValue },
  });

  const { dispatch } = useStepper();

  const { isLoading: countriesLoading, data: countryData } =
    useAPIGetCountries();

  const { isLoading: territoriesLoading, data: territoryData } =
    useAPIGetTerritories();

  const { isLoading: separateConsentLoading, data: hasConsentSeparate } =
    useAPIGetConsentSeparate();

  let territoryOptions: SelectOption<Territory>[] = [];

  const onFormSubmit: SubmitHandler<DataFormInterface> = (data) => {
    onSubmit(data.dataForm);
    dispatch({ type: "setStepNext" });
  };

  const [valuesToUse, setValuesToUse] = useState<
    ApplyMyInfoFormData | undefined
  >({
    firstName: "",
    lastName: "",
    email: "",
    country: { id: "", name: "" },
    territory: "",
    address: "",
    city: "",
    postalCode: "",
    phone: "",
    tos: false,
    ppl: false,
  });

  useEffect(() => {
    setValue("dataForm", {
      firstName:
        defaultValue?.firstName === undefined ? "" : defaultValue?.firstName,
      lastName:
        defaultValue?.lastName === undefined ? "" : defaultValue?.lastName,
      email: defaultValue?.email === undefined ? "" : defaultValue?.email,
      country:
        defaultValue?.country === undefined
          ? { id: "", name: "" }
          : defaultValue?.country,
      territory:
        defaultValue?.territory === undefined ? "" : defaultValue?.territory,
      address: defaultValue?.address === undefined ? "" : defaultValue?.address,
      city: defaultValue?.city === undefined ? "" : defaultValue?.city,
      postalCode:
        defaultValue?.postalCode === undefined ? "" : defaultValue?.postalCode,
      phone: defaultValue?.phone === undefined ? "" : defaultValue?.phone,
      tos: defaultValue?.tos === undefined ? false : defaultValue?.tos,
      ppl: defaultValue?.ppl === undefined ? false : defaultValue?.ppl,
    } as ApplyMyInfoNestedFormData);
  }, [defaultValue]);

  useEffect(() => {
    register("dataForm.country", {
      required: t("validation.required"),
    });
    register("dataForm.territory", {
      required: t("validation.required"),
    });
  }, [register]);

  // Sync form dirty state to stepper
  useEffect(() => {
    dispatch({ type: "setDirty", payload: isDirty });
  }, [dispatch, isDirty]);

  const onChangeCountry = (values: SelectOption<Country>[]) => {
    setValue("dataForm.territory", "");
    setValue(
      "dataForm.country",
      values?.find((v) => v.isSelected === true)?.value
    );
  };

  const onChangeTerritory = (values: SelectOption<Territory>[]) => {
    setValue(
      "dataForm.territory",
      values?.find((v) => v.isSelected === true)?.value
    );
  };

  const removeCountrySelectOption = useCallback(
    (
      optionsArray: SelectOption<Country>[],
      countryIdToRemove: string
    ): SelectOption<Country> | undefined => {
      for (let i: number = 0; i < optionsArray.length; i++) {
        if (optionsArray[i].key === countryIdToRemove) {
          return optionsArray.splice(i, 1)[0];
        }
      }

      return undefined;
    },
    []
  );

  const generateCountryOptions = useCallback(() => {
    let options: SelectOption<Country>[] = [];

    // Set country options
    if (!!countryData && countryData.length > 0) {
      options = countryData.map((country) => {
        return {
          key: country.id,
          label: country.name,
          value: country,
          isSelected: false,
        } as SelectOption<Country>;
      });

      // Put USA and Canada at the top of the list
      let countryDataUsa = removeCountrySelectOption(options, USA_COUNTRY_ID);
      let countryDataCanada = removeCountrySelectOption(
        options,
        CANADA_COUNTRY_ID
      );

      if (undefined !== countryDataUsa && undefined !== countryDataCanada) {
        options.unshift(countryDataCanada);
        options.unshift(countryDataUsa);
      }
    }

    return options;
  }, [countryData, removeCountrySelectOption]);

  const countryOptions: SelectOption<Country>[] = useMemo(
    () => generateCountryOptions(),
    [generateCountryOptions]
  );

  // Selected country vars
  const selectedCountry = watch("dataForm.country");
  const selectedCountryId: string | undefined = selectedCountry?.id;

  // Set territory options
  if (!!territoryData) {
    if (!selectedCountry) {
      territoryOptions = [];
    } else {
      territoryOptions = territoryData
        .map<SelectOption<Territory>>((territory) => {
          return {
            key: territory.id,
            label: territory.name,
            value: territory,
            isSelected: false,
          };
        })
        .filter(
          (territory) => territory.value?.countryId === selectedCountryId
        );
    }
  }

  let selectedCountryHasTerritoryOptions: boolean = territoryOptions.length > 0;

  let isLoading =
    countriesLoading ||
    territoriesLoading ||
    separateConsentLoading ||
    (hasFormData && typeof defaultValue === "undefined");

  const defaultTerritoryID =
    typeof defaultValue?.territory === "string"
      ? undefined
      : defaultValue?.territory?.id;
  const defaultTerritorySelection: SelectOption<Territory> | undefined =
    defaultTerritoryID === undefined
      ? undefined
      : territoryOptions?.find((o) => o.value?.id === defaultTerritoryID);

  const defaultCountryID =
    typeof defaultValue?.country === "string"
      ? undefined
      : defaultValue?.country?.id;
  const defaultCountrySelection: SelectOption<Country> | undefined =
    defaultCountryID === undefined
      ? undefined
      : countryOptions?.find((o) => o.value?.id === defaultCountryID);

  return (
    <>
      <Loading isLoading={isLoading} />

      {!isLoading && (
        <StyledDiv data-testid="apply-my-info">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <ApplyStepHeader
              title={t("apply.myinfo.header")}
              description={t("apply.myinfo.description")}
            />
            <ApplyStepSubHeader
              title={t("apply.myinfo.applicantinformation.header")}
            />
            <GridCol>
              <GridRow>
                <StyledInpurGroup
                  label={`${t("form.firstname")} *`}
                  error={errors.dataForm?.firstName?.message}
                  input={
                    <InputText
                      defaultValue={valuesToUse?.firstName}
                      id="first-name"
                      fullWidth
                      autoFocus={true}
                      {...register("dataForm.firstName", {
                        required: t("validation.required"),
                      })}
                    />
                  }
                />
                <StyledInpurGroup
                  label={`${t("form.lastname")} *`}
                  error={errors.dataForm?.lastName?.message}
                  input={
                    <InputText
                      defaultValue={valuesToUse?.lastName}
                      id="last-name"
                      fullWidth
                      {...register("dataForm.lastName", {
                        required: t("validation.required"),
                      })}
                    />
                  }
                />
              </GridRow>

              <GridRow>
                <Controller
                  control={control}
                  name="dataForm.phone"
                  defaultValue={valuesToUse?.phone}
                  rules={{
                    required: t("validation.required"),
                    pattern: {
                      value: /^[\+\(\s.\-\/\d\)]{9,25}$/,
                      message: t("validation.phone"),
                    },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledInpurGroup
                        label={<>{t("form.phone")} * </>}
                        error={errors.dataForm?.phone?.message}
                        input={
                          <InputPhoneNumber
                            id="phone"
                            type="phone"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            value={value}
                            onChange={(number: any) => onChange(number)}
                          />
                        }
                      />
                    );
                  }}
                />
              </GridRow>

              <InputGroup
                label={`${t("form.country")} *`}
                error={errors.dataForm?.country?.message}
                input={
                  <InputSelect
                    id="country"
                    aria-labelledby="country-label"
                    placeholder={`${t("form.countryPlaceholder")}`}
                    options={countryOptions ?? []}
                    isSearchable={true}
                    onOptionChange={onChangeCountry}
                    defaultSelection={defaultCountrySelection}
                  />
                }
              />

              <InputGroup
                label={`${t("form.address")} *`}
                error={errors.dataForm?.address?.message}
                input={
                  <InputText
                    defaultValue={valuesToUse?.address}
                    id="address"
                    fullWidth
                    {...register("dataForm.address", {
                      required: t("validation.required"),
                      pattern: {
                        value: spaceValidationRegex,
                        message: t("validation.address"),
                      },
                    })}
                  />
                }
              />

              <GridRow>
                <StyledInpurGroup
                  label={`${t("form.city")} *`}
                  error={errors.dataForm?.city?.message}
                  input={
                    <InputText
                      defaultValue={valuesToUse?.city}
                      id="city"
                      fullWidth
                      {...register("dataForm.city", {
                        required: t("validation.required"),
                        pattern: {
                          value: spaceValidationRegex,
                          message: t("validation.city"),
                        },
                      })}
                    />
                  }
                />

                {selectedCountryId === USA_COUNTRY_ID && (
                  <StyledInpurGroup
                    label={`${t("form.state")} *`}
                    error={errors.dataForm?.territory?.message}
                    input={
                      <InputSelect
                        id="state"
                        aria-labelledby="state-label"
                        placeholder={`${t("form.statePlaceholder")}`}
                        options={territoryOptions ?? []}
                        isSearchable={true}
                        onOptionChange={onChangeTerritory}
                        defaultSelection={defaultTerritorySelection}
                      />
                    }
                  />
                )}
                {selectedCountryId === CANADA_COUNTRY_ID && (
                  <StyledInpurGroup
                    label={`${t("form.province")} *`}
                    error={errors.dataForm?.territory?.message}
                    input={
                      <InputSelect
                        id="province"
                        aria-labelledby="province-label"
                        placeholder={`${t("form.provincePlaceholder")}`}
                        options={territoryOptions ?? []}
                        isSearchable={true}
                        onOptionChange={onChangeTerritory}
                        defaultSelection={defaultTerritorySelection}
                      />
                    }
                  />
                )}
                {selectedCountryId !== USA_COUNTRY_ID &&
                  selectedCountryId !== CANADA_COUNTRY_ID &&
                  selectedCountryHasTerritoryOptions && (
                    <StyledInpurGroup
                      label={`${t("form.territory")} *`}
                      error={errors.dataForm?.territory?.message}
                      input={
                        <InputSelect
                          key={
                            selectedCountryId
                          } /* Workaround for InputSelect component bug where option list does not update on options prop change */
                          id="territory"
                          aria-labelledby="territory-label"
                          placeholder={`${t("form.territoryPlaceholder")}`}
                          options={territoryOptions ?? []}
                          isSearchable={true}
                          onOptionChange={onChangeTerritory}
                          defaultSelection={defaultTerritorySelection}
                        />
                      }
                    />
                  )}
                {selectedCountryId !== USA_COUNTRY_ID &&
                  selectedCountryId !== CANADA_COUNTRY_ID &&
                  !selectedCountryHasTerritoryOptions && (
                    <StyledInpurGroup
                      label={`${t("form.territory")} *`}
                      error={errors.dataForm?.territory?.message}
                      input={
                        <InputText
                          id="territory"
                          defaultValue={
                            (valuesToUse?.territory ?? "") as string
                          }
                          fullWidth
                          {...register("dataForm.territory", {
                            required: t("validation.required"),
                          })}
                        />
                      }
                    />
                  )}

                <StyledInpurGroup
                  label={`${t("form.postalCode")} *`}
                  error={errors.dataForm?.postalCode?.message}
                  input={
                    <InputText
                      defaultValue={valuesToUse?.postalCode}
                      id="postal-code"
                      fullWidth
                      {...register("dataForm.postalCode", {
                        required: t("validation.required"),
                        pattern: {
                          value: postalCodeValidationRegex,
                          message: t("validation.postalCode"),
                        },
                      })}
                    />
                  }
                />
              </GridRow>
            </GridCol>
            <ApplyStepSubHeader title={t("apply.myinfo.uploadResume.header")} />
            <FilePicker
              acceptedFileTypes={FilePickerAcceptedFileTypes}
              initialFiles={
                defaultValue?.resumeFile === undefined
                  ? undefined
                  : [defaultValue.resumeFile]
              }
              maxFiles={1}
              maxFilesize={10485760}
              onFilesUpdate={(uploadedFiles: File[]) => {
                if (uploadedFiles.length > 0) {
                  setValue("dataForm.resumeFile", uploadedFiles[0]);
                } else {
                  setValue("dataForm.resumeFile", undefined);
                }
              }}
            />
            <MarginTypography
              variant={TypographyVariant.H4}
              weight={FontWeight.SemiBold}
              color={Color.Subtle}
            >
              {t("apply.termsandconditions.header")}
            </MarginTypography>

            <TermsTextContainer>
              <TermsText
                variant={TypographyVariant.TextLarge}
                weight={FontWeight.Regular}
                color={Color.Subtle}
              >
                {t("apply.termsandconditions.text1")}{" "}
              </TermsText>

              {hasConsentSeparate === true && (
                <TermsText
                  variant={TypographyVariant.TextLarge}
                  weight={FontWeight.Regular}
                  color={Color.Subtle}
                >
                  {t("apply.termsandconditions.text1checkboxplural")}{" "}
                </TermsText>
              )}
              {hasConsentSeparate === false && (
                <TermsText
                  variant={TypographyVariant.TextLarge}
                  weight={FontWeight.Regular}
                  color={Color.Subtle}
                >
                  {t("apply.termsandconditions.text1checkbox")}{" "}
                </TermsText>
              )}

              <TermsText
                variant={TypographyVariant.TextLarge}
                weight={FontWeight.Regular}
                color={Color.Subtle}
              >
                {t("apply.termsandconditions.text2")}{" "}
              </TermsText>

              <TermsText
                variant={TypographyVariant.TextLarge}
                weight={FontWeight.Bold}
                color={Color.Subtle}
              >
                <a href={LegalPageUrl} target="_blank" rel="noreferrer">
                  {t("apply.termsandconditions.link")}
                </a>
              </TermsText>
            </TermsTextContainer>

            {hasConsentSeparate === true && (
              <InputGroupStyled
                label={t("apply.termsandconditions.privacylabel", {
                  termsAndConditionslink: t(
                    "apply.termsandconditions.privacylink"
                  ),
                })}
                data-testid="ppl-checkbox"
                error={errors.dataForm?.ppl?.message}
                input={
                  <Checkbox
                    id="ppl-checkbox"
                    data-testid="ppl-checkbox"
                    {...register("dataForm.ppl", {
                      required: t("validation.required"),
                    })}
                  />
                }
              />
            )}
            <InputGroupStyled
              label={t("apply.termsandconditions.label", {
                termsAndConditionslink: t("apply.termsandconditions.link"),
              })}
              data-testid="tos-checkbox"
              error={errors.dataForm?.tos?.message}
              input={
                <Checkbox
                  id="tos-checkbox"
                  data-testid="tos-checkbox"
                  {...register("dataForm.tos", {
                    required: t("validation.required"),
                  })}
                />
              }
            />

            <BottomHorizontalRule />

            <StepperFooter submitOnNext={true} disabled={disabled} />
          </form>
        </StyledDiv>
      )}
    </>
  );
};
