import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { up, down } from "styled-breakpoints";
import {
  Typography,
  TypographyVariant,
  FontWeight,
  Color,
  Loading,
  Link,
  Mixins,
  Stepper,
  Step,
} from "@teamsoftware/reactcomponents";
import { AxiosError } from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Header } from "components/Header";
import { ApplyMyInfo, ApplyMyInfoFormData } from "components/ApplyMyInfo";
import {
  ApplicationQuestions,
  ApplyQuestionData,
} from "components/ApplicationQuestions";
import { BackLink } from "components/BackLink";
import { useAPIJobDetails } from "api/job-posting/useAPIJobDetails";
import { useAPISaveProgress } from "api/apply/useAPISaveProgress";
import {
  useAPILoadProgress,
  LoadProgressRequest,
} from "api/apply/useAPILoadProgress";
import { useAPISubmitApplicant } from "api/apply/useAPISubmitApplicant";
import {
  useAPIGetCountryAndTerritoryId,
  CountryTerritoryRequest,
} from "api/location/useAPIGetCountryAndTerritoryId";
import { StandardApplicantPlusCaptcha } from "models/StandardApplicant";
import * as StandardApplicantTypes from "@kwantek/standard_applicant";

import {
  ApplyExperienceAndEducation,
  ApplyExperienceAndEducationData,
} from "components/ApplyExperienceAndEducation";
import { ApplyReview, ApplyReviewFormData } from "components/ApplyReview";
import { ReactSession } from "react-client-session";
import { Country } from "locale/models/Country";
import { Territory } from "locale/models/Territory";
import { ApplyEmploymentHistoryFormData } from "components/ApplyExperienceAndEducation/components/ApplyEmploymentHistory";
import { ApplyEducationHistoryFormData } from "components/ApplyExperienceAndEducation/components/ApplyEducationHistory";
import { ApplyCertificationHistoryFormData } from "components/ApplyExperienceAndEducation/components/ApplyCertificationHistory";
import { MyTagManager, bodyTags } from "context/trackingTagsContext";
import { useAPISetAsCompleted } from "api/apply/useAPISetAsCompleted";
import { ApplicationInfo } from "models/ApplicationInfo";
export type ApplicationPageProps = {
  id: string;
};

const STEP_MYINFO: number = 0;
const STEP_EXPERIENCE: number = 1;
const STEP_QUESTIONS: number = 2;
const STEP_REVIEW: number = 3;
const USA_COUNTRY_ID: string = "cca99d61-99ef-479a-8ab2-874fb3018375";
const CANADA_COUNTRY_ID: string = "329f3729-8412-43b7-8c40-02210f74583c";

const DivStyled = styled.div`
  ${up("md")} {
    ${Mixins.Flex.row()}
  }
  ${down("sm")} {
    ${Mixins.Flex.column()}
  }
`;

const MobilePaddingTypography = styled(Typography)`
  ${down("sm")} {
    padding: 0.8rem;
  }
`;

const JobInfoTypography = styled(MobilePaddingTypography)`
  color: ${(props) => props.theme.Text.Ghosted};
  ${Mixins.Flex.row()}
`;

export type ApplicationFormData = {
  myInfoData: ApplyMyInfoFormData;
  experienceData: ApplyExperienceAndEducationData;
  questionData: ApplyQuestionData[];
};

export const ApplicationPage: React.FC = () => {
  let { id }: any = useParams();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();
  const applyPanelEmail: string = location.state as string;
  const [isSavingApplicant, setIsSavingApplicant] = useState<boolean>(false);
  const [isSavingProgress, setIsSavingProgress] = useState<boolean>(false);
  const [isSavingCompletion, setIsSavingCompletion] = useState<boolean>(false);
  const CANNOT_CONTACT = 2;
  const CAN_CONTACT = 1;
  const [xJobId, setXJobId] = useState<string>();
  const [myInfoData, setMyInfoData] = useState<ApplyMyInfoFormData>();
  const [experienceData, setExperienceData] =
    useState<ApplyExperienceAndEducationData>();
  const [reviewData, setReviewData] = useState<ApplyReviewFormData>();
  const [questionData, setQuestionData] = useState<ApplyQuestionData[]>();

  const [isFullApplicationCompleted, setIsFullApplicationCompleted] =
    useState<boolean>(false);

  let countryId = "";
  let territoryId = "";
  let claimString =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";

  const { isLoading, data } = useAPIJobDetails(id);

  let token = ReactSession.get("userToken");
  if (token !== undefined) {
    let decoded = JSON.parse(atob(token.split(".")[1]));
    var authenticatedEmail = decoded[claimString];
  }
  const loadProgressRequest: LoadProgressRequest = {
    email: authenticatedEmail,
    jobId: data?.externalId,
  };

  const { isLoading: appProgressLoading, data: appProgressData } =
    useAPILoadProgress(loadProgressRequest);

  const useAPISaveProgress_Success = (
    data: any,
    applicant: StandardApplicantPlusCaptcha,
  ) => {
    setIsSavingProgress(false);
    if (isFullApplicationCompleted) {
      navigate(`/confirmation/${id}`);
    }
  };

  const useAPISaveProgress_Failed = (
    error: AxiosError,
    applicant: StandardApplicantPlusCaptcha,
  ) => {
    setIsSavingProgress(false);
    if (isFullApplicationCompleted) {
      alert(error?.response?.data ?? t("apply.errorsaving"));
      setIsFullApplicationCompleted(false);
    }
    console.log(error);
  };

  const { mutate: mutateProgress } = useAPISaveProgress(
    useAPISaveProgress_Success,
    useAPISaveProgress_Failed,
  );

  const useAPISubmitApplicant_Success = (
    data: any,
    applicant: StandardApplicantPlusCaptcha,
  ) => {
    setIsSavingApplicant(false);
    if (isFullApplicationCompleted) {
      navigate(`/confirmation/${id}`);
    }
  };

  const useAPISubmitApplicant_Failed = (
    error: AxiosError,
    applicant: StandardApplicantPlusCaptcha,
  ) => {
    setIsSavingApplicant(false);
    if (isFullApplicationCompleted) {
      alert(error?.response?.data ?? t("apply.errorsaving"));
      setIsFullApplicationCompleted(false);
    }
    console.log(error);
  };

  const { mutate } = useAPISubmitApplicant(
    useAPISubmitApplicant_Success,
    useAPISubmitApplicant_Failed,
  );

  const mutateGetCountryAndTerritoryId_Success = (data: any) => {
    countryId = data.data["country_id"];
    territoryId = data.data["territory_id"];
  };

  const mutateGetCountryAndTerritoryId_Failed = (error: AxiosError) => {};

  const { mutateAsync: mutateGetCountryAndTerritoryId } =
    useAPIGetCountryAndTerritoryId(
      mutateGetCountryAndTerritoryId_Success,
      mutateGetCountryAndTerritoryId_Failed,
    );

  const applicationInfo: ApplicationInfo = {
    jobId: data?.externalId,
    email: authenticatedEmail ? authenticatedEmail : applyPanelEmail,
  };

  const useAPISetAsCompleted_Success = (
    data: any,
    applicationInfo: ApplicationInfo,
  ) => {
    setIsSavingCompletion(false);
    if (isFullApplicationCompleted) {
      navigate(`/confirmation/${id}`);
    }
  };

  const useAPISetAsCompleted_Failed = (
    error: AxiosError,
    applicationInfo: ApplicationInfo,
  ) => {
    setIsSavingCompletion(false);
    if (isFullApplicationCompleted) {
      alert(error?.response?.data ?? t("apply.errorsaving"));
      setIsFullApplicationCompleted(false);
    }
    console.log(error);
  };

  const { mutate: mutateCompletion } = useAPISetAsCompleted(
    useAPISetAsCompleted_Success,
    useAPISetAsCompleted_Failed,
  );

  const onSubmitMyInfo = (data: ApplyMyInfoFormData) => {
    if (!data.tos) return;
    data.email =
      authenticatedEmail !== undefined
        ? authenticatedEmail
        : (applyPanelEmail as string);
    setMyInfoData(data);
    setIsSavingApplicant(true); // Persist the data to server
    setIsSavingProgress(true);
    MyTagManager.dataLayer({
      dataLayer: {
        event: "Submitted personal information",
      },
    });
  };

  const onSubmitApplicationQuestions = (data: ApplyQuestionData[]) => {
    // Clean up empty followup questions from react-hook-form
    let formattedQuestionData: ApplyQuestionData[] = [];

    for (let i: number = 0; i < data.length; i++) {
      if (
        data[i].isFollowup &&
        data[i].answers.length === 1 &&
        (data[i].answers[0].answerText === "" ||
          data[i].answers[0].answerText === undefined)
      ) {
        continue;
      }

      formattedQuestionData.push(data[i]);
    }

    setQuestionData(formattedQuestionData);
    setIsSavingProgress(true);
    MyTagManager.dataLayer({
      dataLayer: {
        event: "Submitted application questions",
      },
    });
  };

  const onSubmitExperience = (data: ApplyExperienceAndEducationData) => {
    setExperienceData(data);
    setIsSavingProgress(true);
    MyTagManager.dataLayer({
      dataLayer: {
        event: "Submitted experience",
      },
    });
  };

  const onSubmitReview = (data: ApplyReviewFormData) => {
    setReviewData(data);
    setIsFullApplicationCompleted(true);
    setIsSavingApplicant(true);
    setIsSavingCompletion(true);
    MyTagManager.dataLayer({
      dataLayer: {
        event: "Application completed",
      },
    });
  };

  const applicationFormData: ApplicationFormData = {
    myInfoData: myInfoData ?? {
      firstName: "",
      lastName: "",
      email: "",
      country: undefined,
      territory: undefined,
      address: "",
      city: "",
      postalCode: "",
      phone: "",
      tos: false,
      ppl: false,
    },
    experienceData: experienceData ?? {
      employmentHistory: [],
      educationHistory: [],
      certificationHistory: [],
    },
    questionData: questionData ?? [],
  };

  const convertLocationString = (location: string) => {
    if (location !== undefined) {
      const splitLocation = location.split(",");
      const hasPlace =
        !!splitLocation &&
        Array.isArray(splitLocation) &&
        splitLocation.length > 0;
      const hasTerritory = hasPlace && splitLocation.length > 1;
      return [
        hasPlace ? splitLocation[0].trim() : location,
        hasTerritory ? splitLocation[1].trim() : undefined,
      ];
    } else {
      return [undefined, undefined];
    }
  };

  const readFileAsDataURLAsync = (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string" || reader.result === null)
          resolve(reader.result);
        else reject("Unknown type");
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  // Gather form data and submit
  const mutateApplicant = async (isSubmitting: boolean) => {
    if (!executeRecaptcha) {
      alert("Unable to submit your application, please try again in a moment."); // Should never happen
      return;
    }

    // Validate bare minimum required fields
    if (!xJobId) {
      alert("Could not find job. Please try again later.");
      return;
    }
    if (!myInfoData?.email) {
      alert("Could not find email. Please try again later.");
      return;
    }

    const recaptchaToken = await executeRecaptcha("SubmitApplication");

    const employmentHistoryForApplicant =
      experienceData?.employmentHistory.map<StandardApplicantTypes.EmploymentHistoryData>(
        (item) => {
          const [place, territory] = convertLocationString(item.place);
          return {
            jobTitle: item.jobTitle,
            companyName: item.companyName,
            roleDescription: item.roleDescription,
            place: place,
            territory: territory,
            startDate: item.startDate,
            endDate: item.endDate,
            startingPay: item.startingPay,
            endingPay: item.endingPay,
            supervisorName: item.supervisorName,
            supervisorPhone: item.supervisorPhone,
            canContactSupervisor: item.canContactSupervisor === 1,
            contactSupervisorReason: item.contactSupervisorReason,
            stillWorkHere: item.stillWorkHere,
          };
        },
      );

    const educationHistoryForApplicant =
      experienceData?.educationHistory.map<StandardApplicantTypes.EducationHistoryData>(
        (item) => {
          const [place, territory] = convertLocationString(item.place);
          return {
            school: item.school,
            degree: item.degree,
            place: place,
            territory: territory,
            graduation: item.graduation,
            isCurrentlyEnrolled: item.currentlyEnrolled,
            didNotGraduate: item.didNotGraduate,
          };
        },
      );

    const certificationHistoryForApplicant =
      experienceData?.certificationHistory.map<StandardApplicantTypes.CertificationHistoryData>(
        (item) => ({
          name: item.name,
          license: item.license,
          issuer: item.issuer,
          issued: item.issued,
          isInProgress: item.inProgress,
        }),
      );

    const questionAnswersForApplicant =
      questionData?.map<StandardApplicantTypes.QuestionData>((item) => ({
        id: item.externalQuestionId,
        text: item.questionText,
        explain: undefined,
        section: {
          id: item.section.externalId,
          name: item.section.name,
        },
        answers:
          item.answers.map<StandardApplicantTypes.QuestionData_QuestionAnswer>(
            (itemb) => ({
              id: itemb.externalAnswerId,
              text: itemb.answerText,
            }),
          ),
      }));

    // Base64 encode resume, if applicable
    let resumeFileBase64: string | undefined;
    if (myInfoData.resumeFile !== undefined) {
      const fileDataURL = await readFileAsDataURLAsync(myInfoData.resumeFile);
      resumeFileBase64 = fileDataURL?.replace("data:", "").replace(/^.+,/, "");
    }

    const applicant: StandardApplicantPlusCaptcha = {
      schemaVersion: StandardApplicantTypes.schemaVersion,
      jobId: xJobId,
      firstName: myInfoData?.firstName,
      lastName: myInfoData?.lastName,
      email: myInfoData?.email,
      country: myInfoData?.country?.name,
      territory:
        typeof myInfoData?.territory === "string"
          ? myInfoData?.territory
          : myInfoData?.territory?.name,
      address1: myInfoData?.address,
      place: myInfoData?.city,
      postalCode: myInfoData?.postalCode,
      phoneNumber1: myInfoData?.phone,
      questionAnswers:
        isFullApplicationCompleted || !isSubmitting
          ? questionAnswersForApplicant
          : undefined,
      employmentHistory:
        isFullApplicationCompleted || !isSubmitting
          ? employmentHistoryForApplicant ?? []
          : [],
      educationHistory:
        isFullApplicationCompleted || !isSubmitting
          ? educationHistoryForApplicant ?? []
          : [],
      certificationHistory:
        isFullApplicationCompleted || !isSubmitting
          ? certificationHistoryForApplicant ?? []
          : [],
      recaptchaToken: recaptchaToken,
      resumeFileData: resumeFileBase64,
      resumeFileName: myInfoData?.resumeFile?.name,
      kwantekResumeID: undefined,
    };
    if (isSubmitting == true) {
      mutate(applicant);
    } else {
      mutateProgress(applicant);
    }
  };

  const convertStandardApplicant = (
    applicant: StandardApplicantTypes.StandardApplicant,
  ) => {
    let locationRequest: CountryTerritoryRequest = {
      countryName: applicant.country as string,
      territoryName: applicant.territory as string,
    };
    return mutateGetCountryAndTerritoryId(locationRequest).then(() => {
      if (countryId !== USA_COUNTRY_ID && countryId !== CANADA_COUNTRY_ID) {
        territoryId = applicant.territory as string;
      }
      let applicantTerritory: Territory = {
        id: territoryId,
        countryId: countryId,
        abbreviation: "",
        name: territoryId,
      };
      let applicantCountry: Country = {
        id: countryId as string,
        name: applicant.country as string,
      };

      let employmentHistoryAppData: ApplyEmploymentHistoryFormData[] = [];
      let educationHistoryAppData: ApplyEducationHistoryFormData[] = [];
      let certHistoryAppData: ApplyCertificationHistoryFormData[] = [];
      let empIndex = 0;
      let educIndex = 0;
      let certIndex = 0;

      applicant.employmentHistory?.forEach(function (data) {
        let empHistory: ApplyEmploymentHistoryFormData = {
          id: empIndex.toString(),
          jobTitle: data.jobTitle,
          companyName: data.companyName,
          startDate: data.startDate,
          endDate: data.endDate,
          place: data.place as string,
          roleDescription: data.roleDescription as string,
          endingPay: data.endingPay,
          startingPay: data.startingPay,
          supervisorName: data.supervisorName,
          supervisorPhone: data.supervisorPhone,
          canContactSupervisor:
            data.canContactSupervisor === false ? CANNOT_CONTACT : CAN_CONTACT,
          contactSupervisorReason: data.contactSupervisorReason,
          stillWorkHere: data.stillWorkHere as boolean,
        };
        empIndex++;
        employmentHistoryAppData.push(empHistory);
      });

      applicant.educationHistory?.forEach(function (data) {
        let educationHistory: ApplyEducationHistoryFormData = {
          id: educIndex.toString(),
          school: data.school,
          degree: data.degree as string,
          place: data.place as string,
          graduation: data.graduation,
          currentlyEnrolled: data.isCurrentlyEnrolled as boolean,
          didNotGraduate: data.didNotGraduate as boolean,
        };
        educIndex++;
        educationHistoryAppData.push(educationHistory);
      });

      applicant.certificationHistory?.forEach(function (data) {
        let certHistory: ApplyCertificationHistoryFormData = {
          id: certIndex.toString(),
          name: data.name,
          license: data.license as string,
          issuer: data.issuer as string,
          issued: data.issued,
          inProgress: data.isInProgress as boolean,
        };
        certIndex++;
        certHistoryAppData.push(certHistory);
      });

      let applicationProgressData: ApplicationFormData = {
        myInfoData: {
          firstName: applicant.firstName as string,
          lastName: applicant.lastName as string,
          email: applicant.email,
          country: applicantCountry,
          territory: applicantTerritory,
          address: applicant.address1 as string,
          city: applicant.place as string,
          postalCode: applicant.postalCode as string,
          phone: applicant.phoneNumber1 as string,
          tos: false,
          ppl: false,
        },
        experienceData: {
          employmentHistory: employmentHistoryAppData,
          educationHistory: educationHistoryAppData,
          certificationHistory: certHistoryAppData,
        },
        questionData: [],
      };
      return applicationProgressData;
    });
  };

  // Handle application submission
  useEffect(() => {
    if (!isSavingApplicant) return;
    mutateApplicant(true);
  }, [isSavingApplicant]);

  // Handle saving progress
  useEffect(() => {
    if (!isSavingProgress) return;
    mutateApplicant(false);
  }, [isSavingProgress]);

  // Handle setting as complete application
  useEffect(() => {
    if (!isSavingCompletion) return;
    mutateCompletion(applicationInfo);
  }, [isSavingCompletion]);

  // Handle API request for external job id
  useEffect(() => {
    if (!data) {
      return;
    }
    setXJobId(data.externalId);
  }, [data]);

  useEffect(() => {
    if (!appProgressData) return;
    convertStandardApplicant(appProgressData).then((formData) => {
      setExperienceData(formData.experienceData);
      setMyInfoData(formData.myInfoData);
      setQuestionData(formData.questionData);
      setHasFormData(true);
    });
  }, [appProgressData]);

  const [hasFormData, setHasFormData] = useState<boolean>(false);

  function createTrackingTagsBody(object: string) {
    return { __html: object };
  }

  return (
    <>
      {
        //Adds <img> tracking pixels to the beggining of <body>
        bodyTags.map(function (object, i) {
          return (
            <div
              dangerouslySetInnerHTML={createTrackingTagsBody(object)}
              key={i}
            />
          );
        })
      }
      <Header
        leftComponent={
          <BackLink
            id="backToSearchLink"
            data-testid="nav-back"
            text={t("job.nav.back")}
            onClick={() => navigate(-1)}
          />
        }
      />
      <div>
        <Loading isLoading={isLoading} />
        {!isLoading && (
          <>
            <MobilePaddingTypography
              variant={TypographyVariant.H1}
              weight={FontWeight.Bold}
              color={Color.Contrast}
              id="pageTitleHeader"
              data-testid="page-title"
            >
              Apply Now
            </MobilePaddingTypography>

            <JobInfoTypography
              variant={TypographyVariant.P}
              weight={FontWeight.Regular}
              color={Color.Contrast}
              id="jobInfo"
              data-testid="job-info"
            >
              {t("apply.youareapplyingfor")}&nbsp;-&nbsp;
              <Link id={`job-${id}`} to={`/job/${id}`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.jobTitle ?? "",
                  }}
                />
              </Link>
            </JobInfoTypography>

            <DivStyled>
              <Stepper>
                <Step
                  index={STEP_MYINFO}
                  title={t("apply.myinfo.step.title")}
                  //subTitle={t("apply.myinfo.step.subtitle")}
                >
                  <ApplyMyInfo
                    key={
                      myInfoData?.email ?? applyPanelEmail ?? authenticatedEmail
                    }
                    defaultValue={myInfoData}
                    hasFormData={hasFormData}
                    onSubmit={onSubmitMyInfo}
                    disabled={isSavingApplicant}
                  />
                </Step>
                <Step
                  index={STEP_EXPERIENCE}
                  title={t("apply.experience.step.title")}
                  //subTitle={t("apply.experience.step.subtitle")}
                >
                  <ApplyExperienceAndEducation
                    defaultValue={experienceData}
                    onSubmit={onSubmitExperience}
                    jobData={data}
                  />
                </Step>
                <Step
                  index={STEP_QUESTIONS}
                  title="Application Questions"
                  //subTitle="0 mins"
                >
                  <ApplicationQuestions
                    postingId={id}
                    onSubmit={onSubmitApplicationQuestions}
                    disabled={isSavingApplicant}
                    defaultValues={questionData}
                    companyName={data?.companyName}
                  />
                </Step>
                <Step
                  index={STEP_REVIEW}
                  title={t("apply.review.step.title")}
                  //subTitle={t("apply.review.step.subtitle")}
                >
                  <ApplyReview
                    stepMyInfo={STEP_MYINFO}
                    stepExperience={STEP_EXPERIENCE}
                    stepQuestions={STEP_QUESTIONS}
                    onSubmit={onSubmitReview}
                    data={applicationFormData}
                    disabled={isSavingApplicant}
                    companyName={data?.companyName}
                  />
                </Step>
              </Stepper>
            </DivStyled>
          </>
        )}
      </div>
    </>
  );
};
