import { useQuery } from "react-query";
import { AxiosResponse } from "axios";

import axios from "utils/axios-api-base";
import { Question } from "models/Question";

const baseQueryKey: string = "QuestionsBySectionId-";

type GetAllByQuestionSectionIdResponse = {
  totalResults: number;
  results: Question[];
};

export const useAPIQuestionsBySectionId = (
  postingId: string,
  sectionId: string
) => {
  return useQuery(
    [baseQueryKey + sectionId, postingId],
    async (): Promise<Question[] | null> => {
      const { status, data } = await axios.get<
        any,
        AxiosResponse<GetAllByQuestionSectionIdResponse>
      >("Question/getAllByQuestionSectionId", {
        params: {
          postingId: postingId,
          questionSectionId: sectionId,
        },
      });

      // NoContent
      if (status === 204) return null;
      if (status === 404) return null;

      let formattedResults: Question[] = data.results.map((q) => {
        return { ...q, sectionId: sectionId } as Question;
      });

      return formattedResults;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};
