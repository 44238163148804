// Private
const QTYPE_MULTI_CHOICE_SINGLE_ANSWER: string =
  "7232ee50-a7e9-4c11-9348-67c45c8b14b4";
const QTYPE_MULTI_CHOICE_MULTI_ANSWER: string =
  "7fde469f-2c6f-4caf-a61d-5537094e120a";
const QTYPE_MULTI_LINE_ANSWER: string = "34d44ecc-cab3-48c8-b704-244a1d3e54cd";
const QTYPE_SINGLE_LINE_ANSWER: string = "0f72f9e1-82ce-4f6e-a07a-2d4c478b278c";
const QTYPE_BINARY_ANSWER: string = "6edabdc5-84bb-47e1-9135-e23ed8f35b6e";
const SECTION_ID_START_INDEX: number = 8;
const SECTION_ID_END_INDEX_SEARCH_TERM: string = "-question";

// Public
export type Question = {
  id: string;
  sectionId: string;
  externalID: string;
  displayuNumber: string;
  followupQuestionForAnswerOptionID: string;
  text: string;
  type: string;
  isRequired: boolean;
  answerOptions: AnswerOption[];
};

export type AnswerOption = {
  id: string;
  externalID: string;
  text: string;
  askToExplain: boolean;
};

// Question types
export enum QuestionType {
  Unknown = 0,
  MultipleChoiceSingleAnswer,
  MultipleChoiceMultiAnswer,
  MultiLine,
  SingleLine,
  BinaryChoice,
}

export function QuestionTypeIdToEnum(id: string): QuestionType {
  switch (id) {
    case QTYPE_MULTI_CHOICE_SINGLE_ANSWER:
      return QuestionType.MultipleChoiceSingleAnswer;
    case QTYPE_MULTI_CHOICE_MULTI_ANSWER:
      return QuestionType.MultipleChoiceMultiAnswer;
    case QTYPE_MULTI_LINE_ANSWER:
      return QuestionType.MultiLine;
    case QTYPE_SINGLE_LINE_ANSWER:
      return QuestionType.SingleLine;
    case QTYPE_BINARY_ANSWER:
      return QuestionType.BinaryChoice;
    default:
      return QuestionType.Unknown;
  }
}

export function GenerateFieldName(
  sectionId: string,
  questionId: string
): string {
  return `section-${sectionId}-question-${questionId}`;
}

export function ParseSectionIdFromFieldName(fieldName: string): string {
  return fieldName.substring(
    SECTION_ID_START_INDEX,
    fieldName.indexOf(SECTION_ID_END_INDEX_SEARCH_TERM)
  );
}

export function ParseQuestionIdFromFieldName(fieldName: string): string {
  return fieldName.substring(
    fieldName.indexOf(SECTION_ID_END_INDEX_SEARCH_TERM) +
      SECTION_ID_END_INDEX_SEARCH_TERM.length +
      1
  );
}
